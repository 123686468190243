import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { MdCameraAlt } from "react-icons/md";
import { IoLocationOutline, IoEyeOutline } from "react-icons/io5";
import axios from "axios";
import { Dialog } from "@mui/material";
import DetailSpecialist from "../../DetailSpecialist/DetailSpecialist";
import { useTranslation } from "react-i18next";

const CardSpecialist = ({ data, detailDoctor, seeButton, nextStep }) => {
  const {
    name,
    image_profile,
    categories,
    cities,
    id,
    title,
    description,
    location,
    slug,
    status

  } = data;

  const { t, i18n } = useTranslation("global");
  const [imageLoaded, setImageLoaded] = useState(false);

  // Límite de ciudades a mostrar
  const cityLimit = 2;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles["grid-item"]}>
      <div className={styles["grid-item-content-doc"]}>
        <div className={styles["grid-item-content-img-doc"]}>
          {!imageLoaded && (
            <div className={styles.img_specialist_skeleton}>
              <MdCameraAlt size={40} color="var(--greyPrimary)" />
            </div>
          )}
          <img
            src={`https://pdtclientsolutions.com/crm-public/img/doctors/profile/${image_profile}`}
            alt={name}
            onLoad={() => setImageLoaded(true)}
            style={{ display: imageLoaded ? "block" : "none" }}
          />
          <span className={styles["status-tag"]} style={{ opacity: status === "active" ? "1" : "0.5" }}>{status === "active" ? t('clinics.status_active') : t('clinics.status_inactive')}</span>
        </div>

        <div className={styles["grid-item-info-doc-card"]}>
          <div className={styles["grid-item-info-text-doc"]}>
            <div className={styles["grid-item-info-doc"]}>
              <h4 className={styles["grid-item-info-title"]}>{name}</h4>
            </div>

            <div className={styles["grid-item-icon"]}>
              {categories?.[0]?.name ?? t('clinics.no_speciality')}
            </div>
          </div>
 
          <div className={styles["grid-item-info-buttons"]}>
            <div className={styles["grid-item-icon-doc"]}>
              <IoLocationOutline size={25} color="#00AFEB" />
              {
                cities?.length > 0 ?
                  cities?.length > cityLimit
                    ? `${cities
                      .slice(0, cityLimit)
                      .map((city) => city.name)
                      .join(", ")}...`
                    : cities.map((city) => city.name).join(", ")
                  :

                  t('specialist.detail.no_information')
              }
            </div>
          </div>

          <div className={styles["grid-item-actions"]}>
            <span className={styles["grid-item-link"]} onClick={handleOpen}>
              <IoEyeOutline size={20} /> {t('specialist.detail.see_more')}
            </span>

            {
              seeButton &&
              <button onClick={() => nextStep(slug, data)} className={styles["grid-item-select-button"]}>
                {t('hotel.select')}
              </button>
            }

          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "1100px!important",
            borderRadius: '12px',
            maxHeight: '78vh' // 70% del alto de la pantalla
          }
        }}
        BackdropProps={{
          sx: {
            backgroundColor: '#021624b0' // Color con opacidad
          }
        }}
      >
        <DetailSpecialist slug={slug} handleClose={handleClose} />
      </Dialog>
    </div>
  );
};

export default CardSpecialist;
