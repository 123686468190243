import React, { useEffect, useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import SliderCard from "./SliderCard";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderCardLowCostTourist from "./SliderCardLowCostTourist";

const SliderOferts = ({
  colorButton,
  optionSearch,
  loadingImage = false,
  setLoadingImage,
}) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [CardsType, setCardsType] = useState([]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1354,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const searchTypeCards = () => {
    const url = optionSearch
      ? `${process.env.REACT_APP_URL_API_AIOP}api/medic_packages`
      : `${process.env.REACT_APP_URL_API_AIOP}api/packages`;

    axios.get(url)
      .then((res) => {
        //console.log(res.data);
        const packs = Array.isArray(res.data?.packs)
          ? res.data.packs
          : res.data.packs?.data || [];
        setCardsType(packs)
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    searchTypeCards();
  }, [optionSearch]);

  const handleClickPack = (item, id) => {
    navigate(`/packages/${id}`);
  };

  const detailPack = (item, id) => {
    navigate(`/TourismHealth/DetailProcedure/${id}`, {
      state: {
        data: item,
      },
    });
  };

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {CardsType.length === 0 ? (
        <h3 className="slider-offerst-ca-not-items">
          No hay items disponibles
        </h3>
      ) : (
        <div className="slider-offerst-ca">
          <Slider {...settings} className={isMobile ? "mobile-slider" : ""}>
            {CardsType.map((card, index) => (
              <SliderCardLowCostTourist 
                loadingImage={loadingImage}
                setLoadingImage={setLoadingImage}
                colorButton={colorButton}
                startDate={
                  card?.start_date ? card?.start_date : card?.trip_from
                }
                dueDate={card?.due_date ? card?.due_date : card?.trip_up}
                img={card?.image}
                typePack={optionSearch}
                imgOption={true}
                price={card?.price}
                key={index}
                title={card?.name}
                typeCurrency={card?.currency}
                handleClickPack={card?.due_date ? handleClickPack : detailPack}
                item={card}
              />
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};

export default SliderOferts;
