import { useTranslation } from 'react-i18next'
import ModalContainer from '../../share/components/modalContainer'
import Loading from '../../share/components/loading'
import SvgIcon, { iconList } from '../../share/components/iconSVG'
import { ArrowLeft } from 'iconsax-react'
import Find from '../../share/components/Find/Find'
import CarouselSevices from '../../share/components/CarouselServices/CarouselSevices'
import PersonForm from "./PersonForm/PersonForm"
import SendInvoices from './SendInvoce/SendInvoices'
import DetailPay from "./detailPay/DetailPay"
import DetailBuy from './detailBuy copy/DetailBuy'
import useMakeReservationTransport from '../../../viewModels/transport/useMakeReservation'
import { useNavigate } from 'react-router-dom'
import "./styles.css"
import ReturnPage from '../../share/components/return/ReturnPage'
import { FaCircleCheck } from "react-icons/fa6";
import { useEffect, useState } from 'react'
import toast, { Toaster } from "react-hot-toast";
import AnyQuestions from '../../home/components/anyQuestion'

const ReservationTransport = () => {

  const [t] = useTranslation("global")

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });

  const {
    createLinkPay,
    cantPassenger,
    reservation,
    errorReservation,
    setPaxes,
    paxes,
    loadingRespose,
    loadingReservation,
    setLoadingReservation,
    state,
    needUser
  } = useMakeReservationTransport()

  const navigate = useNavigate()
  const [validate, setValidate] = useState(false)
  const [submitData, setSubmitData] = useState(false)
  const [validateOne, setValidateOne] = useState(false)

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });

    return cantidadFormateada;
  }

  const handleReservation = () => {
    setSubmitData(true);

    if (validate) {
      createLinkPay();
    }
  };

  useEffect(() => {
    if (validateOne) {
      if (validate) {
        handleReservation()
      }
    }
    setValidateOne(true)
  }, [validate])


  return (
    <div>
      <CarouselSevices />
      <ReturnPage />
      <Toaster position="top-center" reverseOrder={false} />
      <div className='contain-hotel-pay'>
        {
          loadingReservation &&
          <Loading size={90} />
        }

        <div className='layout-hotel-pay'>
          <section className='main-hotel-pay'>
            <PersonForm index={1} type={"adult"} setPaxes={setPaxes} paxes={paxes} setValidate={setValidate} setSubmitData={setSubmitData} submitData={submitData} />
          </section>
          <section className='aside-hotel-pay'>
            <DetailPay total={state?.price} acomodation={cantPassenger[1]} />
            <DetailBuy item={state?.service} />
            <div className='content-button-reservation-hotel'>
              <p>{t("hotels.reserve.total_pay")} <span>{formatearCantidad(state?.price)}</span></p>
              <button onClick={handleReservation} className='btn-pay-trans'>
                <FaCircleCheck size={20} color="var(--white)" />
                {t("fligth.list_flights.buy")}
              </button>

              {
                errorReservation != "" &&
                <div className='err-hotel-form-person'>
                  <p style={{ color: "var(--white)", fontWeight: "bold" }}>{errorReservation}</p>
                </div>
              }
            </div>
          </section>
        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  )
}

export default ReservationTransport