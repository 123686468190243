import React, { useState, useEffect } from "react";
import { RiFilterFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import CarouselSevices, {
  servicesTravel,
} from "../share/components/CarouselServices/CarouselSevices";
import UseSpecialist from "@/Hooks/BuildPackage/useSpecialist/UseSpecialist";
import AllCardsSpecialist from "./AllCardsSpecialist/AllCardsSpecialist";
import Paginado from "./Paginado/Paginado";
import { useTranslation } from "react-i18next";
import AnyQuestions from "../../../ui/views/home/components/anyQuestion";
import styles from "./styles.module.css";
import FilterSpecialist from "./FilterSpecialist/FilterSpecialist";
import ModalContainer from "../share/components/modalContainer";

const Specialists = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation("global");
  const [modalFilter, setModalFilter] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(false);

  const {
    dataAlways,
    loading,
    nextHandler,
    prevHandler,
    services,
    urlImg,
    detailSpecialist,
    setCurrentPage,
    findSpecialist,
    setCity,
    setName,
    city,
    name,
    specialistBuild,
    condition,
    modalNoFound,
    setModalNoFound,
  } = UseSpecialist();

  const handleResize = () => {
    if (window.innerWidth >= 1000) {
      setIsWideScreen(false);
    } else {
      setIsWideScreen(true);
    }
  };
  
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isStepTwo = location.pathname === "/build-package/specialist";

  return (
    <>
      <ModalContainer
        show={modalFilter}
        setHidden={setModalFilter}
        hiddsetActiveChangeServiceen={setModalFilter}
        sizeHeight={52}
      >
        <FilterSpecialist
          findSpecialist={findSpecialist}
          city={city}
          name={name}
          setCity={setCity}
          setName={setName}
          setModal={setModalFilter}
          seeFilter={isStepTwo}
          specialist={specialistBuild}
        />
      </ModalContainer>
      {!isStepTwo && (
        <CarouselSevices current={t("carousel_services.specialist")} />
      )}
      <div className={styles.layout_specialist}>
        <div className={styles.filterBtn}>
          <button
            onClick={() => {
              setModalFilter(!modalFilter);
            }}
            className={`${isWideScreen && "w-full"}`}
          >
            {" "}
            <RiFilterFill size={20} /> {t("build_package.steps.specialist.filter_specialists")}
          </button>
        </div>
        {
          dataAlways.length > 0 && (
            <div className={styles.filters}>
              <FilterSpecialist
                findSpecialist={findSpecialist}
                city={city}
                name={name}
                setCity={setCity}
                setName={setName}
                setModal={setModalFilter}
                seeFilter={isStepTwo}
                specialist={specialistBuild}
                data={dataAlways}
              />
            </div>
          )
        } 
        <div className={styles.results}>
          <AllCardsSpecialist
            data={dataAlways}
            urlImg={urlImg}
            loading={loading}
            detailSpecialist={detailSpecialist}
            seeButton={isStepTwo}

            findSpecialist={findSpecialist}
            city={city}
            name={name}
            setCity={setCity}
            setName={setName}
            setModal={setModalFilter}
            seeFilter={isStepTwo}
            specialist={specialistBuild}
          />
        </div>
      </div>

      {dataAlways.length > 0 && (
        <Paginado
          currentPage={services?.current_page}
          totalPages={services?.last_page}
          nextHandler={nextHandler}
          prevHandler={prevHandler}
          pageRangeDisplayed={5}
          setCurrentPage={setCurrentPage}
        />
      )}

      {!isStepTwo && <AnyQuestions title={t("browse_home.have_doubt")} />}
    </>
  );
};

export default Specialists;