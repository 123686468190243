import React, { useEffect, useState } from "react";
import CarouselSevices from "../../../share/components/CarouselServices/CarouselSevices";
import StepStep from "../../components/Step/StepStep";
import HotelDetailRoute from "@/Routes/HotelRoute/HotelDetailRoute";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";
import HomeButtonBuildPackage from "../../components/HomeButtonBuildPackage/HomeButtonBuildPackage";

import { setCurrentStep } from "@/store/slices/dataStepz/dataStepzSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import StepHome from "../../components/Step/StepHome";
import DataSearch from "../../components/DataSearch/DataSearch";

const StepRoom = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("global");

  const [dataStepzTitles, setDataStepzTitles] = useState([]);

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
  const currentStep = useSelector((state) => state.dataStepz.currentStep);

  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  useEffect(() => {
    dispatch(setCurrentStep(3));

  }, []);

  useEffect(() => {
    function handleSetDataStepzTitles() {
        let updatedTitles = [];
        if (setSpetVariableData === 0) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.hotel"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
                t("build_package.steps.titles.valuation_payment"),
            ];
        } else if (setSpetVariableData === 1) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
                t("build_package.steps.titles.valuation_payment"),
            ];
        } else if (setSpetVariableData === 2) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.hotel"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
                t("build_package.steps.titles.valuation_payment"),
            ];
        } else if (setSpetVariableData === 3) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.hotel"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
                t("build_package.steps.titles.valuation_payment"),
            ];
        }
        setDataStepzTitles(updatedTitles);
    }

    handleSetDataStepzTitles();
  }, [setSpetVariableData, t]);

  return (
    <div>
      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>

      <div className="maxWidthBuildPackage">
        <div className="itemSectionAll">
          <StepHome />
          {dataStepzTitles.map((item, index) => (
              <StepStep
                key={index}
                title={item}
                step={index + 1}
                onClick={handleStepClick}
                dataPackage={dataPackage}
              />
            ))}
        </div>
        <DataSearch />

        <HotelDetailRoute />
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default StepRoom;
