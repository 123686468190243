import React, { useState } from 'react';
import styles from './styles.module.css';
import { BiSolidRightArrow } from 'react-icons/bi';

const Collapsible = ({ 
    title, 
    description, 
    border = true, 
    children, 
    index, 
    open, 
    isOpenT = false,  
    id = false 
}) => {
    // Estado interno solo si no hay un controlador externo
    const [isOpen, setIsOpen] = useState(false);

    // Función de toggle que decide si se controla internamente o externamente
    const toggleCollapsible = () => {
        if (id !== false) {
            open(id); // Si hay un controlador externo, usa esa función
        } else {
            setIsOpen(!isOpen); // Si no, usa el estado interno

        }
    };

    // Determina si debe estar abierto usando la prop externa o el estado interno
    const isContentOpen = id !==  false ? isOpenT : isOpen;

    return (
        <div className={styles.collapsible}>
            <div 
                className={`${styles.collapsibleContain} ${!border ? styles.noneBorder : ""}`} 
                onClick={toggleCollapsible} // Usa la función toggle que maneja ambos casos
            >
                <span className={`${styles.buttonArrowOriginal} ${isContentOpen ? styles.buttonArrowOpenOriginal : ''}`}>
                    <BiSolidRightArrow size={20} />
                </span>

                <div className={styles.collapsibleHeader}>
                    <h4>{index ? `${index}. ` : ''}{title}</h4>
                    {description && (<p>{description}</p>)}
                </div>
            </div>

            <div className={`${styles.content} ${isContentOpen ? styles.open : ''}`}>
                {children}
            </div>
        </div>
    );
};

export default Collapsible;
