import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const StepHome = (disabled = false) => {
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    }
  );

  const changeHomePackage = () => {
    if (disabled) {
      notify(t("build_package.steps.std.valoration.already_paid"), "❌");
      return;
    }

    navigate("/build-package");
  };
  return (
    <div className={styles.stepToStepHome}>
      <div className={styles.itemSectionHome} onClick={() => changeHomePackage()}>
        <h2 className={styles.itemText}>
          <AiFillHome />
        </h2>
      </div>
    </div>
  );
};

export default StepHome;
