import React, { useEffect, useState } from "react";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";

import { Link } from "react-router-dom";
import { setCurrentStep, setDataPackage, setLastSearch, setProceduresData, setRoutes } from "@/store/slices/dataStepz/dataStepzSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { IoClose, IoCloseCircle } from "react-icons/io5";
import { TbLoader2 } from "react-icons/tb";
import { BiQuestionMark } from "react-icons/bi";
import { GoCheckCircleFill } from "react-icons/go";
import { currencyFormat } from "@/ui/utils";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";
import { FaCheck } from "react-icons/fa6";
import Loading from "@/ui/views/share/components/loading";
import useStepBuildPackage from "@/Hooks/BuildPackage/useStepBuildPackage/useStepBuildPackage";
import { Dialog } from "@mui/material";
import ModalPaymentCenter from "./ModalPaymentCenter";

const PaymentStatusView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  const [openViewItem, setOpenCreateContact] = useState(false);

  const { handlePayStatus } = useStepBuildPackage();

  const notify = (title, icon) =>
    toast(title, {
        icon: icon,
    }
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // obtain the individual parts of the date
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // The months begin at 0
    const year = date.getFullYear();

    // Obtain the parts of the hour
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Final format
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const toggleModal = () => {
    setOpenCreateContact(!openViewItem);
  };

  const [dataStepzTitles, setDataStepzTitles] = useState([]);

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const especialtieAvliable = useSelector((state) => state.dataStepz.especialtieAvliable);
  const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
  const routes = useSelector((state) => state.dataStepz.routes);

  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const [authUser, setAuthUser] = useState(null);
  const [payStatus, setPayStatus] = useState(null);
  const [hasAlreadyPaid, setHasAlreadyPaid] = useState(false);
  const [packageUrl, setPackageUrl] = useState("");
  const [isLoadingAuthInfo, setIsLoadingAuthInfo] = useState(true);
  const [userPayments, setUserPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const [dollarFlight, setDollarFlight] = useState(
    localStorage.getItem("dollarFlight") === null
      ? "COP"
      : localStorage.getItem("dollarFlight")
  );
  
  const typeOfCoin = {
    name: dollarFlight,
    abbreviation: dollarFlight,
    simbol: "$",
  };


  const handleGoToMyMovements = () => {
    localStorage.removeItem("request-id-payment-hub");
    localStorage.removeItem("payment_hub_item_id");
    localStorage.removeItem("payment_hub_item_data");
    localStorage.removeItem("data-payment-hub-status");
    localStorage.removeItem("has-been-paid-from-payment-hub");
    navigate("/profile?option=3");
  }


  const checkBuildPackagePaymentStatus = async (authUserData) => {
    try {
      const requestId = localStorage.getItem("request-id-payment-hub");
      const paymentHubItemId = localStorage.getItem("payment_hub_item_id");
      const paymentHubItemData = JSON.parse(localStorage.getItem("payment_hub_item_data")); 

      console.log("Payment hub data: ", paymentHubItemData)

      setSelectedItem(paymentHubItemData);

      const url = process.env.REACT_APP_URL_STATE_PAY;
      const paymentStatus = await axios.post(`${url}pack`, { request_id: Number(requestId) });
      
      localStorage.setItem("data-payment-hub-status", JSON.stringify(paymentStatus.data));

      if (paymentStatus.data?.pay?.status === "APPROVED") {
        const paymentsArray = []

        paymentsArray.push(paymentStatus.data?.pay)

        setUserPayments(paymentsArray);

        const paymentInformation = paymentStatus.data?.pay;

        const paymentDataToStore = {
          user_id: authUserData.id,
          email: authUserData.email,
          localizator: paymentInformation.localizator,
          payment_date: paymentInformation.date,
          payment_created_at: paymentInformation.created_at,
          payment_updated_at: paymentInformation.updated_at,
          price: paymentInformation.price.toString(),
          reference: paymentInformation.reference,
          reason: paymentInformation.reason,
          status: paymentInformation.status,
          request_id: paymentInformation.request_id,
          type: paymentInformation.type
        }

        const checkIfHasBeenPaid = localStorage.getItem('has-been-paid-from-payment-hub')

        if (!checkIfHasBeenPaid) {
          const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-payments`;
          await axios.post(url, paymentDataToStore);

          // /PaymentHub/{id}/status
          const urlStatus = `${process.env.REACT_APP_URL_API_AIOP}api/PaymentHub/${Number(paymentHubItemId)}/status`;
          await axios.patch(urlStatus, {
            status: "completed",
          });

          localStorage.setItem('has-been-paid-from-payment-hub', 'true');

          const message = t("packs.tourism.success.approved_payment")
          // setHasAlreadyPaid(true);
          notify(message, "✅");
        } else {
          const message = t("packs.tourism.success.already_paid")
          setHasAlreadyPaid(true);
          notify(message, "✅");
        }
      }
      else if (paymentStatus.data?.pay?.status === "REJECTED") {
        const message = t("packs.tourism.errors.rejected_payment")
        notify(`Error: ${message}`, "❌");
      } else {
        const message = t("packs.tourism.errors.pending_payment")
        notify(`Error: ${message}`, "❌");
      }

      handlePayStatus(paymentStatus.data?.pay);
      setPayStatus(paymentStatus.data?.pay);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const checkAuthInfo = async () => {
      setIsLoadingAuthInfo(true);
      // Verify if there is an authenticated user
      const authUserSession = JSON.parse(localStorage.getItem("@authUserSession") || "{}");
      if (!authUserSession?.user?.id) {
        navigate("/login");
      }
  
  
      setAuthUser(authUserSession?.user);
  
      // Verify if in the store Storage there is a Request-ID-Pay-Build-Package to verify the payment status
      const requestId = localStorage.getItem("request-id-payment-hub");
  
      if (requestId) {
        await checkBuildPackagePaymentStatus(authUserSession?.user);
      } else {
        navigate("/profile");
      }
  
      setIsLoadingAuthInfo(false);
    }

    checkAuthInfo();
  }, []);

  useEffect(() => {
    if (payStatus?.status === "APPROVED") {
      setIsMounted(false);
      setTimeout(() => setIsMounted(true), 0);
    }
  }, [payStatus]);  

  return (
    <div>
      {
        (isLoadingAuthInfo || isLoading ) &&
        <Loading />
      }

      <div className="maxWidthBuildPackage">
        <div
          className="methodPayContainer"
        >
          <div className='methodPayHeader'>
            <h3>{t("packs.tourism.payment_detail.title")}</h3>
            <p>{t("packs.tourism.payment_detail.description")}</p>
          </div>
          <div className='methodPayContain' >
            <div className='methodPayContainQuestions'>
              <div className='methodPayContainerTermsContain'>
                <div className='detailPayFlight'>
                  <div className="detailPayFlightHeader">
                    <div className="detailPayFlightInfo flex justify-center text-center w-full">
                      <h2 >
                        <span className="text-2xl">
                          {t("payment_hub.completed_payment")}
                        </span>
                      </h2>

                      <Link onClick={() => toggleModal()} className="text-[14px] text-customBlue  underline">
                        {t("payment_center.see_details")}
                      </Link>

                      <Dialog
                        PaperProps={{ sx: { width: "100%", maxWidth: "850px!important", padding: "26px", borderRadius: '8px' } }}
                        open={openViewItem}
                        onClose={toggleModal}
                        BackdropProps={{
                          sx: {
                            backgroundColor: '#021624b0' // Color con opacidad
                          }
                        }}
                      >
                        <ModalPaymentCenter item={selectedItem} handleClose={toggleModal} /> 
                      </Dialog>
                    </div>
                  </div>

                  <div>
                    {payStatus?.status === "APPROVED" && (
                      <p className="mt-10 text-center">
                        <span>
                          <strong>
                            {t("packs.tourism.payment_detail.stored_payment")}
                            <button onClick={handleGoToMyMovements} className="ml-2 underline cursor-pointer text-green-700 hover:text-green-900">{t("packs.tourism.payment_detail.stored_payment_module")}</button>
                          </strong>
                        </span>
                      </p>
                    )}
                  </div>

                  <div className="itemDetailsFlights">
                    <div className="itemDetailsFlights1">
                      <div className="itemDetailsFlightsColumn1">
                        <p className="typeFlight">
                          <strong>{t("packs.tourism.payment_detail.payment_date")}</strong> {formatDate(payStatus?.created_at)}
                        </p>
                      </div>
                    </div>
                    <div className="itemDetailsFlights2">
                      <div className="itemDetailsFlightsColumn1">
                        <p className="typeFlight">
                          <strong>{t("packs.tourism.payment_detail.payment_reference")}</strong> {payStatus?.reference}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div className="methodPayContainInfo">
              <div className="methodPayImageContain">
                <div className="methodPayContainInfoContain">
                  <div
                    className={`contentStatePay ${payStatus?.status === "APPROVED"
                      ? "APPROVED"
                      : payStatus?.status === "PENDING"
                        ? "PENDING"
                        : payStatus?.status === "REJECTED"
                          ? "REJECTED"
                          : ""
                      }`}
                  >
                    {payStatus?.status === "APPROVED" ? (
                      <GoCheckCircleFill size={40} />
                    ) : payStatus?.status === "PENDING" ? (
                      <TbLoader2 size={40} />
                    ) : payStatus?.status === "REJECTED" ? (
                      <IoCloseCircle size={40} />
                    ) : (
                      <BiQuestionMark size={40} />
                    )}
                    <div>
                      <div className="contentStatePayMessage !gap-[8px]">
                        <h3>{t("packs.tourism.payment_detail.status.text")}:</h3>
                        <p>
                          {payStatus?.status === "APPROVED"
                            ? t("packs.tourism.payment_detail.status.approved")
                            : payStatus?.status === "PENDING"
                              ? t("packs.tourism.payment_detail.status.pending")
                              : payStatus?.status === "REJECTED"
                                ? t("packs.tourism.payment_detail.status.rejected")
                                : t("packs.tourism.payment_detail.status.loading")}
                        </p>
                      </div>
                      <p>
                        {payStatus?.status === "APPROVED"
                          ? t("packs.tourism.payment_detail.status.success_payment")
                          : payStatus?.status === "PENDING"
                            ? t("packs.tourism.payment_detail.status.pending_payment")
                            : payStatus?.status === "REJECTED"
                              ? t("packs.tourism.payment_detail.status.rejected_payment")
                              : t("packs.tourism.payment_detail.status.loading")}
                      </p>
                    </div>
                  </div>
                  <div className="methodPayContainInfoContainPadding">
                    <div>
                      <h3>{t("packs.tourism.payment_detail.finished_purchase")}</h3>
                      <p>{t("packs.tourism.payment_detail.total_purchase")}</p>
                    </div>
                    <div className="methodValuesContaint">
                      <div className="methodValuesItem">
                        <h4>{t("fligth.form_passenger.step3.sub_total")}:</h4>
                        <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total}</span>
                      </div>
                      <div className="methodValuesItemTotal">
                        <h4>{t("fligth.form_passenger.step3.total")}:</h4>
                        <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="securityPay">
                  <SvgIcon
                    color={"var(--bluePrimary)"}
                    name={iconList.IconKey}
                    size={40}
                  />
                  <div>
                    <TextComponent
                      children={t("packs.tourism.payment_detail.secure_payment")}
                      size={"md"}
                      fontWeight={"bold"}
                      tag="h4"
                    />
                    <TextComponent
                      children={t("packs.tourism.payment_detail.secure_payment_description")}
                      size={"md2"}
                      fontWeight={"light"}
                      tag="span"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="text-center">
            {payStatus?.status === "APPROVED" && (
              <p>
                {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.approved")})}
              </p>
            )}

            {payStatus?.status === "PENDING" && (
              <p>
                {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.pending")})}
              </p>
            )}

            {payStatus?.status === "REJECTED" && (
              <p>
                {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.rejected")})}
              </p>
            )}
          </div>

        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default PaymentStatusView;