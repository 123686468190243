import React from 'react'
import "./styles.css"
import { useTranslation } from "react-i18next";

const DetailPay = ({ acomodation, price, taxes, total, discount }) => {

    function formatearCantidad(cantidad) {
        const cantidadFormateada = cantidad?.toLocaleString("es", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
        });

        return cantidadFormateada;
    }

    var porcentaje = 35;
    var resultado = Number(total) * (porcentaje / 100);

    const { t } = useTranslation("global");

    return (
        <div className='detail-pay-hotel'>
            <h2>{t("packages.packages_pay.detail_pay")} </h2>
            <div className='row-detail-pay-normal'>
                <p>{t("transfers.traslade_for")} {acomodation}</p>
                <span>{price}</span>
            </div>
            <div className='row-detail-pay-normal'>
                <p>{t("transfers.charges")}</p>
                <span>{taxes}</span>
            </div>
            {/* <div className='row-detail-pay row-discount-pay'>
            <p>Descuentos</p>
            <span>-{formatearCantidad(resultado)}</span>
        </div> */}
            <div className='row-detail-pay row-total-pay'>
                <p>Total</p>
                <span>{formatearCantidad(total)}</span>
            </div>
            <div className='line-separation'></div>
            <div className='row-aditional-services'>
                <p>{t("transfers.aditional_charges_pay")}</p>
                <span>{t("transfers.charges_destiny")}.</span>
            </div>
            <div className='row-detail-pay row-secure-hotel'>
                <section>
                    <p>{t("transfers.safe_travel")}</p>
                    <span>{t("transfers.person_by_day")}</span>
                </section>
                <span style={{ color: "#004274", fontWeight: "bold" }}>2.5 USD</span>
            </div>
        </div>
    )
}

export default DetailPay