import React, { useEffect } from "react";
import "./styles.css";
import CardHotelResult from "./CardHotelResult";
import FlowPack from "../../../share/components/flowPack/FlowPack";
import { widgetsList } from "../../../../../infra/constants/app";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const AllCardsResults = ({
  data,
  dataS,
  loading,
  seePack,
  dataRoom,
  persons,
  fen,
  fsa,
  rooms,
  country_client,
  currentPage,
}) => {
  const hotels = [];
  const { pathname } = useLocation();
  const { t } = useTranslation("global");

  const routes = useSelector((state) => state.dataStepz.routes);

  let buildPackage;

  data?.map((e) => {
    hotels.push({
      code: e?.Id,
    });
  });

  const dataRequest = {
    arrival: dataRoom?.arrival,
    departure: dataRoom?.departure,
    country: "CO",
    countryClient: "CO",
    hotels: hotels,
    rooms: dataRoom?.rooms,
  };

  const detailHotelView = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL_API_FLY}hotel-do/quote-hotel`,
        dataRequest
      )
      .then((res) => {
        const isWidget = widgetsList.find(
          (w) => w.path === window.location.pathname
        );
        let url = "";
        if (isWidget) {
          url = `/widget/hotels/`;
        } else if (pathname.includes("packages")) {
          url = "/packages/hotel/";
        } else {
          url = "/hotel/";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    routes?.hotels?.route === pathname ? (buildPackage = true) : (buildPackage = false);
  }, [routes]);

  const findRoomPrice = (data) => {
    const minRoom = data?.res?.pax[0]?.hab?.reduce((minObjeto, objeto) => {
      if (
        objeto?.reg[0]["@attributes"]?.prr < minObjeto?.reg[0]["@attributes"]?.prr
      ) {
        return objeto;
      } else {
        return minObjeto;
      }
    });
    return minRoom?.reg[0]["@attributes"]?.prr ?? Infinity;
  };

  const sortedData = data?.slice().sort((a, b) => {
    const priceA = findRoomPrice(a);
    const priceB = findRoomPrice(b);
    return priceA - priceB;
  });

  return (
    <div className={`contain-all-results-hotels`}>
      {seePack && (
        <FlowPack
          data={data}
          fen={fen}
          persons={persons}
          fsa={fsa}
          type={"hotel"}
        />
      )}
      {sortedData?.length === 0 && buildPackage ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            padding: "50px 0",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            No se encontraron resultados
          </h2>
          <CiSearch size={50} />
        </div>
      ) : sortedData?.length === 0 && !buildPackage ? (
        <div
          style={{
            width: "100%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            padding: "50px 0",
          }}
        >

        </div>
      )
        : (
          sortedData?.length > 0 ? (
            sortedData?.map((item, key) => (
              <CardHotelResult
                key={key}
                data={item}
                dataRoom={dataRoom}
                detailReservation={detailHotelView}
                fen={fen}
                fsa={fsa}
                country_client={country_client}
                rooms={rooms}
                persons={persons}
                currentPage={currentPage}
                bestOffer={key === 0}
              />
            ))
          ) : (
            <div className="main-content-resulta-filter-hotels-not-results">
              <div className="noItemsHotels">
                <div className="messageNoItemsHotels">
                  {t("hotels.without_searchs")}
                  <AiOutlineExclamationCircle
                    size={20}
                    color="var(--bluePrimary)"
                  />
                </div>

                <Link to="/hotel" className="buyNoItemsHotels">
                  {t("hotels.search_again")}
                </Link>
              </div>
            </div>
          )

        )}
    </div>
  );
};

export default AllCardsResults;