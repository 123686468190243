import useSearchBuildPackage from "@/Hooks/BuildPackage/useBuildPackageSearchEngine/UseBuildPackageSearchEngine";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setDataPackage, setLastSearch, setProceduresData, setRoutes } from "@/store/slices/dataStepz/dataStepzSlice";
import { resetState } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import { useDispatch } from "react-redux";

const PaymentApprovedBanner = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { newSearch } = useSearchBuildPackage();

  const handleGoToProfile = () => {
    dispatch(setDataPackage({}));
    dispatch(setRoutes({}));
    dispatch(setLastSearch({}));
    dispatch(setProceduresData([]));
    dispatch(resetState());

    localStorage.removeItem('form-data-procedure-std');
    localStorage.removeItem('data-std');
    localStorage.removeItem('has-been-paid-build-package');
    localStorage.removeItem('data-pay-build-package-status');
    localStorage.removeItem('request-id-pay-build-package');
    localStorage.removeItem('data-pay-build-package');
    localStorage.removeItem('data-pay-build-package-body-std');
    localStorage.removeItem('data-pay-build-package-body-aiop');
    localStorage.removeItem('data-pay-build-package-body-crm-packages');

    navigate("/profile?option=4");
  };

  return (
    <div className="bg-gradient-to-r from-green-600 to-green-900 text-white p-6 rounded-lg shadow-lg text-center my-8">
      <h2 className="text-2xl font-bold mb-4">{t("build_package.steps.std.valoration.already_approved_payment")}</h2>
      <div className="flex justify-center gap-4">
        <button
          onClick={newSearch}
          className="bg-white text-customBlue font-semibold py-2 px-4 rounded-lg hover:bg-gray-100 transition-all duration-200"
        >
          {t("build_package.steps.std.valoration.build_another_package")}
        </button>
        <button
          onClick={handleGoToProfile}
          className="bg-white text-customBlue font-semibold py-2 px-4 rounded-lg hover:bg-gray-100 transition-all duration-200"
        >
          {t("build_package.steps.std.valoration.go_to_profile")}
        </button>
      </div>
    </div>
  );
};

export default PaymentApprovedBanner;