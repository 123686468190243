import bannerSpanish from "../../../assets/pictures/BannerTourismInH.jpg";
import bannerEnglish from "../../../assets/pictures/BannerTourismInHEng.jpg";
import "./styles.css";
import { useTranslation } from "react-i18next";
import useBrowseHome from "../../../viewModels/home/browse.home.viewModel";
import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";

const SearchTourismHealth = () => {
  const {
    disableBtn,
    loading,
    tabOpen,
    cardRefProcedure,
    cardRefDestination,
    proceduresSelected,
    isOpenproceduresList,
    setIsOpenproceduresList,
    proceduresList,
    chooseProcedure,
    destinationsSelected,
    isOpenDestinationsList,
    setIsOpenDestinationsList,
    destinationsList,
    chooseDestination,
    searchForProceduresOnSite,
    goToWelezyShop,
    loadingSearch,
    specialistList,
    setSpecialistList,
    chooseSpecislist,
    isOpenSpecialistList,
    cardRefSpecialist,
    setIsOpenSpecialistList,
    specialistSelected
  } = useBrowseHome();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [t, i18n] = useTranslation("global");

  const gotoOption = () => {
    navigate(`/TourismHealthPack/result?procedure=${proceduresSelected ? proceduresSelected?.id : ""}&specialist=${specialistSelected?.id ? specialistSelected?.id : ""}`, {
      state: {
        procedure: proceduresSelected?.id,
        specialist: specialistSelected?.id
      },
    });
  };

  const [procedureFilterText, setProcedureFilterText] = useState("");
  const [specialistFilterText, setSpecialistFilterText] = useState("");
  const [destinationFilterText, setDestinationFilterText] = useState("");
  const [isProcedureInputActive, setIsProcedureInputActive] = useState(false);
  const [isSpecialistInputActive, setIsSpecialistInputActive] = useState(false);
  const [isDestinationInputActive, setIsDestinationInputActive] =
    useState(false);

  const procedureInputRef = useRef(null);
  const specialistInputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const containerRef = useRef(null); // Para manejar clics fuera del componente

  // Filtra la lista de procedimientos en función del texto de entrada
  const filteredProcedures = proceduresList.filter((procedure) =>
    procedure?.name.toLowerCase().includes(procedureFilterText.toLowerCase())
  );

  // Filtra la lista de procedimientos en función del texto de entrada
  const filteredSpecialist = specialistList.filter((specialist) =>
    specialist?.name.toLowerCase().includes(specialistFilterText.toLowerCase())
  );

  // Filtra la lista de destinos en función del texto de entrada
  const filteredDestinations = destinationsList.filter((destination) =>
    destination?.name
      .toLowerCase()
      .includes(destinationFilterText.toLowerCase())
  );

  // Maneja la selección de un procedimiento
  const handleProcedureSelect = (procedure) => {
    chooseProcedure(procedure);
    setIsProcedureInputActive(false); // Oculta el campo de entrada
    setProcedureFilterText(""); // Restablece el texto del filtro
    setIsOpenproceduresList(false); // Cierra la lista de procedimientos
  };


  // Maneja la selección de un procedimiento
  const handleSpecialistSelect = (specialist) => {
    chooseSpecislist(specialist);
    setIsSpecialistInputActive(false); // Oculta el campo de entrada
    setSpecialistFilterText(""); // Restablece el texto del filtro
    setIsOpenSpecialistList(false); // Cierra la lista de procedimientos
  };



  // Maneja la selección de un destino
  const handleDestinationSelect = (destination) => {
    chooseDestination(destination);
    setIsDestinationInputActive(false); // Oculta el campo de entrada
    setDestinationFilterText(""); // Restablece el texto del filtro
    setIsOpenDestinationsList(false); // Cierra la lista de destinos
  };

  // Maneja el clic en el texto para activar el campo de entrada
  const handleProcedureTextClick = (e) => {
    e.stopPropagation();
    setIsProcedureInputActive(true);
    setIsOpenproceduresList(true);
    setIsOpenDestinationsList(false);
  };


  // Maneja el clic en el texto para activar el campo de entrada
  const handleSpecialistTextClick = (e) => {
    e.stopPropagation();
    setIsSpecialistInputActive(true);
    setIsOpenSpecialistList(true);
    setIsOpenproceduresList(false);
  };

  // Maneja el clic en el texto para activar el campo de entrada de destinos
  const handleDestinationTextClick = (e) => {
    e.stopPropagation();
    setIsDestinationInputActive(true);
    setIsOpenDestinationsList(true);
    setIsOpenproceduresList(false);

  };

  // Maneja el clic fuera del componente y el foco del input
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpenproceduresList(false);
        setIsOpenDestinationsList(false);
        setIsProcedureInputActive(false);
        setIsDestinationInputActive(false);
        setIsSpecialistInputActive(false);
        setIsOpenSpecialistList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Establece el foco en el campo de entrada cuando está activo
  useEffect(() => {
    if (isProcedureInputActive && procedureInputRef.current) {
      procedureInputRef.current.focus();
    }
    if (isSpecialistInputActive && specialistInputRef.current) {
      specialistInputRef.current.focus();
    }
    if (isDestinationInputActive && destinationInputRef.current) {
      destinationInputRef.current.focus();
    }
  }, [isProcedureInputActive, isDestinationInputActive, isSpecialistInputActive]);

  return (
    <div className='top-tourism-health'>
      <div className='top1-tourism'>
        <h2 className="browseHomeColumnSideLeftTitleTop">
          {t("browse_home.need_procedures")}
        </h2>
      </div>

      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} ref={containerRef}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4.7}>
                <div
                  className={`browseHomeColumnSideLeftFormColumnsGroup2`}
                  onClick={() => {
                    setIsOpenproceduresList(false);
                    setIsOpenSpecialistList(!isOpenSpecialistList);
                  }}
                >
                  <div className="browseHomeColumnSideLeftFormColumnsText2">
                    <p className="browseHomeColumnSideLeftFormColumnsTextTitle">
                      {t("packs.health.specialties.title")}
                    </p>
                    {isSpecialistInputActive ? (
                      <input
                        type="text"
                        placeholder={t("packs.health.specialties.placeholder")}
                        value={specialistFilterText}
                        onChange={(e) => setSpecialistFilterText(e.target.value)}
                        className="filterInput"
                        ref={specialistInputRef}
                        onBlur={() => setIsSpecialistInputActive(false)}
                      />
                    ) : (
                      <p
                        className="browseHomeColumnSideLeftFormColumnsTextValue"
                        onClick={handleSpecialistTextClick}
                      >
                        {specialistSelected?.name || t("packs.health.specialties.placeholder")}
                      </p>
                    )}
                  </div>
                  {loading ? (
                    <CircularProgress size={15} />
                  ) : (
                    <div className="browseHomeColumnSideLeftFormColumnsBtn2">
                      <div
                        style={{
                          transition: "all 0.3s ease",
                          rotate: isOpenSpecialistList ? "180deg" : "",
                        }}
                      >
                        <TiArrowSortedDown />
                      </div>
                    </div>
                  )}

                  {isOpenSpecialistList && (
                    <div className="browseHomeColumnSideLeftFormListContainer2">
                      {filteredSpecialist.length > 0 ? (
                        filteredSpecialist.map((specialist, key) => (
                          <div
                            className="browseHomeColumnSideLeftFormListOption"
                            onClick={() => handleSpecialistSelect(specialist)}
                            key={key}
                          >
                            <p className="browseHomeColumnSideLeftFormListOptionText">
                              {specialist?.name}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p className="noResults">{t("packs.health.specialties.without_coincidence")}</p>
                      )}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={4.7}>
                <div
                  style={{
                    pointerEvents: specialistSelected ? "" : "none",
                    opacity: specialistSelected ? 1 : 0.5
                  }}
                  className={`browseHomeColumnSideLeftFormColumnsGroup2`}
                  onClick={() => {
                    setIsOpenDestinationsList(false);
                    setIsOpenproceduresList(!isOpenproceduresList);
                  }}
                >
                  <div className="browseHomeColumnSideLeftFormColumnsText2">
                    <p className="browseHomeColumnSideLeftFormColumnsTextTitle">
                      {t("browse_home.what_procedures_looking")}
                    </p>
                    {isProcedureInputActive ? (
                      <input
                        type="text"
                        placeholder={t("packs.health.procedures.placeholder")}
                        value={procedureFilterText}
                        onChange={(e) => setProcedureFilterText(e.target.value)}
                        className="filterInput"
                        ref={procedureInputRef}
                        onBlur={() => setIsProcedureInputActive(false)}
                      />
                    ) : (
                      <p
                        className="browseHomeColumnSideLeftFormColumnsTextValue"
                        onClick={handleProcedureTextClick}
                      >
                        {proceduresSelected?.name || t("browse_home.procedures")}
                      </p>
                    )}
                  </div>
                  {loading ? (
                    <CircularProgress size={15} />
                  ) : (
                    <div className="browseHomeColumnSideLeftFormColumnsBtn2">
                      <div
                        style={{
                          transition: "all 0.3s ease",
                          rotate: isOpenproceduresList ? "180deg" : "",
                        }}
                      >
                        <TiArrowSortedDown />
                      </div>
                    </div>
                  )}

                  {isOpenproceduresList && (
                    <div className="browseHomeColumnSideLeftFormListContainer2">
                      {filteredProcedures.length > 0 ? (
                        filteredProcedures.map((procedure, key) => (
                          <div
                            className="browseHomeColumnSideLeftFormListOption"
                            onClick={() => handleProcedureSelect(procedure)}
                            key={key}
                          >
                            <p className="browseHomeColumnSideLeftFormListOptionText">
                              {procedure?.name}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p className="noResults">{t("packs.health.procedures.without_coincidence")}</p>
                      )}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={2.6}>
                {!(isOpenDestinationsList || isOpenproceduresList) && pathname !== "/" ? (
                  <button
                    style={{ opacity: loadingSearch ? 0.5 : 1 }}
                    className={`${disableBtn
                      ? "browseHomeColumnSideLeftFormBtnDisabled2"
                      : "browseHomeColumnSideLeftFormBtn2"
                      }`}
                    onClick={() => !loadingSearch && gotoOption()}
                    disabled={disableBtn}
                  >
                    {!loadingSearch && (
                      <p className="browseHomeColumnSideLeftFormBtnText">
                        {t("browse_home.search")}
                      </p>
                    )}
                  </button>
                ) : (
                  <button
                    style={{ opacity: loadingSearch ? 0.5 : 1 }}
                    className={`${disableBtn
                      ? "browseHomeColumnSideLeftFormBtnDisabled2"
                      : "browseHomeColumnSideLeftFormBtn2"
                      }`}
                    onClick={() => !loadingSearch && searchForProceduresOnSite()}
                    disabled={disableBtn}
                  >
                    {!loadingSearch && (
                      <p className="browseHomeColumnSideLeftFormBtnText">
                        {t("browse_home.search")}
                      </p>
                    )}
                  </button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </div>

      <div className="banner-bottom-medicalpackage">
          <img src={i18n.language === "es" ? bannerSpanish : bannerEnglish} alt="" />        
      </div>
    </div>
  );
};

export default SearchTourismHealth;