import { setIsRoundTripData } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import TooltipContainer from "@/ui/views/share/components/tooltipContainer";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TiArrowSortedDown } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";

const InputTypeTravelFlight = ({
    isRoundTrip,
    setIsRoundTrip,
    setCheckDate
}) => {
    const { t } = useTranslation("global");

    // Variables
    const dispatch = useDispatch();

    const typeTravelRef = useRef(null);
    const inputRef = useRef(null);

    const [ShowCardTypeTravel, setShowCardTypeTravel] = useState(false);

    const isRoundTripData = useSelector((state) => state.dataSearchEngine.isRoundTripData);

    // Functions
    const handleClick = () => {
        setShowCardTypeTravel(!ShowCardTypeTravel);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleChangeTypeTravel = (type) => {
        if (type) {
            setCheckDate(true);
            setIsRoundTrip(true);
        } else {
            setCheckDate(false);
            setIsRoundTrip(false);
        }

        setShowCardTypeTravel(false);
    };

    // UseEffects
    useEffect(() => {
        setIsRoundTrip(isRoundTripData);

        const handleClickOutside = (event) => {
            if(typeTravelRef.current && !typeTravelRef.current.contains(event.target)){
                setShowCardTypeTravel(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(setIsRoundTripData(isRoundTrip));
    }, [isRoundTrip]);

    return(
        <div className="searchBuildPackageContentInput" onClick={handleClick} ref={typeTravelRef}>
            <div className="searchBuildPackageContentInputContent">
                <p>{t("build_package.search_engine.flight.type")}</p>
                <input type="text" placeholder="Elije un tipo de viaje" value={ !isRoundTrip ? t("build_package.search_engine.flight.one_way") : t("build_package.search_engine.flight.round_trip") } ref={inputRef} readOnly />
            </div>
            <TiArrowSortedDown size={20} color="#104172" />
            {
                ShowCardTypeTravel && (
                    <TooltipContainer
                        show={ShowCardTypeTravel}
                        hidden={setShowCardTypeTravel}
                        sizeWidth={100}
                        sizeHeight={80}
                    >
                        <ul className="contentElemetsSearchInputBuildPackage">
                            <li onClick={(e) => {
                                handleChangeTypeTravel(false);
                                e.stopPropagation();
                            }}>{t("build_package.search_engine.flight.one_way")}</li>
                            <li onClick={(e) => {
                                handleChangeTypeTravel(true);
                                e.stopPropagation();
                            }}>{t("build_package.search_engine.flight.round_trip")}</li>
                        </ul>
                    </TooltipContainer>
                )
            }
        </div>
    );
}

export default InputTypeTravelFlight;