import React from "react";
import "./styles.css";
import Loading from "../../share/components/loading";
import { useTranslation } from "react-i18next";
import CardTour from "./CardTour";

const AllCardsSpecialist = ({ data, urlImg, loading, detailTour }) => {
  const { t } = useTranslation("global");
  
  return (
    <div className="packages-section" style={{ width: "90%" }}>
      <h2 id="title" className="title-packages-section">
        {t("tourism.tours")}
      </h2>
      <p className="p-pack">
        {t("tourism.tours_description")}
      </p>
        <div className="content-packs-new">
          {loading === true ? (
            <Loading size={100} />
          ) : data?.length === 0 ? (
            <h2>{t("tourism.without_results")}</h2>
          ) : (
            data?.map((item, key) => {
              return (
                <CardTour
                  key={key}
                  data={item}
                  detailTour={detailTour}
                  urlImg={urlImg}
                />
              );
            })
          )}
        </div>
    </div>
  );
};

export default AllCardsSpecialist;
