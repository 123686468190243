import React, { useEffect, useState } from "react";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import "./styles.css";
import { MdOutlineLocationOn } from "react-icons/md";
import ModalContainer from "../../../share/components/modalContainer";
import TextComponent from "../../../share/components/fonts/TextComponent";
import { FaRegMap } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const DetailBuy = ({
  nom,
  address,
  hab,
  arrival,
  departure,
  checkIn,
  checkOut,
  stars,
  canceledDay,
  lat,
  lon,
  mountRoom,
}) => {
  const { t } = useTranslation("global");

  useEffect(() => {
    console.log(hab);
  }, []);

  const starsNumber = [];

  for (let i = 0; i < Number(stars); i++) {
    starsNumber.push(
      <SvgIcon key={i} name={iconList.IconStar} color={"#FFD902"} size={20} />
    );
  }

  const [dayCanceled, setDayCanceled] = useState();

  function restarDias(fechaStr, diasARestar) {
    // Convertir la cadena de fecha a un objeto Date
    let fecha = new Date(fechaStr);

    // Restar los días especificados
    fecha.setDate(fecha.getDate() - diasARestar);

    // Formatear la fecha en el formato deseado (MM/DD/YYYY)
    let dia = fecha.getDate();
    let mes = fecha.getMonth() + 1; // Los meses son de 0 a 11
    let anio = fecha.getFullYear();

    // Asegurarse de que el día y el mes tengan dos dígitos
    if (dia < 10) dia = "0" + dia;
    if (mes < 10) mes = "0" + mes;

    setDayCanceled(`${anio}/${mes}/${dia}`);

    return `${mes}/${dia}/${anio}`;
  }

  useEffect(() => {
    restarDias(arrival, canceledDay);
  }, []);

  const [activeChangeService, setActiveChangeService] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);

  useEffect(() => {
    if (activeChangeService) {
      setIframeKey((prevKey) => prevKey + 1);
    }
  }, [activeChangeService]);

  const delta = 0.0005; // Puedes ajustar este valor según sea necesario

  const latNum = parseFloat(lat);
  const lonNum = parseFloat(lon);
  
  const minLat = latNum - delta;
  const maxLat = latNum + delta;
  const minLon = lonNum - delta;
  const maxLon = lonNum + delta;
  
  const mapUrl = `https://www.openstreetmap.org/export/embed.html?bbox=${minLon}%2C${minLat}%2C${maxLon}%2C${maxLat}&layer=mapnik&marker=${lat}%2C${lon}`;

  return (
    <>
      <ModalContainer
        show={activeChangeService}
        hiddsetActiveChangeServiceen={setActiveChangeService}
        width={1200}
        height={700}
      >
        <iframe
          key={iframeKey}
          title="Map hotel"
          width="1200"
          height="630"
          loading="lazy"
          src={mapUrl}
        />
      </ModalContainer>
      <div className="content-detail-buy-hotel">
        <TextComponent fontWeight="bold" size={"md"} tag="h2">
          {t("hotels.reserve.purchase_details")}
        </TextComponent>
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <TextComponent fontWeight="bold" size={"md2"} tag="h3">
            {nom}
          </TextComponent>
          <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
            {starsNumber}
          </div>
        </div>

        {canceledDay | arrival ? (
          <div className="alert-cancelled-hotel">
            {t("hotels.reserve.can_cancel")} {dayCanceled}
          </div>
        ):null}
        <p
          style={{
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap: 5,
          }}
        >
          <MdOutlineLocationOn size={20} color="var(--bluePrimary)" /> {address}
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            fontSize: 14,
            cursor: "pointer",
          }}
          onClick={() => setActiveChangeService(true)}
        >
          <FaRegMap size={18} color="var(--bluePrimary)" /> 
          {t("hotels.reserve.see_map")}
        </p>
        <div className="content-check-group">
          <section className="check-in-detail">
            <span>{t("hotels.reserve.check_in")}</span>
            <TextComponent fontWeight="bold" size={"sm"}>
              {arrival}
            </TextComponent>
            <TextComponent fontWeight="normal" size={"sm"} tag="span">
              {checkIn}
            </TextComponent>
          </section>
          <section className="check-in-detail">
            <span>{t("hotels.reserve.check_out")}</span>
            <TextComponent fontWeight="bold" size={"sm"}>
              {departure}
            </TextComponent>
            <TextComponent fontWeight="normal" size={"sm"} tag="span">
              {checkOut}
            </TextComponent>
          </section>
        </div>
        {hab?.map((item, index) => {
          let allAcomodation = item?.acomodation?.split("-")?.map(Number);
          return (
            <div className="content-hab-total-paxes" key={index}>
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                  fontSize: 14,
                }}
              >
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  name={iconList.IconTwoPerson}
                  size={25}
                />{" "}
                <TextComponent size={"md2"}>
                  {allAcomodation[0]} {t("hotels.reserve.adults")}, {allAcomodation[1]} {t("hotels.reserve.children")}
                </TextComponent>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                  fontSize: 14,
                }}
              >
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  name={iconList.IconHotel}
                  size={25}
                />{" "}
                <TextComponent size={"md2"}>
                  ({mountRoom[index]}) {t("hotels.reserve.rooms")}
                </TextComponent>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                  fontSize: 14,
                }}
              >
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  name={iconList.IconHotel}
                  size={25}
                />{" "}
                {item?.name}
              </div>
              <div
                key={index}
                style={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                  fontSize: 14,
                }}
              >
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  size={25}
                  name={iconList.IconCoupon}
                />
                <TextComponent tag="span" size={"md2"}>
                  {
                    item["@attributes"]?.cod === "Solo alojamiento" ?
                    t("hotels.results.includes.only_accommodation")
                    : item["@attributes"]?.cod === "Alojamiento y desayuno" ?
                    t("hotels.results.includes.with_breakfast")
                    : item["@attributes"]?.cod
                  }
                </TextComponent>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DetailBuy;
