import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiHotelFill } from "react-icons/ri";
import { FaUserDoctor } from "react-icons/fa6";
import { MdOutlineArrowOutward } from "react-icons/md";
import { IoMdHelpCircleOutline } from "react-icons/io";

import { setProceduresData } from "@/store/slices/dataStepz/dataStepzSlice";
import useSearchBuildPackage from "@/Hooks/BuildPackage/useBuildPackageSearchEngine/UseBuildPackageSearchEngine";
import styles from "./styles.module.css";

import Tooltip from "@/Components/Tooltip/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmModal from "../ConfirmModal";
import InputProcedures from "./components/InputProcedures/InputProcedures";
import InputEspecialties from "./components/InputEspecialties/InputEspecialties";
import { useTranslation } from "react-i18next";

const SpecialistClinic = ({ appointments, title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation("global");
  const [show, setShow] = useState(false);
  const [showInputProcedure, setShowInputProcedure] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dateGoingSelectedData = useSelector(
    (state) => state.dataSearchEngine.dateGoingSelectedData
  );
  const procedureData = useSelector(
    (state) => state.dataSearchEngine.procedureData
  );
  const proceduresData = useSelector((state) => state.dataStepz.proceduresData);

  const btnAddProcedure = () => {
    setShowInputProcedure(true);
  };

  const deleteProcedure = (index) => () => {
    const newProceduresData = proceduresData.filter(
      (procedure, i) => i !== index
    );

    dispatch(setProceduresData(newProceduresData));
  };

  const {
    showSpecialties,
    setShowSpecialties,
    especialtie,
    setEspecialtie,
    checkSpeciality,
    setCheckSpeciality,
    idSpecialty,
    setIdSpecialty,
    showProcedures,
    setShowProcedures,
    procedure,
    setProcedure,
    checkProcedure,
    setCheckProcedure,
  } = useSearchBuildPackage();

  const saveProcedure = () => {
    if (procedure === "") {
      setCheckProcedure(true);
    }

    if (especialtie === "") {
      setCheckSpeciality(true);
    }

    if (procedure === "" || especialtie === "") {
      return;
    }

    if (
      proceduresData.includes(procedure) ||
      procedure === procedureData.name
    ) {
      const message = t("build_package.steps.resume.already_added_procedure")
      toast(message, { icon: "❌" });
      return;
    }

    setShowInputProcedure(false);
    dispatch(setProceduresData([...proceduresData, procedure]));

    setProcedure("");
    setEspecialtie("");
    setIdSpecialty(null);
  };

  const confirmProcedure = () => {
    setShowConfirmModal(false);
    navigate("/build-package/std");
  };

  return (
    <>
      <div className={styles.cartItemsItem}>
        {!title ? null : <h3>{t("build_package.steps.resume.specialist_and_clinic")}</h3>}

        <div className={styles.cartItemsItemOne}>
          <div className={styles.itemAppointment}>
            <div className={styles.itemHeader}>
              <div className={styles.itemHeaderIconText}>
                <div className={styles.icon}>
                  <FaUserDoctor />
                </div>
                <div className={styles.itemHeaderText}>
                  <h4 className={styles.title}>{appointments.specialist.name}</h4>
                  <p className={styles.shortDescription}>
                    {t("build_package.steps.resume.specialist_at")} 
                    {" "}
                    {appointments.specialist.categories
                      .map((category) => category.name)
                      .join(", ")}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.itemDetails}>
              <p className={styles.longDescription}>
                {appointments.specialist.description}
              </p>
            </div>

            <div className={styles.itemLinks}>
              <p>
              {t("build_package.steps.resume.date")} <span>{dateGoingSelectedData}</span>
              </p>
              <p>
                <div className={styles.containerAddProcedure}>
                  {t("build_package.steps.resume.another_quote_procedure")}{" "}
                  <button
                    onClick={btnAddProcedure}
                    className={styles.btnAddProcedure}
                  >
                    <AddIcon style={{ fontSize: "15px" }} />
                    {t("build_package.steps.resume.add_procedure")}
                  </button>
                </div>
                <Toaster />
                {showInputProcedure && (
                  <div className={styles.containerInputsAddProcedure}>
                    <div className={styles.actionsInputs}>
                    <div>
                      <label htmlFor="especialties"> {t("build_package.steps.resume.specialty")}</label>
                      <br />
                      <InputEspecialties
                        showSpecialties={showSpecialties}
                        setShowSpecialties={setShowSpecialties}
                        especialtie={especialtie}
                        setEspecialtie={setEspecialtie}
                        checkSpeciality={checkSpeciality}
                        setCheckSpeciality={setCheckSpeciality}
                        setIdSpecialty={setIdSpecialty}
                      />
                    </div>
                    <div>
                      <label> {t("build_package.steps.resume.procedure")}</label>
                      <br />
                      <InputProcedures
                        showProcedures={showProcedures}
                        setShowProcedures={setShowProcedures}
                        procedure={procedure}
                        setProcedure={setProcedure}
                        checkProcedure={checkProcedure}
                        setCheckProcedure={setCheckProcedure}
                        idSpecialty={idSpecialty}
                      />
                    </div>
                    </div>
                    <div className={styles.actionsButtons}>
                    <button onClick={saveProcedure}> {t("build_package.steps.resume.save")}</button>
                    <button onClick={() => {
                      setShowInputProcedure(false)
                      setProcedure("")
                      setEspecialtie("")
                      setIdSpecialty(null)
                    }}>
                       {t("build_package.steps.resume.cancel")}
                    </button>
                    </div>
                  </div>
                )}
                <div className={styles.containerAllProcedures}>
                  <span>{procedureData.name}</span>
                  {Array.isArray(proceduresData) &&
                    proceduresData.length > 0 &&
                    proceduresData.map((procedure, index) => (
                      <div
                        className={styles.containerAddedProcedures}
                        key={index}
                      >
                        <span>{procedure}</span>
                        <button onClick={deleteProcedure(index)}>
                          <ClearIcon
                            style={{
                              fontSize: "15px",
                              color: "var(--bluePrimary)",
                            }}
                          />
                        </button>
                      </div>
                    ))}
                </div>
              </p>
            </div>
            <div className={styles.itemHeaderClinic}>
              <div className={styles.itemHeaderIconText}>
                <div className={styles.icon}>
                  <RiHotelFill />
                </div>
                <div className={styles.itemHeaderText}>
                  <h4 className={styles.title}>{appointments.clinic.name}</h4>
                  <p className={styles.shortDescription}>
                    {appointments.clinic.address}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.itemDetails}>
              <p className={styles.longDescription}>
                {appointments.clinic.description}
              </p>
            </div>
          </div>

          <div className={styles.buttonsActions}>

            <Tooltip content={t("build_package.steps.resume.tds_indications")} mobilePosition="bottom">
              <button onClick={() => setShow(true)} className={styles.helpSTD}>
                
                <IoMdHelpCircleOutline size={25} />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>

      <ConfirmModal
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        confirmProcedure={confirmProcedure}
        hasCloseButton={false}
      />
      
    </>
  );
};

export default SpecialistClinic;
