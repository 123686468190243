import React, { useEffect, useState } from "react";
import Find from "../../../share/components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "../../../share/components/CarouselServices/CarouselSevices";
import DetailPayHotel from "./detailPayTransport/DetailPayTransport";
import connectMiles from "../../../../assets/logos/connectmiles.png";
import "./styles.css";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import ModalContainer from "../../../share/components/modalContainer";
import Loading from "../../../share/components/loading";
import SuccessPay from "./successPay/SuccessPay";
import PendingPay from "./pendingPay/PendingPay";
import RejectPay from "./rejectPay/RejectPay";
import usePayTransport from "../../../../viewModels/transport/usePayTransport";
import ReturnPage from "../../../share/components/return/ReturnPage";
import { useTranslation } from "react-i18next";
import GalleryImg from "@/ui/views/Hotel/DetailHotel/GalleryImg/GalleryImg";
import ModalCardDetail from "@/ui/views/Hotel/HotelResult/allResultsCards/components/ModalCardDetail";
import { currencyFormat } from "@/ui/utils";
import { MdCameraAlt } from "react-icons/md";
import { GoCheckCircleFill } from "react-icons/go";
import { TbLoader2 } from "react-icons/tb";
import { IoCloseCircle } from "react-icons/io5";
import { BiQuestionMark } from "react-icons/bi";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";

const PayTransport = () => {
  const {
    dataEmitter,
    incompleteInformation,
    dataValue,
    loadinPay,
    setLoadinPay,
    statusTransaction,
  } = usePayTransport();

  const [t, i18n] = useTranslation("global");

  const [transportUrl, setTransportUrl] = useState("/transport");

  const [loadingImage, setLoadingImage] = useState(true);
  const [slideShow, setSlideShow] = useState(false);
  const [modalCardDetail, setModalDetail] = useState(false);
  const [fotos, setFotos] = useState([
    "https://images.pexels.com/photos/1135379/pexels-photo-1135379.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/244818/pexels-photo-244818.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/1405665/pexels-photo-1405665.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/164634/pexels-photo-164634.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/235066/pexels-photo-235066.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/220309/pexels-photo-220309.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/452099/pexels-photo-452099.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  ]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingImage(false);
    }, 3000);
  }, []);

  function Image({ url }) {
    const onLoad = () => {
      setLoadingImage(false);
    };
    const onError = () => {
      setLoadingImage(true);
    };
    return (
      <img
        className="image-hotel-detail"
        src={url}
        onLoad={onLoad}
        onError={onError}
        alt="Hotel"
      />
    );
  }

  const [dollarFlight, setDollarFlight] = useState(
    localStorage.getItem("dollarFlight") === null
      ? "US"
      : localStorage.getItem("dollarFlight")
  );

  const typeOfCoin = {
    name: dollarFlight,
    abbreviation: dollarFlight,
    simbol: "$",
  };

  function formatearFecha(fechaISO) {
    // Crear un objeto de fecha a partir de la cadena ISO
    const fecha = new Date(fechaISO);

    // Obtener los componentes de la fecha y hora
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const año = fecha.getFullYear();
    const hora = fecha.getHours().toString().padStart(2, "0");
    const minutos = fecha.getMinutes().toString().padStart(2, "0");

    // Formatear la fecha y hora según el formato deseado
    const fechaFormateada = `${dia}-${mes}-${año} ${hora}:${minutos}`;

    return fechaFormateada;
  }

  return (
    <div>
      <CarouselSevices current={servicesTravel[3].title} />
      <ReturnPage nav={transportUrl} reload={true} />
      <div className="contain-hotel-pay">
        {loadinPay && <Loading size={90} />}
        {!loadingImage && (
          <GalleryImg
            Images={fotos}
            slideShow={slideShow}
            setSlideShow={setSlideShow}
          />
        )}
        <ModalCardDetail
          modalCardDetail={modalCardDetail}
          setModalDetail={setModalDetail}
          modalPay={true}
          statePay={statusTransaction?.pay?.status}
          valuePay={statusTransaction}
          value={
            currencyFormat(statusTransaction?.pay?.price, typeOfCoin).total
          }
          dateHour={formatearFecha(statusTransaction?.pay?.date)}
          statusPay={statusTransaction?.pay?.status}
          incompleteInformation={incompleteInformation}
        />
        <div className="layout-hotel-pay">
          <section className="main-hotel-pay">
            <div className="main-hotel-title-pay">
              <h2>{t("transfers.transaction_status")}</h2>
              <p>{t("transfers.transaction_status_subtitle")}</p>
            </div>
            <div className="contain-img-principal-button-hotel-pay">
              <div
                className="contain-img-principal-hotel-pay"
                onClick={() => {
                  setSlideShow(!slideShow);
                }}
              >
                <div className="section-img-big-pay">
                  <div className="section-img-1-pay">
                    {loadingImage ? (
                      <div className="img-hotel-skeleton-pay">
                        <MdCameraAlt size={40} color="var(--greyPrimary)" />
                      </div>
                    ) : (
                      <Image url={fotos[0]} />
                    )}
                  </div>
                </div>
                <div className="section-img-layout-for-pay">
                  <div className="section-img-layout-1-pay">
                    {loadingImage ? (
                      <div className="img-hotel-skeleton-pay">
                        <MdCameraAlt size={40} color="var(--greyPrimary)" />
                      </div>
                    ) : (
                      <Image url={fotos[2]} />
                    )}
                  </div>
                  <div className="section-img-layout-2-pay">
                    {loadingImage ? (
                      <div className="img-hotel-skeleton-pay">
                        <MdCameraAlt size={40} color="var(--greyPrimary)" />
                      </div>
                    ) : (
                      <Image url={fotos[3]} />
                    )}
                  </div>
                </div>
              </div>

              <button
                onClick={() => setModalDetail(true)}
                className="contain-img-principal-hotel-button-pay"
              >
                {t("fligth.flight_pays.see_info")}
              </button>
            </div>
          </section>
          <section className="content-state-pay-state">
            <div
              className={`content-state-pay ${statusTransaction?.pay?.status === "APPROVED"
                  ? "APPROVED"
                  : statusTransaction?.pay?.status === "PENDING"
                    ? "PENDING"
                    : statusTransaction?.pay?.status === "REJECTED"
                      ? "REJECTED"
                      : ""
                }`}
            >
              {statusTransaction?.pay?.status === "APPROVED" ? (
                <GoCheckCircleFill size={40} />
              ) : statusTransaction?.pay?.status === "PENDING" ? (
                <TbLoader2 size={40} />
              ) : statusTransaction?.pay?.status === "REJECTED" ? (
                <IoCloseCircle size={40} />
              ) : (
                <BiQuestionMark size={40} />
              )}
              <div>
                <div className="content-state-pay-message">
                  <h3>{t("hotels.payment_detail.status.text")}:</h3>
                  <p>
                    {statusTransaction?.pay?.status === "APPROVED"
                      ? t("hotels.payment_detail.status.approved")
                      : statusTransaction?.pay?.status === "PENDING"
                        ? t("hotels.payment_detail.status.pending")
                        : statusTransaction?.pay?.status === "REJECTED"
                          ? t("hotels.payment_detail.status.rejected")
                          : t("hotels.payment_detail.status.loading")}
                  </p>
                </div>
                <p>
                  {statusTransaction?.pay?.status === "APPROVED"
                    ? t("hotels.payment_detail.status.success_payment")
                    : statusTransaction?.pay?.status === "PENDING"
                      ? t("hotels.payment_detail.status.pending_payment")
                      : statusTransaction?.pay?.status === "REJECTED"
                        ? t("hotels.payment_detail.status.rejected_payment")
                        : t("hotels.payment_detail.status.loading")}
                </p>
              </div>
            </div>

            <div className="aside-hotel-pay">
              <DetailPayHotel
                nom={dataValue?.description}
                covid={dataValue?.service?.covidPrepared}
                passengers={dataValue?.baby + dataValue?.passengers}
                img={dataValue?.service?.vehicleHDImage}
                vehicle={dataValue?.service?.vehicle}
                price={dataValue?.price}
                meet={dataValue?.service?.meetAndGreet}
              />
              <div className="pay-secure-hotel">
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  name={iconList.IconKey}
                  size={40}
                />
                <div>
                  <p>{t("fligth.flight_pays.safe_pay")}</p>
                  <span>{t("fligth.flight_pays.high_traffic_pay")}</span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default PayTransport;