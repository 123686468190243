import React from "react";
import "./styles.css";
import { IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const ModalCardDetail = ({
  data,
  include,
  more,
  total,
  modalCardDetail,
  setModalDetail,
  modalPay,
  statePay,
  valuePay,
  value,
  dateHour,
  statusPay,
  incompleteInformation,
}) => {
  const { t } = useTranslation("global");

  return (
    <div
      className={`${
        modalCardDetail ? "modal-card-detail" : "modal-card-detail close"
      }`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          setModalDetail(false);
        }
      }}
    >
      <div
        className={`${
          modalCardDetail
            ? "content-modal-card-detail"
            : "content-modal-card-detail close"
        }`}
      >
        <button
          className="modal-card-detail-close"
          onClick={() => setModalDetail(false)}
        >
          <IoIosClose size={45} color="var(--bluePrimary)" />
        </button>

        <div className="content-modal-card">
          <div className="content-modal-card-title">
            <h2>
              {statePay
                ? statePay === "APPROVED"
                  ? t("transfers.aproved_ticket")
                  : statePay === "PENDING"
                  ? t("transfers.pending_ticket")
                  : statePay === "REJECTED"
                  ? t("transfers.rejected_ticket")
                  : t("transfers.error_ticket")
                : t("reserve_modal.title")
              }
            </h2>

            <p>
              {
                valuePay?.pay?.reference ? 
                t("reserve_modal.reference")
                : t("reserve_modal.reserve_code")
              } 
              :
              <span className="ml-[2px]">
                {data?.cod || data?.codigo_hotel || valuePay?.pay?.reference}
              </span>
            </p>
          </div>
          <ul className="list-content-modal-card">
            {(data?.nom || data?.nombre_h) && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_name")}
                </span>
                <span className="value-content-modal-card">
                  
                {data?.nom || data?.nombre_h}
                </span>
              </li>
            )}

            {valuePay?.pay?.reference && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_reference")}
                </span>
                <span className="value-content-modal-card">
                  {valuePay?.pay?.reference}
                </span>
              </li>
            )}

            {value && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_value")}
                </span>
                <span className="value-content-modal-card">{value}</span>
              </li>
            )}

            {dateHour && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_date")}
                </span>
                <span className="value-content-modal-card">{dateHour}</span>
              </li>
            )}
            {statusPay && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_status")}
                </span>
                <span className="value-content-modal-card">{statusPay || data?.nombre_h}
              </span>
              </li>
            )}

            {data?.tipo_establecimiento && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_type")}
                </span>
                <span className="value-content-modal-card">
                  {
                    data?.tipo_establecimiento
                    ? "Hotel"
                    : data?.tipo_establecimiento
                  }
                </span>
              </li>
            )}

            {(data?.pob || data?.poblacion) && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_city")}
                </span>
                <span className="value-content-modal-card">
                  {data?.pob?.charAt(0).toUpperCase() +
                    data?.pob?.slice(1).toLowerCase() ||
                  data?.poblacion?.charAt(0).toUpperCase() +
                    data?.poblacion?.slice(1).toLowerCase() ||
                    data?.poblacion?.charAt(0).toUpperCase() +
                      data?.poblacion?.slice(1).toLowerCase()}
                </span>
              </li>
            )}

            {data?.direccion && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_address")}
                </span>
                <span className="value-content-modal-card">
                  {data?.direccion}
                </span>
              </li>
            )}

            {include && (
              <li>
                  <span className="label-content-modal-card">
                    {t("reserve_modal.reserve_includes")}
                  </span>
                  <span className="value-content-modal-card">
                    {
                      include === "Solo alojamiento" ?
                      t("hotels.results.includes.only_accommodation")
                      : include === "Alojamiento y desayuno" ?
                      t("hotels.results.includes.with_breakfast")
                      : include
                    }
                  </span>
                </li>
            )}

            {data?.res?.pax?.[0]?.hab?.[0]?.["@attributes"]?.desc && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.also")}
                </span>
                <span className="value-content-modal-card">
                  {data?.res?.pax?.[0]?.hab?.[0]?.["@attributes"]?.desc}
                </span>
              </li>
            )}

            {more && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.in_addition")}
                </span>
                <span className="value-content-modal-card">{more}</span>
              </li>
            )}

            {!valuePay && (
              <li>
                <span className="label-content-modal-card">
                  {t("hotel.include_taxes")}
                </span>
              </li>
            )}

            {incompleteInformation !== "" && (
              <li>
                <span className="label-content-modal-card">
                  {incompleteInformation}
                </span>
              </li>
            )}
          </ul>

          <div className="price-content-modal-card">
            <span className="value-content-modal-card">
              {t("reserve_modal.total")}
            </span>
            <span>
              <b>
                {total || value} {modalPay ? "" : "COP"}
              </b>
            </span>
          </div>
          <p
            className="price-content-modal-card-description"
          >
            
            {statePay
              ? statePay === "APPROVED"
                ? t("reserve_modal.approved_status_text", { requestId: valuePay?.pay?.request_id })
                : statePay === "PENDING"
                ? t("reserve_modal.pending_status_text", { requestId: valuePay?.pay?.request_id })
                : statePay === "REJECTED"
                ? t("reserve_modal.rejected_status_text", { requestId: valuePay?.pay?.request_id })
                : t("reserve_modal.error_status_text")
              : t("reserve_modal.footer_text")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalCardDetail;
