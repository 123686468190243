import React from 'react'
import "./styles.css"
import { useTranslation } from 'react-i18next'

const SendInvoices = () => {
    const { t, i18n } = useTranslation("global")

    return (
        <div className='content-send-vouchers'>
            <section className='titles-vouchers'>
                <h2>{t("invoices.title")}</h2>
                <p>{t("invoices.description")}</p>
            </section>
            <form action="" className='form-send-vouchers'>
                <div className='camp-form-send-vouchers'>
                    <label htmlFor="">{t("invoices.email")}</label>
                    <input type="text" placeholder={
                        i18n.language === "es" ? "tucorreo@gmail.com" : "youremail@gmail.com"
                    } />
                </div>
                <div className='camp-form-send-vouchers'>
                    <label htmlFor="">{t("invoices.confirm_email")}</label>
                    <input type="text" placeholder={
                        i18n.language === "es" ? "tucorreo@gmail.com" : "youremail@gmail.com"
                    } />
                </div>
            </form>
        </div>
    )
}

export default SendInvoices