import React, { useEffect } from "react";
import { formatMoney } from "../../../../utils";
import { MdCameraAlt } from "react-icons/md";

import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";


const SliderCard = ({
  carousel = false,
  img,
  title,  
  dueDate,
  colorButton = "",
  price,
  typeCurrency,
  boxShadow = false, 
  imgOption = false,
  typePack,
  handleClickPack,
  item,
  loadingImage,
  setLoadingImage
}) => {
  const { t } = useTranslation("global");

  function textCutter(cantidadCaracteres, texto) {
    if (texto.length <= cantidadCaracteres) {
      return texto;
    }
    return texto.slice(0, cantidadCaracteres) + "...";
  }

  const onLoad = () => {
    setLoadingImage(false);
  };

  const onError = () => {
    setLoadingImage(true);
  };

  useEffect(() => {
    setLoadingImage(false)
  }, [typePack]);

  return (
    <div
      className="slider-card"
      style={{
        boxShadow: boxShadow ? "0px 4px 4px 0px #00000040" : "",
        maxWidth: carousel ? "90%" : "none",
        margin: "4px"
      }}
    >
      {loadingImage ? (
        <div className="skeleton-home-img">
          <div className="img-home-skeleton">
            <MdCameraAlt size={40} color="#c8c8c8" />
          </div>
        </div>
      ) : (
        <img
          onLoad={onLoad}
          onError={onError}
          src={
            typePack
              ? `${process.env.REACT_APP_URL_API_AIOP}storage/medic_pack/${img}`
              : `${process.env.REACT_APP_URL_API_AIOP}storage/pack/${img}`
          }
          alt={img}
          style={{
            minWidth: carousel ? "auto" : "",
            maxHeight: carousel ? "none" : "",
            height: imgOption ? "300px" : "",
          }}
        />
      )}

      <div className="ofertsTurismSaludCardContent">
        <> 
          <div className="ofertsTurismSaludCardText">
            <Tooltip title={title} placement="bottom" >
              <h3
                className="ofertsTurismSaludCardTitle"
                style={{ color: colorButton }}
              >
                {textCutter(21, title)}
              </h3>
            </Tooltip>
            <p> 
              <p>
                {t("packs.health.available_until")} {dueDate}
              </p>
            </p>
          </div> 
          <div className="ofertsTurismSaludCardButtons">
            <button
              className="ofertsTurismSaludCardButtonn"
              style={{
                backgroundColor: colorButton,
                transition: "all 0.5 ease",
              }}
            >
              {formatMoney(price) + " " + typeCurrency}
            </button>
            <button
              style={{
                color: colorButton,
                transition: "all 0.6 ease",
                fontSize: "12px",
                fontWeight: "700",
                cursor: 'pointer'
              }}
              onClick={() => handleClickPack(item, item?.id)}
              className="ofertsLink"
            >
              {t("common.see_more.capitalized")}
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default SliderCard;
