import { Grid } from '@mui/material';
import styles from "./index.module.css";
import { IoIosAdd } from 'react-icons/io';

const formatDate = (dateString) => {
    if (!dateString) return '';
    const [month, day, year] = dateString.split('/');
    return `${month}/${day}/${year.slice(-2)}`;
};

const Specialist = ({ dataPackage }) => {
    
    return (
        <div className={styles.mainClinicDivPackage}>
            <div className={styles.topClinicDivPackage}>
                <h3>{dataPackage.specialist?.name}</h3>
                <h4>{dataPackage.specialist?.categories[0].name}</h4>
            </div>

            <div className={styles.infoSpecialistPackage}>

                <div className={styles.descriptionSpecialist}>
                    <div className={styles.dataDesctip} >
                        <h3>Descripción del doctor</h3>
                        <p>
                            {dataPackage.specialist?.description}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Specialist;