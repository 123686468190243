import { useState } from "react";

const useStepBuildPackage = (initialStep = 1) => {
  const [activeStep, setActiveStep] = useState(initialStep);
  const [completedSteps, setCompletedSteps] = useState([initialStep]);
  const [payStatus, setPayStatus] = useState(null);

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  const resetSteps = () => {
    setActiveStep(initialStep);
    setCompletedSteps([initialStep]);
  };

  const handlePayStatus = (status) => {
    setPayStatus(status);
  };

  const resetPayStatus = () => {
    setPayStatus(null);
  };

  return {
    activeStep,
    completedSteps,
    handleStepClick,
    resetSteps,
    payStatus,
    handlePayStatus,
    resetPayStatus,
  };
};

export default useStepBuildPackage;
