import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdCameraAlt } from "react-icons/md";
import { useTranslation } from "react-i18next";

const CardTours = ({ pack, imgType, width = "" }) => {
  const { image, fare, name, start_time, end_time, currency, due_date, start_date, id } = pack;

  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();

  // Estado para controlar si la imagen ha cargado
  const [imageLoaded, setImageLoaded] = useState(false);

  function formatDateTime(dateTime) {
    if (!dateTime) {
      return t("tourism.detailed_tour.invalid_date");
    }

    const correctedDateTime = dateTime.replace(" ", "T");

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const date = new Date(correctedDateTime);

    if (isNaN(date)) {
      return t("tourism.detailed_tour.invalid_date");
    }

    const formattedDate = i18n.language === "es" ? new Intl.DateTimeFormat("es-ES", options).format(date) : new Intl.DateTimeFormat("en-US", options).format(date);

    return formattedDate;
  }

  const handleClick = () => {
    navigate(`/tour/${id}`);
  };

  function recortarTexto(cantidadCaracteres, texto) {
    if (texto.length <= cantidadCaracteres) {
      return texto;
    }
    return texto.slice(0, cantidadCaracteres) + "...";
  }

  return (
    <div className="card-packages-new" style={{ width: width ? width : "" }}>
      {/* Muestra el esqueleto solo si la imagen no ha cargado */}
      {!imageLoaded && (
        <div className="img-transport-skeleton">
          <MdCameraAlt size={40} color="var(--greySecundary)" />
        </div>
      )}

      {/* Agrega el evento onLoad a la imagen para cambiar el estado cuando se cargue */}
      <img
        src={`${
          imgType === true
            ? image
            : `${process.env.REACT_APP_URL_API_AIOP}storage/tour/${image}`
        }`}
        alt={name}
        onLoad={() => setImageLoaded(true)}
        style={{ display: imageLoaded ? "block" : "none" }} // Oculta la imagen hasta que se cargue
      />

      <div className="description">
        <h2>{recortarTexto(22, name)}</h2> 
        <p>{formatDateTime(start_time)} - {formatDateTime(end_time)}</p>
      </div>
      <div className="price">
        <span className="back-price">{fare}</span>
        <span className="see-more" onClick={handleClick}>
          {t("tourism.see_more")}
        </span>
      </div>
    </div>
  );
};

export default CardTours;
