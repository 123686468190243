import React, { useEffect, useState } from "react";
import CarouselSevices from "../../../share/components/CarouselServices/CarouselSevices";
import StepStep from "../../components/Step/StepStep";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";

import { setCurrentStep, setDataPackage, setLastSearch, setProceduresData, setRoutes } from "@/store/slices/dataStepz/dataStepzSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import StepHome from "../../components/Step/StepHome";
import DataSearch from "../../components/DataSearch/DataSearch";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { IoClose, IoCloseCircle } from "react-icons/io5";
import { TbLoader2 } from "react-icons/tb";
import { BiQuestionMark } from "react-icons/bi";
import { GoCheckCircleFill } from "react-icons/go";
import { currencyFormat } from "@/ui/utils";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";
import { FaCheck } from "react-icons/fa6";
import PaymentApprovedBanner from "@/common/components/PaymentApprovedBanner";
import Loading from "@/ui/views/share/components/loading";
import { resetState } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import useStepBuildPackage from "@/Hooks/BuildPackage/useStepBuildPackage/useStepBuildPackage";

const StepValuationPayment = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  const { handlePayStatus } = useStepBuildPackage();

  const notify = (title, icon) =>
    toast(title, {
        icon: icon,
    }
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // obtain the individual parts of the date
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // The months begin at 0
    const year = date.getFullYear();

    // Obtain the parts of the hour
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Final format
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const [dataStepzTitles, setDataStepzTitles] = useState([]);

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const especialtieAvliable = useSelector((state) => state.dataStepz.especialtieAvliable);
  const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
  const routes = useSelector((state) => state.dataStepz.routes);

  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const [authUser, setAuthUser] = useState(null);
  const [payStatus, setPayStatus] = useState(null);
  const [hasAlreadyPaid, setHasAlreadyPaid] = useState(false);
  const [packageUrl, setPackageUrl] = useState("");
  const [isLoadingAuthInfo, setIsLoadingAuthInfo] = useState(true);
  const [userPayments, setUserPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);


  const [dollarFlight, setDollarFlight] = useState(
    localStorage.getItem("dollarFlight") === null
      ? "COP"
      : localStorage.getItem("dollarFlight")
  );
  
  const typeOfCoin = {
    name: dollarFlight,
    abbreviation: dollarFlight,
    simbol: "$",
  };

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  useEffect(() => {
    if (setSpetVariableData === 2) {
        dispatch(setCurrentStep(7));
    } else {
        dispatch(setCurrentStep(6))
    }
    
    dispatch(
        setRoutes({
            ...routes,
            valuation: {
                route: location.pathname,
                search: location.search,
            },
        })
    );
  }, []);

  useEffect(() => {
    function handleSetDataStepzTitles() {
        let updatedTitles = [];
        if (setSpetVariableData === 0) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.hotel"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
                t("build_package.steps.titles.valuation_payment"),
            ];
        } else if (setSpetVariableData === 1) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
                t("build_package.steps.titles.valuation_payment"),
            ];
        } else if (setSpetVariableData === 2) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.hotel"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
                t("build_package.steps.titles.valuation_payment"),
            ];
        } else if (setSpetVariableData === 3) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.hotel"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
                t("build_package.steps.titles.valuation_payment"),
            ];
        }
        setDataStepzTitles(updatedTitles);
    }

    handleSetDataStepzTitles();
  }, [setSpetVariableData, t]);

  const handleGoToMyMovements = () => {
    dispatch(setDataPackage({}));
    dispatch(setRoutes({}));
    dispatch(setLastSearch({}));
    dispatch(setProceduresData([]));
    dispatch(resetState());

    localStorage.removeItem('form-data-procedure-std');
    localStorage.removeItem('data-std');
    localStorage.removeItem('has-been-paid-build-package');
    localStorage.removeItem('data-pay-build-package-status');
    localStorage.removeItem('request-id-pay-build-package');
    localStorage.removeItem('data-pay-build-package');
    localStorage.removeItem('data-pay-build-package-body-std');
    localStorage.removeItem('data-pay-build-package-body-aiop');
    localStorage.removeItem('data-pay-build-package-body-crm-packages');

    navigate("/profile?option=3");
  }

  const sendStd = async (stdBody, aiopBody, stdBodyPackage) => {
    try {
      setIsLoading(true)

        // TODO: Eliminar URLs del código y pasarlas al archivo .env como una variable
        const urlPackage = `https://crmedic.pdtclientsolutions.com/api/wellezy/store/package`

        const url = `${process.env.REACT_APP_URL_API_FINANMED}/store/std`
        await axios.post(url, stdBody)

        if (especialtieAvliable) {
            await axios.post(urlPackage, stdBodyPackage)
            await sendDataAiop(aiopBody)
        } else {
            await sendDataAiop(aiopBody)
        }
    } catch (error) {
      setIsLoading(false)
        console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const sendDataAiop = async (aiopBody) => {
    try {
        const url = `${process.env.REACT_APP_URL_API_AIOP}api/searchs/build/package`
        
        await axios.post(url, aiopBody)
    } catch (error) {
        console.error(error)
        throw new Error(error)
    }
  }

  const checkBuildPackagePaymentStatus = async (authUserData) => {
    try {
      const requestId = localStorage.getItem("request-id-pay-build-package");
      const url = process.env.REACT_APP_URL_STATE_PAY;
      const paymentStatus = await axios.post(`${url}pack`, { request_id: Number(requestId) });
      
      localStorage.setItem("data-pay-build-package-status", JSON.stringify(paymentStatus.data));

      if (paymentStatus.data?.pay?.status === "APPROVED") {
        const paymentsArray = []

        paymentsArray.push(paymentStatus.data?.pay)

        setUserPayments(paymentsArray);

        const paymentInformation = paymentStatus.data?.pay;

        const paymentDataToStore = {
          user_id: authUserData.id,
          email: authUserData.email,
          localizator: paymentInformation.localizator,
          payment_date: paymentInformation.date,
          payment_created_at: paymentInformation.created_at,
          payment_updated_at: paymentInformation.updated_at,
          price: paymentInformation.price.toString(),
          reference: paymentInformation.reference,
          reason: paymentInformation.reason,
          status: paymentInformation.status,
          request_id: paymentInformation.request_id,
          type: paymentInformation.type
        }

        const aiopBody = JSON.parse(localStorage.getItem("data-pay-build-package-body-aiop"));
        const stdBody = JSON.parse(localStorage.getItem("data-pay-build-package-body-std"));
        const stdBodyPackage = JSON.parse(localStorage.getItem("data-pay-build-package-body-crm-packages"));

        const checkIfHasBeenPaid = localStorage.getItem('has-been-paid-build-package')

        if (!checkIfHasBeenPaid) {
          const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-payments`;
          await axios.post(url, paymentDataToStore);

          await sendStd(stdBody, aiopBody, stdBodyPackage);

          localStorage.setItem('has-been-paid-build-package', 'true');

          const message = t("packs.tourism.success.approved_payment")
          notify(message, "✅");
        } else {
          const message = t("packs.tourism.success.already_paid")
          setHasAlreadyPaid(true);
          notify(message, "✅");
        }
      }
      else if (paymentStatus.data?.pay?.status === "REJECTED") {
        const message = t("packs.tourism.errors.rejected_payment")
        notify(`Error: ${message}`, "❌");
      } else {
        const message = t("packs.tourism.errors.pending_payment")
        notify(`Error: ${message}`, "❌");
      }

      handlePayStatus(paymentStatus.data?.pay);
      setPayStatus(paymentStatus.data?.pay);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const checkAuthInfo = async () => {
      setIsLoadingAuthInfo(true);
      // Verify if there is an authenticated user
      const authUserSession = JSON.parse(localStorage.getItem("@authUserSession") || "{}");
      if (!authUserSession?.user?.id) {
        navigate("/login");
      }
  
  
      setAuthUser(authUserSession?.user);
  
      // Verify if in the store Storage there is a Request-ID-Pay-Build-Package to verify the payment status
      const requestId = localStorage.getItem("request-id-pay-build-package");
  
      if (requestId) {
        await checkBuildPackagePaymentStatus(authUserSession?.user);
      } else {
        navigate("/build-package");
      }
  
      setIsLoadingAuthInfo(false);
    }

    checkAuthInfo();
  }, []);

  useEffect(() => {
    if (payStatus?.status === "APPROVED") {
      setIsMounted(false);
      setTimeout(() => setIsMounted(true), 0);
    }
  }, [payStatus]);  

  return (
    <div>
      {
        (isLoadingAuthInfo || isLoading ) &&
        <Loading />
      }

      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>

      <div className="maxWidthBuildPackage">
        {isMounted && (
          <div className="itemSectionAll">
            <StepHome disabled={payStatus?.status === "APPROVED"}/>
            {dataStepzTitles.map((item, index) => (
              <StepStep
                key={index}
                title={item}
                step={index + 1}
                onClick={handleStepClick}
                dataPackage={dataPackage}
                disabled={payStatus?.status === "APPROVED"}
              />
            ))}
          </div>
        )}
        <DataSearch />

        {payStatus?.status === "APPROVED" && <PaymentApprovedBanner />}

        <div
          className="methodPayContainer"
        >
          <div className='methodPayHeader'>
            <h3>{t("packs.tourism.payment_detail.title")}</h3>
            <p>{t("packs.tourism.payment_detail.description")}</p>
          </div>
          <div className='methodPayContain' >
            <div className='methodPayContainQuestions'>
              <div className='methodPayContainerTermsContain'>
                <div className='detailPayFlight'>
                  <div className="detailPayFlightHeader">
                    <div className="detailPayFlightInfo flex justify-center text-center w-full">
                      <h2 >
                        <span className="text-2xl">
                          {t("build_package.steps.titles.valuation_payment")}
                        </span>
                        <br />
                        <span className="text-xl font-[600] text-customHoverBlue">
                          ({t("build_package.steps.std.description")})
                        </span>
                      </h2>
                    </div>
                  </div>

                  {payStatus?.status === "APPROVED" ? (
                    <>
                      <div className="flex justify-center">  
                        <div className="bg-[var(--blueWhiteTableThead)] text-[var(--greenPrimary)] w-[50px] h-[50px] rounded-[50%] grid place-items-center">
                          <FaCheck size={25} />
                        </div>
                      </div>

                      <div className="flex flex-col text-center gap-[10px] mt-5">
                        <h2 className="text-[26px] font-[600]">{t("build_package.steps.std.valoration.success_built")}</h2>
                        <p className="text-[16px] font-[500]">
                          {t("build_package.steps.std.valoration.first_description")} <br /> {t("build_package.steps.std.valoration.second_description")}
                        </p>
                      </div>
                    </>
                  ) :
                  (
                    <>
                      <div className="flex justify-center">  
                        <div className="bg-[var(--blueWhiteTableThead)] text-red-500 w-[50px] h-[50px] rounded-[50%] grid place-items-center">
                          <IoClose size={25} />
                        </div>
                      </div>

                      <div className="flex flex-col text-center gap-[10px] mt-5">
                        <h2 className="text-[26px] font-[600]">{t("build_package.steps.std.valoration.error_built")}</h2>
                        <p className="text-[16px] font-[500]">
                          {t("build_package.steps.std.valoration.error_description")}
                        </p>
                      </div>
                    </>
                  )

                  }

                  <div>
                    {payStatus?.status === "APPROVED" && (
                      <p className="mt-10 text-center">
                        <span>
                          <strong>
                            {t("packs.tourism.payment_detail.stored_payment")}
                            <button onClick={handleGoToMyMovements} className="ml-2 underline cursor-pointer text-green-700 hover:text-green-900">{t("packs.tourism.payment_detail.stored_payment_module")}</button>
                          </strong>
                        </span>
                      </p>
                    )}
                  </div>

                  <div className="itemDetailsFlights">
                    <div className="itemDetailsFlights1">
                      <div className="itemDetailsFlightsColumn1">
                        <p className="typeFlight">
                          <strong>{t("packs.tourism.payment_detail.payment_date")}</strong> {formatDate(payStatus?.created_at)}
                        </p>
                      </div>
                    </div>
                    <div className="itemDetailsFlights2">
                      <div className="itemDetailsFlightsColumn1">
                        <p className="typeFlight">
                          <strong>{t("packs.tourism.payment_detail.payment_reference")}</strong> {payStatus?.reference}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div className="methodPayContainInfo">
              <div className="methodPayImageContain">
                <div className="methodPayContainInfoContain">
                  <div
                    className={`contentStatePay ${payStatus?.status === "APPROVED"
                      ? "APPROVED"
                      : payStatus?.status === "PENDING"
                        ? "PENDING"
                        : payStatus?.status === "REJECTED"
                          ? "REJECTED"
                          : ""
                      }`}
                  >
                    {payStatus?.status === "APPROVED" ? (
                      <GoCheckCircleFill size={40} />
                    ) : payStatus?.status === "PENDING" ? (
                      <TbLoader2 size={40} />
                    ) : payStatus?.status === "REJECTED" ? (
                      <IoCloseCircle size={40} />
                    ) : (
                      <BiQuestionMark size={40} />
                    )}
                    <div>
                      <div className="contentStatePayMessage !gap-[8px]">
                        <h3>{t("packs.tourism.payment_detail.status.text")}:</h3>
                        <p>
                          {payStatus?.status === "APPROVED"
                            ? t("packs.tourism.payment_detail.status.approved")
                            : payStatus?.status === "PENDING"
                              ? t("packs.tourism.payment_detail.status.pending")
                              : payStatus?.status === "REJECTED"
                                ? t("packs.tourism.payment_detail.status.rejected")
                                : t("packs.tourism.payment_detail.status.loading")}
                        </p>
                      </div>
                      <p>
                        {payStatus?.status === "APPROVED"
                          ? t("packs.tourism.payment_detail.status.success_payment")
                          : payStatus?.status === "PENDING"
                            ? t("packs.tourism.payment_detail.status.pending_payment")
                            : payStatus?.status === "REJECTED"
                              ? t("packs.tourism.payment_detail.status.rejected_payment")
                              : t("packs.tourism.payment_detail.status.loading")}
                      </p>
                    </div>
                  </div>
                  <div className="methodPayContainInfoContainPadding">
                    <div>
                      <h3>{t("packs.tourism.payment_detail.finished_purchase")}</h3>
                      <p>{t("packs.tourism.payment_detail.total_purchase")}</p>
                    </div>
                    <div className="methodValuesContaint">
                      <div className="methodValuesItem">
                        <h4>{t("fligth.form_passenger.step3.sub_total")}:</h4>
                        <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total}</span>
                      </div>
                      <div className="methodValuesItemTotal">
                        <h4>{t("fligth.form_passenger.step3.total")}:</h4>
                        <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="securityPay">
                  <SvgIcon
                    color={"var(--bluePrimary)"}
                    name={iconList.IconKey}
                    size={40}
                  />
                  <div>
                    <TextComponent
                      children={t("packs.tourism.payment_detail.secure_payment")}
                      size={"md"}
                      fontWeight={"bold"}
                      tag="h4"
                    />
                    <TextComponent
                      children={t("packs.tourism.payment_detail.secure_payment_description")}
                      size={"md2"}
                      fontWeight={"light"}
                      tag="span"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="text-center">
            {payStatus?.status === "APPROVED" && (
              <p>
                {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.approved")})}
              </p>
            )}

            {payStatus?.status === "PENDING" && (
              <p>
                {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.pending")})}
              </p>
            )}

            {payStatus?.status === "REJECTED" && (
              <p>
                {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.rejected")})}
              </p>
            )}
          </div>

        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default StepValuationPayment;