import { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const ModalPaymentCenter = ({ item, handleClose }) => {
  const [details, setDetails] = useState(null);
  const [showInnerModal, setShowInnerModal] = useState(false);
  const [innerModalData, setInnerModalData] = useState(null);

  useEffect(() => {
    if (item) {
      const parsedDetails = {
        flights: item.flights ? JSON.parse(item.flights) : [],
        hotels: item.hotels ? JSON.parse(item.hotels) : [],
        extras: item.extras ? JSON.parse(item.extras) : { other_amounts: [], health_amounts: [] },
      };

      console.log("parsed details: ", parsedDetails)
      setDetails(parsedDetails);
    }
  }, [item]);

  if (!details) return null;

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  const { flights, hotels, extras } = details;

  const openInnerModal = (data) => {
    setInnerModalData(data);
    setShowInnerModal(true);
  };

  const closeInnerModal = () => {
    setShowInnerModal(false);
    setInnerModalData(null);
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-4xl w-full max-h-[80vh] overflow-y-auto p-6 customScroll">
        {/* Header */}
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-lg font-bold text-gray-800">Payment Details</h2>
          <IoIosCloseCircleOutline
            onClick={handleClose}
            className="text-2xl text-gray-600 cursor-pointer hover:text-gray-800"
          />
        </div>

        {/* Content */}
        <div className="space-y-6 mt-4">
          {/* Vuelo */}
          <div>
            <h3 className="text-lg font-semibold text-gray-700">
              Flight - 
              <button
                onClick={() => openInnerModal({ type: "flight", data: flights })}
                className="text-customBlue underline cursor-pointer hover:text-customHoverBlue ml-2"
              >
                View flight details
              </button>
            </h3>
            <table className="w-full border-collapse border border-gray-200">
              <thead>
                <tr>
                  <th className="border border-gray-300 p-2 text-left">Price to pay</th>
                  <th className="border border-gray-300 p-2 text-center">Is included in payment</th>
                </tr>
              </thead>
              <tbody>
                {flights.length > 0 ? (
                  flights.map((flight, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="border border-gray-300 p-2">{formatPriceCOP(flight.price)}</td>
                      <td className="border border-gray-300 p-2 text-center">
                        {flight.is_checked ? (
                          <FaCheckCircle className="text-green-500 text-xl mx-auto" />
                        ) : (
                          <FaTimesCircle className="text-red-500 text-xl mx-auto" />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="text-gray-500 p-2">
                      There is no flight information.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Hotel */}
          <div>
            <h3 className="text-lg font-semibold text-gray-700">Hotel -
            <button
                onClick={() => openInnerModal({ type: "hotel", data: hotels })}
                className="text-customBlue underline cursor-pointer hover:text-customHoverBlue ml-2"
              >
                View hotel details
              </button>
            </h3>
            <table className="w-full border-collapse border border-gray-200">
              <thead>
                <tr>
                  <th className="border border-gray-300 p-2 text-left">Price to pay</th>
                  <th className="border border-gray-300 p-2 text-center">Is included in payment</th>
                </tr>
              </thead>
              <tbody>
                {hotels.length > 0 ? (
                  hotels.map((hotel, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="border border-gray-300 p-2">{formatPriceCOP(hotel.price)}</td>
                      <td className="border border-gray-300 p-2 text-center">
                        {hotel.is_checked ? (
                          <FaCheckCircle className="text-green-500 text-xl mx-auto" />
                        ) : (
                          <FaTimesCircle className="text-red-500 text-xl mx-auto" />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="text-gray-500 p-2">
                      There is no hotel information.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Montos Adicionales */}
          <div>
            <h3 className="text-lg font-semibold text-gray-700">Additional amounts</h3>
            <table className="w-full border-collapse border border-gray-200">
              <thead>
                <tr>
                  <th className="border border-gray-300 p-2 text-left">Price to pay</th>
                  <th className="border border-gray-300 p-2 text-center">Is included in payment</th>
                </tr>
              </thead>
              <tbody>
                {extras.other_amounts.length > 0 ? (
                  extras.other_amounts.map((amount, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="border border-gray-300 p-2">
                        {amount.name}: {formatPriceCOP(amount.value)}
                      </td>
                      <td className="border border-gray-300 p-2 text-center">
                        {amount.is_checked ? (
                          <FaCheckCircle className="text-green-500 text-xl mx-auto" />
                        ) : (
                          <FaTimesCircle className="text-red-500 text-xl mx-auto" />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="text-gray-500 p-2">
                      There are no additional amounts.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Montos de Salud */}
          <div>
            <h3 className="text-lg font-semibold text-gray-700">Health amounts</h3>
            <table className="w-full border-collapse border border-gray-200">
              <thead>
                <tr>
                  <th className="border border-gray-300 p-2 text-left">Price to pay</th>
                  <th className="border border-gray-300 p-2 text-center">Is included in payment</th>
                </tr>
              </thead>
              <tbody>
                {extras.health_amounts.length > 0 ? (
                  extras.health_amounts.map((amount, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="border border-gray-300 p-2">
                        {amount.name}: {formatPriceCOP(amount.value)}
                      </td>
                      <td className="border border-gray-300 p-2 text-center">
                        {amount.is_checked ? (
                          <FaCheckCircle className="text-green-500 text-xl mx-auto" />
                        ) : (
                          <FaTimesCircle className="text-red-500 text-xl mx-auto" />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="text-gray-500 p-2">
                      There are no health amounts.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Monto a Pagar */}
          <div className="flex justify-center gap-4 text-center items-center">
            <div>
              <h3 className="text-lg font-semibold text-gray-700">Total payment amount</h3>
              <p className="text-customBlue text-xl font-bold">{formatPriceCOP(item.amount_pay)}</p>
            </div>

            {/* Monto Total */}
            <div>
              <h3 className="text-lg font-semibold text-gray-700">Total quotation amount</h3>
              <p className="text-customBlue text-xl font-bold">{formatPriceCOP(item.total_pay)}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Inner Modal */}
      {showInnerModal && innerModalData && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-60">
          <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6">
            <div className="flex justify-between items-center pb-4 border-b">
              <h2 className="text-lg font-bold text-gray-800">{innerModalData.type === "flight" ? "Flight Details" : "Hotel Details"}</h2>
              <IoIosCloseCircleOutline
                onClick={closeInnerModal}
                className="text-2xl text-gray-600 cursor-pointer hover:text-gray-800"
              />
            </div>
            <div className="mt-4">
              { innerModalData.data.length > 0 ? (
                  innerModalData.data.map((detail, index) => (
                    <div key={index} className="mb-4 border-b pb-4">
                      {innerModalData.type === "flight" && detail.data && (
                        <div>
                          <p>From: {detail.data.from}</p>
                          <p>To: {detail.data.to}</p>
                          <p>Departure: {detail.data.departure}</p>
                          <p>Arrival: {detail.data.arrival}</p>
                          <p>Price: {formatPriceCOP(detail.data.price)}</p>
                        </div>
                      )}
                      {innerModalData.type === "hotel" && detail.data && (
                        <div>
                          <p>Hotel: {detail.data.hotel}</p>
                          <p>Destination: {detail.data.destination}</p>
                          <p>Arrival: {detail.data.arrival}</p>
                          <p>Departure: {detail.data.departure}</p>
                          <p>Price: {formatPriceCOP(detail.data.price)}</p>
                        </div>
                      )}
                    </div>
                  ))
                )
                :
                (
                  <p>No {innerModalData.type === "flight" ? "flights" : "hotels"} details available.</p>
                )
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalPaymentCenter;
