import React, {useState, useEffect, useRef} from "react";
import styles from "./styles.module.css";
import axios from "axios";
import TooltipContainer from "@/ui/views/share/components/tooltipContainer";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const InputProcedures = ({
    showProcedures,
    setShowProcedures,
    procedure,
    setProcedure,
    checkProcedure,
    setCheckProcedure,
    idSpecialty,
    
}) => {
    const specialtiesRef = useRef(null);
    const inputRef = useRef(null);

    const procedureData = useSelector((state) => state.dataSearchEngine.procedureData)
    const { t } = useTranslation("global");
    const [cardEspecialties, setCardEspecialties] = useState(false);
    const [especialties, setEspecialties] = useState([]);
    const [especialtiesSearch, setEspecialtiesSearch] = useState([]);
    const [firstRender, setFirstRender] = useState(false)
    const [available, setAvailable] = useState(false)

    const notify = (title, icon) =>
    toast(title, {
        icon: icon,
    });

    // Functions
    const handleClick = () => {
        if(idSpecialty){
            setProcedure("");
            setShowProcedures(true);
            setCardEspecialties(true);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    const getEspecialties = () => {
        let response = axios.get(`https://pdtclientsolutions.com/crm-public/api/category/sub/${idSpecialty}`);

        response.then((res) => {
            setEspecialties(res.data);
            setAvailable(true)
        })
        .catch((err) => {
            console.error(err);
        });
    };

    // UseEffects
    useEffect(() => {
        if (idSpecialty === "") {
            setEspecialties([]);
        }
    }, []);

    useEffect(() => {
        setProcedure("")
        setFirstRender(true)
        getEspecialties();
    }, [idSpecialty]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(specialtiesRef.current && !specialtiesRef.current.contains(event.target)){
                setShowProcedures(false);
                setCardEspecialties(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [specialtiesRef, setShowProcedures]);

    useEffect(() => {
        const sortedEspecialties = [...especialties].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        if (procedure === "") {
            setEspecialtiesSearch(sortedEspecialties);
        } else {
            setEspecialtiesSearch(sortedEspecialties?.filter((especialty) => 
                especialty?.name && especialty?.name?.toLowerCase().includes(procedure?.toLowerCase())
            ));
        }
    }, [procedure, especialties]);

    return (
        <div className={styles.containerInputSearch} onClick={handleClick} ref={specialtiesRef}>
            <input 
                type="text" 
                placeholder={ showProcedures ? '' : t("build_package.filters.procedure.placeholder") } 
                ref={inputRef} 
                value={procedure}
                onChange={(e) => {
                    setProcedure(e.target.value)
                    setCheckProcedure(false)
                }}
                className={styles.inputSearchEspecialties}
                style={{
                    borderColor: checkProcedure ? "var(--redPrimary)" : "", 
                    opacity: idSpecialty ? 1 : 0.5,
                    pointerEvents: idSpecialty ? "" : "none"
                }}
            />
            {
                cardEspecialties && (
                    <TooltipContainer
                        show={showProcedures}
                        hidden={setShowProcedures}
                        sizeWidth={100}
                        sizeHeight={80}
                    >
                        <ul className="contentElemetsSearchInputBuildPackage">
                            {
                                especialtiesSearch.length === 0 ? (
                                    <li className="list-not-results">
                                        {t("build_package.filters.without_results")}
                                    </li>
                                ) : (
                                    especialtiesSearch.map((especialtieItem, index) => (
                                        <Tooltip title={especialtieItem.name} arrow placement="right">
                                            <li 
                                                key={index} 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setProcedure(especialtieItem.name);
                                                    setShowProcedures(false);
                                                    setCardEspecialties(false);
                                                    setCheckProcedure(false);
                                                }}
                                            >
                                                <span>{especialtieItem.name}</span>
                                            </li>
                                        </Tooltip>
                                    ))
                                )
                            }
                        </ul>
                    </TooltipContainer>
                )
            }
        </div>
    );
}

export default InputProcedures;