import React from 'react'
import Packages from '../../ui/views/MedicalPackages/Packages'

const MedicalPackages = () => {
  return (
    <div>
        <Packages/>
    </div>
  )
}

export default MedicalPackages