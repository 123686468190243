import React from "react";
import "./styles.css";
import {
  FaRegEye,
} from "react-icons/fa";
import { Grid } from "@mui/material";
import { LiaToothSolid } from "react-icons/lia";
import { MdOutlineFace4 } from "react-icons/md";
import { GiBoxCutter, GiBrokenBone, GiFemaleLegs, GiFetus, GiKidneys } from "react-icons/gi";
import { FaBacteria } from "react-icons/fa6";
import { IoWomanOutline } from "react-icons/io5";
import { SiPicardsurgeles } from "react-icons/si";
import { useTranslation } from "react-i18next";

const Specialities = () => {
  const { t } = useTranslation("global");
  const specialties = [
    { name: t("packs.health.specialties.types.cosmetic_surgery"), icon: <GiBoxCutter /> },
    { name: t("packs.health.specialties.types.dentistry"), icon: <LiaToothSolid /> },
    { name: t("packs.health.specialties.types.anti_aging"), icon: <MdOutlineFace4 /> },
    { name: t("packs.health.specialties.types.fertility"), icon: <GiFetus /> },
    { name: t("packs.health.specialties.types.stem_cells"), icon: <SiPicardsurgeles /> },
    { name: t("packs.health.specialties.types.eye_care"), icon: <FaRegEye /> },
    { name: t("packs.health.specialties.types.bariatric_surgery"), icon: <IoWomanOutline /> },
    { name: t("packs.health.specialties.types.orthopedics"), icon: <GiBrokenBone /> },
    { name: t("packs.health.specialties.types.gynecology"), icon: <GiFemaleLegs /> },
    { name: t("packs.health.specialties.types.urology"), icon: <GiKidneys /> },
    { name: t("packs.health.specialties.types.oncology"), icon: <FaBacteria /> },
  ];

  return (
    <div className="content-specialties-turism-health">
      <h2>{t("packs.health.specialties.our")}</h2>
      <div>
        <Grid container mb={-2} mt={'5px'}>
          <Grid item xs={12}>
            <Grid container>
              {specialties.map((specialist, index) => (
                <Grid item xs={12} md={6} lg={3} key={index} className="card-element">
                  <div>
                    <span>
                      {specialist.icon}
                    </span>
                    <p>{specialist.name}</p>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>

    </div>
  );
};

export default Specialities;
