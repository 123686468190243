import React, { useEffect, useState } from 'react'
import CarouselSevices from '../../share/components/CarouselServices/CarouselSevices'
import packageAside from "../../../assets/images/packages1.jpg";
import { useTranslation } from 'react-i18next';
import packImg from "./pack.png"
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CardPackages from '../../home/components/PackagesSection/CardPackages';
import SliderOferts from '../../home/components/SliderOferts/SliderOferts';
import "./styles.css"
import Recomendation from './Recomendation/Recomendation';
import Loading from '../../share/components/loading';
import { CircularProgress, Grid, Pagination } from '@mui/material'
import paperFligth from "./paperFlight.svg"
import AnyQuestions from "../../home/components/anyQuestion/index"
import ReturnPage from '../../share/components/return/ReturnPage';
import banner5 from "../../../assets/pictures/BannerNuevo5.jpg";

const TypePackages = () => {
    const { t } = useTranslation("global");
    const urlParams = new URLSearchParams(window.location.search)
    const type = urlParams.get('type');
    let navigate = useNavigate()
    const [packs, setPacks] = useState([])
    const [packsRecomendation, setPacksRecomendation] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [width, setWidth] = useState("32%")
    //Variables de paginación

    let counter = 0;
    let hasUpdated = false;
    const [itemsPerPage, setItemsPerPage] = useState(6);

    // Variables de paginación
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = packs.slice(indexOfFirstItem, indexOfLastItem);


    //Función para cambiar de página
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const redirectTo = () => {
        navigate(type === "colombia" ? "/packagesType?type=internacional" : "/packagesType?type=colombia")
    }

    const updatedPagination = (item) => {
        setItemsPerPage(item);
    };
    const getPacks = () => {
        setLoading(true)
        const url = `${process.env.REACT_APP_URL_API_AIOP}api/packages`
        axios.get(url, {
            params: {
                "destination_type": type === "colombia" ? "national" : "international"
            }
        })
            .then(response => {
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
                setPacks(response.data.packs)
            })
            .catch(err => {
                console.log(err)
            })
    }


    const getPacksRecomendation = () => {
        const url = `${process.env.REACT_APP_URL_API_AIOP}api/packages`
        axios.get(url)
            .then(response => {
                setPacksRecomendation(response.data.packs)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getPacks()
        setCurrentPage(1)
    }, [type])

    useEffect(() => {
        getPacksRecomendation()
    }, [])


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1270) {
                updatedPagination(6);
                setWidth("32%")
                setCurrentPage(currentPage);
            } else if (window.innerWidth <= 1270 && window.innerWidth > 600 && !hasUpdated) {
                updatedPagination(4);
                setWidth("48%")
                setCurrentPage(currentPage); // Reinicia la página actual al actualizar la paginación
                // Marca que la actualización se ha realizado
            } else if (window.innerWidth <= 600 && !hasUpdated) {
                setWidth("100%")
                updatedPagination(2);
                setCurrentPage(currentPage); // Reinicia la página actual al actualizar la paginación
            }
        };

        window.addEventListener("resize", handleResize);

        // Ejecutar una vez al cargar la página si la condición ya se cumple
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <>
            <CarouselSevices current={t("carousel_services.packages")} />
            <ReturnPage />
            {
                loading &&
                <Loading />
            }

            <div className="content-packages-new">
                <div className="content-packages-colum-1">
                    <div className="content-packages-new-change" style={{ height: "20%" }}>
                        <div className="content-packages-header">
                            <h1 style={{ fontSize: "24px", marginTop: "0.4rem" }}>{t("packs.tourism.anything_else")}</h1>
                            <p>
                                {t("packs.tourism.different_options")} <br /> {t("packs.tourism.travel_any_destination")}
                            </p>
                                
                        </div>
                        <article>
                            <section className="content-btn-pack" style={{ marginTop: "1rem" }} onClick={redirectTo}>
                                <button>
                                    <div>
                                        <span style={{ textTransform: "uppercase" }} >{type === "colombia" ? t("packs.tourism.international_text") : t("packs.tourism.colombia_text")}</span>
                                    </div>
                                </button>
                            </section>
                        </article>
                    </div>
                    <aside style={{ cursor: 'pointer' }}>
                        <img src={banner5} className="img-pack-aside" alt="paquetes" />
                        <div className="info">
                            <div className="letter"></div>
                            <div
                                className="cardRegisterNowButtonContain"
                                style={{
                                    position: "relative",
                                    bottom: "0px",
                                }}
                            >
                                <Link
                                    to={"/TourismHealth"}
                                    style={{
                                        backgroundColor: "#00AFE8",
                                        fontSize: "24px",
                                        fontWeight: "900",
                                        borderRadius: "0px",
                                        width: "100%",
                                    }}
                                    className="cardRegisterNowButton"
                                >
                                    {t("common.see_more.uppercase")}
                                </Link>
                            </div>
                        </div>
                    </aside>
                </div>

                <div className="content-packages-colum-2">
                    <div className="content-packages-new-change" style={{ height: "10%" }}>
                        <div className="content-packages-header">
                            <h1>{type === "colombia" ? t("packs.tourism.colombia_uppercase") :  t("packs.tourism.international_uppercase")}</h1>
                            <p style={{ color: "black" }}>
                                {type === "colombia" ? t("packs.tourism.recommended.description") : t("packs.tourism.travel_wellezy")}
                            </p>
                        </div>
                    </div>
                    <div className='layout-type-packages-result' style={{ height: "90%", position: "relative" }}>
                        <div className="content-packs-new-2" style={{ height: "100%"}}>
                            {

                                packs.length > 0 ?
                                    (
                                        currentItems.map((item, index) => {
                                            return (
                                                <CardPackages
                                                    key={index}
                                                    pack={item}
                                                    imgType={false}
                                                    width={width}
                                                />
                                            )
                                        })

                                    )
                                    :
                                    <h3>{t("packs.tourism.without_at_moment")}</h3>
                            }
                        </div>
                        {
                            packs?.length === 0 ? (
                                null
                            ) :
                                packs.length > itemsPerPage ?
                                    (
                                        <Pagination className='pagination-packages' count={Math.ceil(packs.length / itemsPerPage)} page={currentPage} onChange={handlePageChange} style={{
                                            position: "absolute",
                                            bottom: "-135px", left: "37%"
                                        }} />
                                    ) : ""
                        }
                    </div>
                </div>
            </div>


            <div className='carousel-type-pack'>
                <div className='content-width'>
                    <div className='letters-carrousel-type'>
                        <h2>
                            <span style={{ fontSize: "24px" }}>{t("packs.tourism.fly_with")}</span>
                            <span style={{ fontSize: "32px" }}>{t("packs.tourism.best")}</span>
                            {t("packs.tourism.prices")}
                        </h2>
                        <img src={paperFligth} alt="icon" />
                    </div>
                    <SliderOferts colorButton={"#004274"} setLoadingImage={setLoadingImage} loadingImage={loadingImage} />
                </div>
            </div>
            <Recomendation article={packsRecomendation} />
            <AnyQuestions title={t("browse_home.have_doubt")} />
        </>
    )
}

export default TypePackages