import "./styles.css";
import DoubleTitle from "../components/doubleTitle";
import BarSearch from "../components/barSearch";
import JoinUs from "../components/jounUs";
import OptionsTabs from "../components/optionsTabs";
import ListInstructionvideos from "../components/listInstructionvideos";
import FrequentQuestions from "../components/frequentQuestions";
import FormContactUs from "../components/formContactUs";
import FooterButton from "../components/footerButton";
import AnyQuestions from "../../home/components/anyQuestion";
import usePqrsPrimaryScreen from "../../../viewModels/pqrs/pqrs.primaryScreen.viewModel";
import NeedInformation from "../../tourismHealth/needInformation/NeedInformation";
import question from "./question.svg";
import earphones from "./earphones.svg";
import CarouselSevices from "../../share/components/CarouselServices/CarouselSevices";
import { useTranslation } from "react-i18next";
import ContactWellezy from "@/Components/ContactWellezy/ContactWellezy";

function PqrsPrimaryScreen() {
  const {t} = useTranslation("global");
  const {
    activatedTab,
    setActivatedTab,
    dataPqrs,
    isEstablishedSession,
    dataSelected,
  } = usePqrsPrimaryScreen();

  const btns = {
    need: {
      title: t('home.need_information.title'),
      textButton: t('home.need_information.button_text'),
      description: t('home.need_information.description'),
      img: question,
    },
  };

  return (
    <>
      <CarouselSevices current={t("carousel_services.packages")} />
      <div className="pqrsPrimaryScreen">
        <DoubleTitle simpleText="" bigText={t("pqrs.title")} />
        {activatedTab && (
          <OptionsTabs
            tabActived={activatedTab}
            tabList={dataPqrs}
            tabChoose={setActivatedTab}
          />
        )}

        <div
          className="layout-need-information-tourism-health"
          style={{ margin: "1rem auto", width: "100%", marginBottom: 50 }}
        >
          <NeedInformation
            description={btns.need.description}
            img={btns.need.img}
            title={btns.need.title}
            textButton={btns.need.textButton}
            color={"#104172"}
            active={true}
            url={"/contact"}
          />
        </div>

        {activatedTab && (
          <div className="pqrsPrimaryScreenRow">
            <FrequentQuestions data={dataSelected?.faq} />
            <FormContactUs />
          </div>
        )}
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </>
  );
}

export default PqrsPrimaryScreen;
