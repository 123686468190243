import React from 'react'
import SvgIcon, { iconList } from '../../../share/components/iconSVG'
import "./styles.css"
import { useTranslation } from "react-i18next";

const DetailBuy = ({ item }) => {
    const { t } = useTranslation("global");


    return (
        <div className='content-detail-buy-hotel'>
            <h2>{t("fligth.form_passenger.step.det_of_purchase")} </h2>
            <h3>{item?.vehicle}</h3>
            <p style={{ fontSize: "13px" }}>{t("transfers.prepared_covid")}: {item?.covidPrepared ? t("specialist.booking_flow.yes") : t("specialist.booking_flow.no")}</p>
            {/* <p style={{ display: "flex", alignItems: "center" ,fontSize:"14px"}}><SvgIcon color={"#004274"} name={iconList.IconPinMap} size={25} /> Ver mapa</p> */}
            <div className='content-check-group'>
                <section className='check-in-detail'>
                    <span>{t("transfers.capacity")} </span>
                    <span>{item?.passengerCapacity}</span>
                </section>
                <section className='check-in-detail'>
                    <span>{t("transfers.duration")} </span>
                    <span>{item?.duracionInMinutes} Min</span>
                </section>
            </div>


        </div>
    )
}

export default DetailBuy