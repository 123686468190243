import { useState, useEffect } from "react";

import CarouselSevices from "../../ui/views/share/components/CarouselServices/CarouselSevices";
import style from "./Assets/style.module.css";

import AnyQuestions from "../../ui/views/home/components/anyQuestion";
import { useTranslation } from "react-i18next";
import { get, keys } from "../../ui/localStorage";
import AllCardTour from "../../ui/views/tours/CardTour/AllCardTour"
import Imagen from "./Assets/images/imagen.jpg";
import CustomSlider from "@/ui/views/Packages/DetailPackages/sliderImg/CustomSlider";
import banner from "./Assets/images/imagen2.png";
import { CiSearch } from "react-icons/ci";
import useTour from "../../ui/viewModels/tours/useTour";
import Paginado from "@/ui/views/tours/Paginado/Paginado";




function Tours() {
    const { t } = useTranslation("global");

    const {
        dataAlways,
        loading,
        nextHandler,
        prevHandler,
        services,
        urlImg,
        detailTour,
        setCurrentPage,
        findTour,
        setCity,
        setName,
        city,
        name,

    } = useTour();

    const photos = [
        {
            id: 1,
            img: banner,
        },
        {
            id: 2,
            img: banner,
        },
        {
            id: 3,
            img: banner,
        },
    ];

    return (
        <>
            <CarouselSevices current={t("carousel_services.tours")} />
            <div className={style.main_tours}>
                <div className={style.attraction_tour}>
                    <div className="flex flex-col absolute gap-1 top-[20px] text-white z-[99] justify-center align-middle items-center text-center">
                        <h2 className="text-[24px] font-bold">{t("tourism.text")}</h2>
                        <p className="px-4 py-2">{t("tourism.description")}</p>
                    </div>
                    <img src={Imagen} alt="Imagen" />
                    {/* <button className={style.attraction_tour_button}>Ver más</button> */}
                </div>
                <div className={style.carrousel_search}>
                    <CustomSlider>
                        {photos.map((image, index) => {
                            return (
                                <img
                                    className={style.carrousel_search_image}
                                    src={image.img}
                                    key={index}
                                    alt={`${index + 1}`}
                                />
                            );
                        })}
                    </CustomSlider>

                    <div className={style.main_tours_form}>
                        <div className={style.main_tours_form_header}>
                            <h2>{t("tourism.text")}</h2>
                            <p>{t("tourism.description")}</p>
                        </div>

                        <div className={style.main_tours_inputs}>
                            <input
                                type="text"
                                placeholder={t("tourism.specific_destination")}
                            />
                            <select name="tour" id="tour-select">
                                <option value="a">{t("tourism.types.city")}</option>
                                <option value="b">{t("tourism.types.mountain")}</option>
                                <option value="c">{t("tourism.types.river")}</option>
                                <option value="d">{t("tourism.types.beach")}</option>
                                <option value="e">{t("tourism.types.museum")}</option>
                                <option value="f">{t("tourism.types.desert")}</option>
                                <option value="g">{t("tourism.types.vineyard")}</option>
                            </select>

                            <button className={style.main_tours_button_search}>
                                <CiSearch size={25} /> {t("tourism.search")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.results}>
                <AllCardTour
                    data={dataAlways}
                    urlImg={urlImg}
                    loading={loading}
                    detailTour={detailTour}
                />
            </div>
            {(dataAlways.length > 2 || services?.current_page > 1) && (
                <Paginado
                    currentPage={services?.current_page}
                    totalPages={services?.last_page}
                    nextHandler={nextHandler}
                    prevHandler={prevHandler}
                    pageRangeDisplayed={5}
                    setCurrentPage={setCurrentPage}
                />
            )}

            <div className={style.tours_main}>
                <AnyQuestions title={t("browse_home.have_doubt")} />
            </div>
        </>
    );
}

export default Tours;
