import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import TextComponent from "../../../../share/components/fonts/TextComponent";
import { MdClose } from "react-icons/md";
import { Slider } from "@mui/material";

const FilterAsideHotels = ({
    priceMin,
    priceMax,
    widthContain,
    position,
    setFirstRange,
    setSecondRange,
    activeFilterPrice,
    setActiveFilterPrice,
    errorFilter,
    setResetResult,
    resetResult,
    filterHotelModal,
    setFilterHotelModal,
    setSelectedStars: setParentSelectedStars,
    setFilterName,
}) => {
    const [t] = useTranslation("global");
    const [selectedStars, setSelectedStars] = useState(0);
    const [range, setRange] = useState([priceMin, priceMax]);

    const [rangePriceMin, setRangePriceMin] = useState(priceMin);
    const [rangePriceMax, setRangePriceMax] = useState(priceMax);
    const [filterNameTemp, setFilterNameTemp] = useState('');
    let hotelDefault = false;



    useEffect(() => {
        // Actualiza el rango y los valores mínimos y máximos del slider
        setRange([priceMin, priceMax]);
        setRangePriceMin(priceMin);
        setRangePriceMax(priceMax);
    }, [priceMin, priceMax]);

    // Format price values for the slider marks
    const formatPrice = (value) => {
        return `$${value.toLocaleString()}`;
    };



    // Handle slider change while dragging
    const handleSliderChange = (event, newValue) => {
        setRange(newValue);
        setRangePriceMin(newValue[0])
        setRangePriceMax(newValue[1])
    };

    // Handle slider change after releasing
    const handleSliderChangeCommitted = (event, newValue) => {
        setRange(newValue);
        setFirstRange(newValue[0])
        setSecondRange(newValue[1])
        setActiveFilterPrice(!activeFilterPrice);
    };

    // Reset filters
    const handleReset = () => {
        setRange([priceMin, priceMax]);
        setFirstRange(priceMin);
        setSecondRange(priceMax);
        setSelectedStars(0);
        setParentSelectedStars(0);
        setActiveFilterPrice(!activeFilterPrice);
        setResetResult(!resetResult);
        setFilterName('');
        setFilterNameTemp('');
    };

    const handleStarClick = (starCount) => {
        const newStarCount = selectedStars === starCount ? 0 : starCount;
        setSelectedStars(newStarCount);
        setParentSelectedStars(newStarCount);
    };

    useEffect(() => {
        console.log("Filtros aplicados con estrellas: ", localStorage.getItem("defaultHotel"));
        hotelDefault = localStorage.getItem("defaultHotel");
        if (hotelDefault === "true") {
            setSelectedStars(5);
            setParentSelectedStars(5);
            hotelDefault = false;
            localStorage.setItem("defaultHotel", false);
        } else {
            setSelectedStars(0);
            setParentSelectedStars(0);
        }
    }, []);


    // Define marks for the slider

    const marks = [
        {
            value: rangePriceMin,
            label: formatPrice(rangePriceMin),
        },
        {
            value: rangePriceMax,
            label: formatPrice(rangePriceMax),
        },
    ];

    return (
        <div
            className={`${filterHotelModal
                ? "container-filter-price"
                : "container-filter-price close"
                }`}
        >
            <button
                className="ModalChangeCloseHotelButton"
                onClick={() => setFilterHotelModal(false)}
            >
                <MdClose size={23} color="#104172" />
            </button>
            <TextComponent
                className={"filter-hotels"}
                tag="h2"
                children={t("hotel.filters")}
                fontWeight="bold"
                size={"lg"}
            />

            <div className="pt-0 pb-0 flex flex-col gap-4 ">
                <div className="text-[18px] font-bold text-[#104172]">
                    <TextComponent
                        tag={"h3"}
                        children={t("hotels.hotel_filters.hotel_name")}
                        size={"md"}
                        fontWeight="bold"
                    />
                </div>
                <input
                    type="text"
                    className="h-[46px] p-2 border border-[#d9d9d9] rounded-lg w-[100%] text-[12px] flex items-center justify-between gap-2"
                    placeholder={t("hotels.hotel_filters.filter_by_name")}
                    value={filterNameTemp}
                    onChange={(event) => {
                        setFilterName(event.target.value)
                        setFilterNameTemp(event.target.value)
                    }}
                />
            </div>

            <div className="pt-0 pb-0 flex flex-col gap-4 pt-3 border-t border-[#dedede]">
                <div className="text-[18px] font-bold text-[#104172]">
                    <TextComponent
                        tag={"h3"}
                        children={t("hotels.hotel_filters.price")}
                        size={"md"}
                        fontWeight="bold"
                    />
                </div>

                <div className="rangeRadioFilter">
                    <TextComponent
                        tag={"p"}
                        children={t("hotels.hotel_filters.range_price")}
                        size={"normal"}
                        fontWeight="light"
                        color="var(--bluePrimary)"
                    />

                    {rangePriceMin > 0 && rangePriceMax > 0 ?
                        <Slider
                            value={range}
                            onChange={(handleSliderChange)}
                            onChangeCommitted={handleSliderChangeCommitted}
                            valueLabelDisplay="auto"
                            valueLabelFormat={formatPrice}
                            max={priceMax}
                            min={priceMin}
                            marks={marks}
                            sx={{
                                '& .MuiSlider-markLabel': {
                                    fontSize: '10px',
                                    fontWeight: "600",
                                    color: 'var(--bluePrimary)'
                                },
                                color: 'var(--bluePrimary)',
                                '& .MuiSlider-thumb': {
                                    backgroundColor: 'var(--bluePrimary)',
                                    width: "18px",
                                    height: "18px",
                                },
                                '& .MuiSlider-markLabel[data-index="0"]': {
                                    left: '15% !important',
                                },
                                '& .MuiSlider-markLabel[data-index="1"]': {
                                    left: '85% !important',
                                },
                                '& .MuiSlider-rail': {
                                    color: '#bdbdbd',
                                },
                                '& .MuiSlider-track': {
                                    color: 'var(--bluePrimary)',
                                },
                            }}
                        />
                        : null}
                </div>
            </div>

            <div className="input-filtrado-price pt-3 border-t border-[#dedede]">
                <TextComponent
                    tag="h3"
                    children={t("hotel.stars")}
                    fontWeight="bold"
                    size={"md"}
                />
                <div className="stars-filter">
                    {[1, 2, 3, 4, 5].map((star) => (
                        <button
                            key={star}
                            style={{
                                cursor: "pointer",
                                background: "none",
                                border: "none",
                            }}
                            onClick={() => handleStarClick(star)}
                        >
                            <SvgIcon
                                name={iconList.IconStar}
                                color={star <= selectedStars ? "#FFD902" : "#E0E0E0"}
                                size={20}
                            />
                        </button>
                    ))}
                </div>
            </div>

            <button className="buttonCleanFilters" onClick={handleReset}>
            {t("hotels.hotel_filters.clean_filters")}
          </button>

            {/* <div className="aplicar-filtro">
                <button
                    onClick={() => {
                    setActiveFilterPrice(!activeFilterPrice);
                    // Aquí deberías agregar la lógica para aplicar el filtro de estrellas
                    console.log("Filtros aplicados con estrellas: ", selectedStars);
                    }}
                >
                    {t("hotel.apply")}
                </button>
                <button
                    onClick={() => {
                    setResetResult(!resetResult);
                    }}
                >
                    {t("hotel.to_reset")}
                </button>
                </div> */}
        </div>

    );
};

export default FilterAsideHotels;