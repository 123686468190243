import React from "react";
import Data from "./components/Data";
import styles from "./styles.module.css";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DataSearch = ({
    setSpetVariableData
}) => {
    const { t } = useTranslation("global");
    const lastSearch = useSelector((state) => state.dataStepz.lastSearch);

    const origin = lastSearch?.originSelectedFlightData?.split(" - ")[0];
    const destination = lastSearch?.destinySelectedFlightData?.split(" - ")[0];

    const startDate = new Date(lastSearch?.dateGoingSelectedData);
    const endDate = new Date(lastSearch?.dateReturnSelectedData);

    const differenceInTime = endDate - startDate;

    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    
    
    
    return (
        <div className={styles.container}>
            <Data title={t("build_package.steps.procedure")} data={lastSearch?.procedureData?.name} border={true} />
            <Data title={t("build_package.steps.start_date")} data={lastSearch?.dateGoingSelectedData} border={true} />
            {lastSearch?.isRoundTripData && (
                    <Data title={t("build_package.steps.return_date")} data={lastSearch?.dateReturnSelectedData} border={true} />
                )    
            }
            {setSpetVariableData !== 1 && (lastSearch?.dateGoingSelectedData && lastSearch?.dateReturnSelectedData) && (
                <Data title={t("build_package.steps.days")} data={differenceInDays} border={true} />
            )}

            {lastSearch?.activeIndex != 0 ? (
                <>
                    <Data title={t("build_package.steps.origin")} data={origin} border={true} />
                    <Data title={t("build_package.steps.destination")} data={lastSearch?.cityHotelData} />
                </>
            ) : (
                    <Data title={t("build_package.steps.destination")} data={lastSearch?.cityHotelData} />
                )}
        </div>
    );
};

export default DataSearch;