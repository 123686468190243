import React, { useEffect } from 'react'
import './styles.css'
import Find from '../share/components/Find/Find'
import CarouselSevices from '../share/components/CarouselServices/CarouselSevices'
import AnyQuestions from '../home/components/anyQuestion'
import { useTranslation } from "react-i18next"
import BannerInsurance from './BannerInsurance/BannerInsurance'
import ButtonSecure from './buttonSecure/ButtonSecure'
import SvgIcon, { iconList } from '../share/components/iconSVG'
import { Link } from 'react-router-dom'
import question from "./question.svg";
import earphones from "./earphones.svg";
import NeedInformation from '../tourismHealth/needInformation/NeedInformation'
import LastSearch from '../share/components/lastSearch/LastSearch'
import ReturnPage from '../share/components/return/ReturnPage'

const TravelSecure = () => {

  const [t] = useTranslation("global")

  const btns = {
    need: {
      title: t("browse_home.need_help.title"),
      textButton: t("browse_home.need_help.button"),
      description: t("browse_home.need_help.description"),
      img: question,
    },
    agent: {
      title: "Mi Agente Wellezy, ¡compra con atención personalizada!",
      textButton: "Conocer canales",
      description:
        "Tenemos varios canales de atención para que planifiques tu viaje con la asesoría de nuestros agentes expertos.",
      img: earphones,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className='contain-travel-secures'>
      <CarouselSevices current={t("carousel_services.travel_secure")} />
      <div className='layout-secure'>
        {/* <BannerInsurance /> */}
        <ButtonSecure />
      </div>

      <LastSearch
        description={t("hotels.last_search.description")}
        title={t("hotels.last_search.title")}
      />

      <div className='contact-insurance'>
        <NeedInformation
          description={btns.need.description}
          img={btns.need.img}
          title={btns.need.title}
          textButton={btns.need.textButton}
          color={"#104172"}
          active={true}
          url={"/contact"}
        />
      </div>

      <AnyQuestions title={t('browse_home.have_doubt')} />
    </div>
  )
}

export default TravelSecure