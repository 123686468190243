import React, { useRef, useState } from "react";
import bannerBottomSpanish from "../../../../assets/svg/BannerBottom.jpg";
import bannerBottomEnglish from "../../../../assets/svg/BannerBottomEng.jpg";

import Loading from "../../../share/components/loading/index";
import "./styles.css";

import { useTranslation } from "react-i18next";
import CardPackages from "./CardPackages";
import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";
import { useNavigate } from "react-router-dom";

const PackageSection = ({
  tour,
  inputValue,
  selectValue,
  seker,
  loading,
  dataPackage,
}) => {
  if (!inputValue) {
    inputValue = "";
  }

  //Variables de estado

  const [inputValueSection, setInputValueSection] = useState(inputValue);
  const [selectValueSection, setSelectValueSection] = useState(selectValue);

  //Variables de paginación
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataPackage.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil((dataPackage || []).length / itemsPerPage);

  //Traducción
  const { t, i18n } = useTranslation("global");

  const nextHandler = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
    window.location.href = "#pack"
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
    window.location.href = "#pack"
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/TourismHealth');
  };

  return (
    <div id="pack" className="packages-section" style={{ width: "90%" }}>
      <h2 id="title" className="title-packages-section">
        {tour ? "Tours" : t("packages.packages_home.packages")}
      </h2>
      <p className="p-pack">{t("packages.packages_home.best_price")}</p>
      <div>
        <div>
          <div>
            {seker ? (
              <div>
                <div>
                  <label className="label-filter-package" htmlFor="">
                    {t("packs.tourism.packages_section.place")}
                  </label>
                  <input
                    className="input-filter-packages"
                    type="text"
                    onChange={(event) =>
                      setInputValueSection(event.target.value)
                    }
                  />
                </div>
                <div>
                  <label className="label-filter-package" htmlFor="">
                    {t("packs.tourism.packages_section.destination_type")}
                  </label>
                  <select
                    className="select-filter-packages"
                    name=""
                    id=""
                    onChange={(event) =>
                      setSelectValueSection(event.target.value)
                    }
                  >
                    <option value="">{t("packs.tourism.types.all")}</option>
                    <option value="international">{t("packs.tourism.types.international")}</option>
                    <option value="national">{t("packs.tourism.types.national")}</option>
                    <option value="regional">{t("packs.tourism.types.regional")}</option>
                  </select>
                </div>
              </div>
            ) : null}
          </div>
          <div className="layout-packages">
            {loading ? (
              <Loading />
            ) : currentItems && currentItems.length === 0 ? (
              <h1>{t("packs.tourism.without_coincidence")}</h1>
            ) : currentItems && currentItems.length > 0 ? (
              <div className="content-packs-new" style={{ marginTop: "10px" }}>
                {currentItems.map((pack, index) => (
                  <CardPackages key={index} pack={pack} imgType={false} />
                ))}
              </div>
            ) : (
              <h1>{t("packs.tourism.without_coincidence")}</h1>
            )}
            {currentItems &&
              currentItems.length === 0 ? null : dataPackage.length > 8 ? (
                <>
                  <Paginado
                    currentPage={currentPage}
                    data={dataPackage}
                    nextHandler={nextHandler}
                    prevHandler={prevHandler}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    pageRangeDisplayed={5}
                  />
                </>

              ) : (
              ""
            )}
          </div>
          <div className="banner-bottom-package" onClick={handleClick} style={{ cursor: 'pointer' }}>
            <img src={i18n.language === "es" ? bannerBottomSpanish : bannerBottomEnglish} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageSection;
