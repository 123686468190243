import useUserProfile from "../../viewModels/profile/user.profile.viewModel";
import SvgIcon, { iconList } from "../share/components/iconSVG";
import "./styles.css";
import AnyQuestions from "../home/components/anyQuestion";
import CommercialProfile from "./components/sections/commercialProfile";
import MyTravels from "./components/sections/myTravels";
import AppointmentRequests from "./components/sections/appointmentRequests";
import TeleConsultation from "./components/sections/appointmentRequests/teleConsultation";
import MyMovements from "./components/sections/myMovements";

import MyItinerary from "./components/sections/myItinerary";
import { RiLogoutCircleLine } from "react-icons/ri";
import { IoCheckmark, IoClose, IoInformation, IoWarning } from "react-icons/io5";
import MyNotifications from "./components/sections/myNotifications";
import MyCart from "./components/sections/Cart";
import { MdEdit } from "react-icons/md";
import ModalContainer from "../share/components/modalContainer";
import { useEffect, useState } from "react";
import UserUpdate from "./components/Update/UserUpdate";
import useDynamicRefetch from "@/common/hooks/useDynamicRefetch";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";


const FeatureUnderDevelopment = () => {
  return (
    <div className="functionalityUnderDevelopment">
      <SvgIcon
        name={iconList.IconAlertOutLine}
        size={60}
        color={"var(--bluePrimary)"}
      />
      <p className="functionalityUnderDevelopmentText">
        Funcionalidad en desarrollo
      </p>
    </div>
  );
};

function ProfileUser() {
  const {
    userDetail,
    optionsList,
    optionActive,
    setOptionActive,
    closeSession,
    codeTeleconsutation,
    goToMyNotifications,
    codeMyNotifications,
    getUserNotifications,
    goToTeleConsultation
  } = useUserProfile();

  const location = useLocation();
  const [t, i18n] = useTranslation("global");
  const [show, setShow] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [filterBy, setFilterBy] = useState('')

  const handleGetUserNotifications = async () => {
    try {
      const notifications = await getUserNotifications();
      setNotifications(notifications);
    } catch (error) {
      console.log(error);
    }
  }

  const manageNotificationTitle = (notificationTitle) => {
    switch (notificationTitle) {
      case "Bienvenido/a":
        return t("profile.modules.my_notifications.titles.welcome");
      case "Reserva completada":
        return t("profile.modules.my_notifications.titles.completed_reserve");
      case "Pago realizado":
        return t("profile.modules.my_notifications.titles.payment_done");
      default:
        return notificationTitle
    }
  }

  useDynamicRefetch(handleGetUserNotifications, { interval: 20000 })

  useEffect(() => {
    handleGetUserNotifications()
  }, [])

  useEffect(() => {
    if (location.state?.codeMyNotifications) {
      goToMyNotifications("notifications")
    }

    if (location.state?.filterBy) {
      setFilterBy(location.state?.filterBy)
    }
  }, [location.state]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const option = params.get('option');
    if (option === '3') {
      setOptionActive(3);
    }

    if (option === '4') {
      setOptionActive(4);
    }
  }, [location, setOptionActive]);

  return (
    <div className="profileScreen">
      <div className="profileScreenContent">
        <div className="profileScreen_Header">
          <h1>{t("profile.title")}</h1>
        </div>
        <div className="profileScreen_Container">
          <div className="profileScreen_ContainerSideLeft">
            <div className="profileScreen_ColumnContainer">
              {optionsList.length > 0 &&
                optionsList.map((index, key) => {
                  const isActive = optionActive === index.value;
                  return (
                    <div
                      key={key}
                      onClick={() => setOptionActive(index?.value)}
                      className={`optionProfile ${isActive ? "optionProfileActive" : ""
                        }`}
                    >
                      <div
                        className={`${isActive
                          ? "optionProfileImageContainer active"
                          : "optionProfileImageContainer"
                          }`}
                      >
                        <SvgIcon
                          name={index?.icon}
                          size={30}
                          color={isActive ? "var(--white)" : "var(--blackGray)"}
                        />
                      </div>

                      <p
                        className={`${isActive
                          ? "optionProfileText active"
                          : "optionProfileText"
                          }`}
                      >
                        {index?.text}
                      </p>
                    </div>
                  );
                })}
            </div>
            <div className="logOutButtonProfile">
              <div className="optionProfile" onClick={closeSession}>
                <div className="optionProfileImageContainer">
                  <RiLogoutCircleLine size={30} color="var(--blackGray)" />
                </div>

                <p className="optionProfileText">{t("profile.log_out")}</p>
              </div>
            </div>
          </div>
          {optionActive === 1 && (
            <div className="bigContainer">
              <div className="containerProfileNotifications">
                <div className="profileScreen_CardShadow profileCard">
                  <div className="profileCardHeader">
                    <div className="profileCardHeaderAvatar">
                      <div className="profileCardHeaderAvatarWrapper">
                        <img
                          src={userDetail?.profile_photo_url}
                          alt="profile-avatar"
                          className="profileCardHeaderAvatarWrapperImg"
                        />
                      </div>

                      <button onClick={() => setShow(true)} className="profileCardHeaderInfoTextSmall">
                        <MdEdit size={25} />
                      </button>
                    </div>
                    <div className="profileCardHeaderInfo">
                      <p className="profileCardHeaderInfoTextBig">
                        <span>
                          {
                            userDetail?.gender === "Masculino" ? t("profile.welcome_male") 
                            : userDetail?.gender === "Femenino" ? t("profile.welcome_female") 
                            : t("profile.welcome_unspecified")
                          },
                        </span>
                        {userDetail?.name} {userDetail?.lastname}
                      </p>

                      <div className="profileCardBody">
                        <div className="profileCardBodyGroup">
                          <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">
                            {t("profile.modules.my_profile.full_name")}
                          </p>
                          <p className="profileCardBodyGroupText">
                            {userDetail?.name}
                          </p>
                        </div>

                        <div className="profileCardBodyGroup">
                          <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">
                            {t("profile.modules.my_profile.birth_date")}
                          </p>
                          <p className="profileCardBodyGroupText">
                            {userDetail?.birth_date ?? 'N/A'}
                          </p>
                        </div>

                        <div className="profileCardBodyGroup">
                          <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">
                            {t("profile.modules.my_profile.gender")}
                          </p>
                          <p className="profileCardBodyGroupText">{userDetail?.gender || 'N/A'}</p>
                        </div>

                        <div className="profileCardBodyGroup">
                          <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">
                            {t("profile.modules.my_profile.email")}
                          </p>
                          <p className="profileCardBodyGroupText">
                            {userDetail?.email}
                          </p>
                        </div>

                        <div className="profileCardBodyGroup">
                          <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">
                            {t("profile.modules.my_profile.phone")}
                          </p>
                          <p className="profileCardBodyGroupText">
                            {userDetail?.prex_phone_public} {userDetail?.phone_public}
                          </p>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="containerProfileNotifications">
                <div className="containerProfileNotificationsHeader">
                  <h3>{t("profile.notifications")}</h3>
                  <button onClick={() => goToMyNotifications("notifications")}>
                    {t("profile.see_more")}
                  </button>
                </div>

                <div className="containerProfileNotificationsItems overflow-scroll w-fit h-[300px] customScroll">
                  {notifications?.map((notification, index) => (
                    <div
                      key={index}
                      className="containerProfileNotificationsItem"
                    >
                      <div className={`containerProfileNotificationsItemIcon ${notification.type === "info" ? "bg-[var(--blueLight)]" : notification.type === "success" ? "bg-green-500" : notification.type === "warning" ? "bg-yellow-500" : "bg-red-500"}`}>
                        {notification.type === "info" && (
                          <IoInformation size={15} />
                        )}
                        {notification.type === "success" && (
                          <IoCheckmark size={15} />
                        )}
                        {notification.type === "warning" && (
                          <IoWarning size={15} color="white" />
                        )}
                        {notification.type === "error" && (
                          <IoClose size={15} />
                        )}
                      </div>
                      <div className="containerProfileNotificationsItemContent">
                        <p className="!text-[larger] !text-customHoverBlue !font-semibold">
                          {manageNotificationTitle(notification.title)}
                        </p>
                        <p className={`overflow-scroll w-[350px] min-h-fit break-words customScroll ${notification.message.length < 50 ? "!h-fit" : ""}`}>
                          {notification.message}
                        </p>
                        <p className="!text-customBlue">{new Date(notification.created_at).toLocaleString()} </p>
                      </div>
                    </div>
                  ))}
                  {notifications.length === 0 && (
                    <div className="containerProfileNotificationsItem">
                      <p>{t("profile.without_notifications")}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <ModalContainer width={500} show={show} hiddsetActiveChangeServiceen={setShow}>
            <UserUpdate show={show} />
          </ModalContainer>
          {optionActive === 2 && <CommercialProfile />}
          {optionActive === 3 && <MyMovements userId={userDetail?.id} />}
          {optionActive === 4 && <MyItinerary userId={userDetail?.id} />}
          {optionActive === 5 && <AppointmentRequests goToTeleConsultation={(code) => goToTeleConsultation(code)} />}
          {optionActive === 6 && (
            <TeleConsultation code={codeTeleconsutation} />
          )}
          {optionActive === 7 && <FeatureUnderDevelopment />}
          {optionActive === 8 && <FeatureUnderDevelopment />}
          {optionActive === 9 && <FeatureUnderDevelopment />}
          {optionActive === 11 && <MyTravels />}
          {optionActive === 12 && <FeatureUnderDevelopment />}
          {optionActive === 13 && <FeatureUnderDevelopment />}
          {optionActive === 14 && <MyNotifications filterBy={filterBy} />}
          {optionActive === 15 && <MyCart />}
        </div>
      </div>

      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
}
export default ProfileUser;
