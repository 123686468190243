import React, { useEffect, useState, useCallback, memo } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import ActivityIndicator from '../../../ui/views/share/components/activityIndicator';
import "./Assets/styles.css";
import { useTranslation } from "react-i18next";
import { Autocomplete, Backdrop } from '@mui/material';

function SelectOrigin({ setOrigin, origin, setValidate, validate }) {
    const env = process.env.REACT_APP_URL_ENV
    let url = process.env.REACT_APP_URL_API_AIOP;
    const { t } = useTranslation("global");
    const [origins, setOrigins] = useState([]);
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);
        if (newValue) {
            setOrigin(newValue.country_name);
            setValidate("");
        } else {
            setValidate("origin");
        }
    }, [setOrigin, setValidate]);

    useEffect(() => {
        axios.get(`${url}api/terrawind/countries`)
            .then((response) => {
                setOrigins(response.data)
            })
            .catch((error) => {
                console.error(error)
                setError("Ha ocurrido un error al cargar los países de origen.")
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])

    return (
        <div className='select-origin-container'>
            <label htmlFor="" className='label-origin-terrawind'>{t("transfers.origin")} </label>
            <Autocomplete
                className={validate == "origin" ? "input-validate-origin autocomplete-origin-terrawind" : "autocomplete-origin-terrawind"}
                id="combo-box-demo"
                options={origins}
                onChange={handleChange}
                onInputChange={(event, newInputValue) => {
                    setOrigin(newInputValue);
                }}
                getOptionLabel={(option) => option.country_name}
                renderOption={(props, option) => (
                    <li className='list-origin' {...props} style={{ fontFamily: 'Montserrat' }}>
                        {option.country_name}
                    </li>
                )}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                null
                            ),
                            style: {
                                borderRadius: 8,
                            },
                        }}
                    />
                }
            />
            {validate === "origin" ? (<p className="validate-p">{t("travel_insurance.is_required")}</p>) : ""}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <div className='loading-modal-terrawind-plan'>
                    <ActivityIndicator colorFill='#004274' size={40} />
                </div>
            </Backdrop>
        </div>
    );
}

export default SelectOrigin;