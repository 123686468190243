import React, { useRef, useState, useEffect } from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../../../share/components/iconSVG";
import { RowFiveColumnsTitle, RowFiveColumnsInfo } from "../rowFiveColumns";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import TextComponent from "../../../../../share/components/fonts/TextComponent";
import { MdCameraAlt } from "react-icons/md";
import ImageJetSmart from "../../images/jetsmart.svg"

const Modal = ({ show, onClose, children }) => {
  return (
    <div
      className={`${show ? "FlightInfoContentModal" : "FlightInfoContentModal close"
        }`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          onClose();
        }
      }}
    >
      <div
        className={`${show ? "FlightInfoContent" : "FlightInfoContent close"}`}
      >
        <button className="FlightInfoButtonClose" onClick={onClose}>
          <MdClose size={23} color="#104172" />
        </button>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

const ComponentScales = ({ count }) => {
  const [t, i18n] = useTranslation("global");
  const getScale = count.length > 1;
  return getScale
    ? t("fligth.list_flights.scale")
    : t("fligth.list_flights.direct");
};

function ContainerChecked({
  flight,
  activedJourney,
  setActivedJourney,
  id,
  name,
}) {
  const [isImageLoaded, setIsImageLoaded] = useState(true);

  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const ImageAero = id?.marketingCarrier === 'JA' ? ImageJetSmart : `https://pics.avs.io/60/60/${id?.marketingCarrier}.png`;


  return (
    <div className="ContainerChecked">
      <div className="ContainerCheckedUp">
        <div className="checkActive" onClick={() => setActivedJourney(flight)}>
          {activedJourney?.num === flight?.num && (
            <div className="checkInactive"></div>
          )}
        </div>
        <div className="ContainerCheckedLogo">
          {isImageLoaded ? (
            <img
              src={ImageAero}
              alt={id?.marketingCarrier}
              className="ContainerCheckedLogoFile"
              onError={handleImageError}
              onLoad={handleImageLoad}
            />
          ) : (
            <div className="img-fligths-skeleton">
              <MdCameraAlt size={25} color="#c8c8c8" />
            </div>
          )}
        </div>
      </div>
      <TextComponent
        children={name}
        className={"ContainerCheckedName"}
        size={"min"}
        fontWeight="normal"
      />
    </div>
  );
}

function BagsContainer({ list }) {
  const isAvailable = (key) => {
    const bag = key + 1;
    return bag <= list;
  };
  const bagsLis = [
    iconList?.IconBagSmall,
    iconList?.IconBagMedium,
    iconList?.IconBagBig,
  ];
  return (
    <div className="bagsContainer">
      {bagsLis.map((index, key) => {
        let isAvailableBag = key <= list ? true : false;
        return (
          <div key={key} className="bagsContainerIcon">
            <SvgIcon
              name={bagsLis[key]}
              color={isAvailableBag ? "#104172" : "#1d1d1d45"}
              size={50}
            />
          </div>
        );
      })}
    </div>
  );
}

function ListFlights({
  isRoundTrip,
  Seg1,
  Seg2,
  nameOrigin,
  nameDestiny,
  recommendation,
  outwardJourney,
  setOutwardJourney,
  returnJourney,
  setReturnJourney,
}) {
  const containerRef = useRef(null);
  const [t, i18n] = useTranslation("global");
  const [isShowScales, setIsShowScales] = useState(false);
  const [arraySegs, setArraySegs] = useState([Seg1, Seg2]);

  const [openModalFlight, setOpenModalFlight] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [isShowState, setIsShowState] = useState("");

  const [showAllIda, setShowAllIda] = useState(false);
  const [showAllVuelta, setShowAllVuelta] = useState(false);

  const recommendationSegsList = [
    recommendation?.seg?.Seg1,
    recommendation?.seg?.Seg2,
  ];

  const extractDate = (obj) => {
    if (obj === undefined) {
      return "No hay fecha";
    } else {
      const respuesta =
        obj[0]?.segments[0]?.productDateTime?.dateOfDeparture || "hola";

      return respuesta;
    }
  };

  const handleOpenModal = (flight, isShowState) => {
    setSelectedFlight(flight);
    setIsShowState(isShowState);
    setOpenModalFlight(true);
  };


  const RenderFlight = ({ flight, firstSegment, key, isOutward }) => {

    // Función para convertir el attributeDescription a minutos
    const convertToMinutes = (timeStr) => {
      const hours = parseInt(timeStr.substring(0, 2), 10) || 0;
      const minutes = parseInt(timeStr.substring(2, 4), 10) || 0;
      return hours * 60 + minutes;
    };

    // Función modificada para calcular el tiempo total de viaje
    const calculateTotalTravelTime = (segments) => {
      if (!segments || segments.length === 0) return '0h 0min';

      let totalMinutes = 0;

      // Iteramos sobre cada segmento de vuelo
      for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        const attributeDescription = segment?.attributeDetail?.attributeDescription;

        if (attributeDescription) {
          totalMinutes += convertToMinutes(attributeDescription);
        }
      }

      // Convertimos el total de minutos a horas y minutos
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      // Devolvemos el tiempo total en el formato "Xh Ymin"
      return `${hours}h ${minutes}min`;
    };

    // Uso con múltiples segmentos
    const travelTime = calculateTotalTravelTime(flight?.segments);

    return (
      <div
        className={`flightContainerOptionSelect fade-in ${key >= 3
          ? isOutward
            ? showAllIda
              ? "show"
              : "hide"
            : showAllVuelta
              ? "show"
              : "hide"
          : ""
          }`}
        key={key}
      >
        <div className="flightContainerOptionSelectReponsive">
          <div className="nameFlightsBags">
            <ContainerChecked
              flight={flight}
              activedJourney={isOutward ? outwardJourney : returnJourney}
              setActivedJourney={
                isOutward ? setOutwardJourney : setReturnJourney
              }
              id={firstSegment?.companyId}
              name={firstSegment?.companyName}
            />
          </div>
          <RowFiveColumnsInfo
            key={key}
            col1={
              <p className="fiveColumnsTextBig">
                <span className="fiveColumnsHour">
                  {firstSegment?.productDateTime?.timeOfDeparture}
                </span>
              </p>
            }
            col2={
              <RowFiveColumnsTitle
                flight={flight}
                type={travelTime}
                origin={isOutward === true ? nameOrigin : nameDestiny}
                destiny={isOutward === true ? nameDestiny : nameOrigin}
                date={extractDate(arraySegs[isOutward ? 0 : 1])}
                isOurwar={isOutward}
                showModal={handleOpenModal}
              />
            }
            col3={
              <p className="fiveColumnsTextBig">
                <span className="fiveColumnsHour">
                  {flight?.segments[flight?.segments.length - 1]?.productDateTime?.timeOfArrival}
                </span>
              </p>
            }
          />
          <BagsContainer list={recommendation?.bag && recommendation.bag[isOutward ? 0 : 1]} />
        </div>

        <button
          className="fiveButtonArrow"
          onClick={() => handleOpenModal(flight, isOutward ? t("fligth.search_fligth.going") : t("fligth.search_fligth.return"))}
        >
          <IoIosArrowDown size={23} color="#104172" />
        </button>
      </div>
    );
  };

  useEffect(() => {
    setArraySegs([Seg1, Seg2]);
  }, [Seg1, Seg2]);


  return (
    <div className="componentChild" ref={containerRef}>
      <Modal
        show={openModalFlight}
        state={isShowState}
        onClose={() => setOpenModalFlight(false)}
      >
        <div className="componentChildContainerModal">
          <div className="content-render-scales">
            {selectedFlight &&
              selectedFlight?.segments.map((segment, key) => {
                return (
                  <div className="flightContainerOptionSelectModal" key={key}>
                    <div className="componentChildIdaModal">{isShowState}</div>
                    <div className="ContainerCheckedModal">
                      <div className="ContainerCheckedUp">
                        <div className="ContainerCheckedLogo">
                          <img
                            src={segment?.companyId?.marketingCarrier === 'JA' ? ImageJetSmart : `https://pics.avs.io/60/60/${segment?.companyId?.marketingCarrier}.png`}
                            alt={segment?.companyId?.marketingCarrier}
                            className="ContainerCheckedLogoFile"
                          />
                        </div>
                        <TextComponent
                          children={segment?.productDateTime?.dateOfDeparture}
                          size={"md"}
                        />
                      </div>
                      <TextComponent
                        children={segment?.companyName}
                        size={"md"}
                        fontWeight="bold"
                        className={"ContainerCheckedNameModal"}
                      />
                    </div>
                    <RowFiveColumnsInfo
                      openModalFlight={openModalFlight}
                      modal={true}
                      col1={
                        <p className="fiveColumnsTextBig">
                          <span className="fiveColumnsHour">
                            {segment?.productDateTime?.timeOfDeparture}
                          </span>
                          {segment?.location[0]?.locationName}
                        </p>
                      }
                      col2={
                        <RowFiveColumnsTitle
                          type={
                            isShowState === t("fligth.search_fligth.going")
                              ? t("fligth.search_fligth.going")
                              : t("fligth.search_fligth.return")
                          }
                          origin={segment?.location[0]?.locationId}
                          destiny={segment?.location[1]?.locationId}
                          date={extractDate([selectedFlight])}
                          modal={true}
                          flight={selectedFlight}
                        />
                      }
                      col3={
                        <p className="fiveColumnsTextBigRight">
                          <span className="fiveColumnsHour">
                            {segment?.productDateTime?.timeOfArrival}
                          </span>
                          {segment?.location[1]?.locationName}
                        </p>
                      }
                    />
                  </div>
                );
              })}
          </div>

          <div className="componentBagsContainerModal">
            <div className="componentBagsContainerSectionModal">
              <div className="componentBagsContainerSeparationModal">
                <TextComponent
                  children={t("fligth.upsell_choose.luggage")}
                  size={"sm"}
                  fontWeight="bold"
                />
              </div>
              <BagsContainer list={recommendation?.bag && recommendation.bag[isShowState === t("fligth.search_fligth.going") ? 0 : 1]} />

              {/* <BagsContainer
                list={recommendation?.bag[isShowState === t("fligth.search_fligth.going") ? 0 : 1]}
              /> */}
            </div>
            <TextComponent size={"sm"} fontWeight="bold">
              {recommendation?.bag && recommendation?.bag[isShowState === t("fligth.search_fligth.going") ? 0 : 1] === 0
                ? t("fligth.baggage.dimensions_0")
                : recommendation?.bag && recommendation?.bag[isShowState === t("fligth.search_fligth.going") ? 0 : 1] === 1
                  ? t("fligth.baggage.dimensions_1")
                  : t("fligth.baggage.dimensions_2")}
            </TextComponent>
          </div>
        </div>
      </Modal>
      <div className="componentChildContainer">
        <div className="componentChildIda">{t("fligth.search_fligth.going")} </div>
        {(showAllIda
          ? recommendationSegsList[0]
          : recommendationSegsList[0].slice(0, 3)
        ).map((segmentFlightRefItem, key) => {
          const flight = arraySegs[0]?.find(
            (obj) => obj?.num == segmentFlightRefItem?.refNumber
          );
          const firstSegment = flight?.segments[0];
          if (key === 0 && !outwardJourney) {
            setOutwardJourney(flight);
          }
          return RenderFlight({ flight, firstSegment, key, isOutward: true });
        })}
        {recommendationSegsList[0].length > 3 && (
          <button
            className="componentChildIdaButtonViewMore"
            onClick={() => setShowAllIda(!showAllIda)}
          >
            {showAllIda ? t("fligth.search_fligth.hide") : t("profile.see_more")}
            {showAllIda ? (
              <MdKeyboardArrowUp size={20} />
            ) : (
              <MdKeyboardArrowDown size={20} />
            )}
          </button>
        )}
      </div>

      {isRoundTrip === "true" && (
        <>
          <div className="componentChildContainer">
            <div className="componentChildIda">{t("fligth.search_fligth.return")}</div>
            {(showAllVuelta
              ? recommendationSegsList[1]
              : recommendationSegsList[1]?.slice(0, 3)
            )?.map((segmentFlightRefItem, key) => {
              const flight = arraySegs[1]?.find(
                (obj) => obj?.num == segmentFlightRefItem?.refNumber
              );
              const firstSegment = flight?.segments[0];

              if (key === 0 && !returnJourney) {
                setReturnJourney(flight);
              }
              return RenderFlight({
                flight,
                firstSegment,
                key,
                isOutward: false,
              });
            })}
            {recommendationSegsList[1]?.length > 3 && (
              <button
                className="componentChildIdaButtonViewMore"
                onClick={() => setShowAllVuelta(!showAllVuelta)}
              >
                {showAllVuelta ? "Ocultar" : t("profile.see_more")}
                {showAllVuelta ? (
                  <MdKeyboardArrowUp size={20} />
                ) : (
                  <MdKeyboardArrowDown size={20} />
                )}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ListFlights;
