// hooks/useSearchBuildPackage.js
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCityHotelData, setDateGoingSelectedData, setDateReturnSelectedData, setDestinySelectedFlightData, setEspcialtieData, setIdSpecialtyData, setIsRoundTripData, setNationalityData, setOriginSelectedFlightData, setProcedureData, setSpetVariableData, setUrls,resetState } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import { setDataPackage, setEspecialtieAvliable, setLastSearch, setProceduresData, setRoutes } from "@/store/slices/dataStepz/dataStepzSlice";
import useSearchFlights from "@/ui/viewModels/flights/search.flights.viewModel";
import UseHotels from "@/ui/viewModels/hotels/UseHotels";
import useTransport from "@/ui/viewModels/transport/TransportC";
import UseSpecialist from "@/Hooks/BuildPackage/useSpecialist/UseSpecialist";
import { useNavigate } from "react-router-dom";
import useHeaderSession from "@/ui/viewModels/session/header.session.viewModel";
import { persistor } from "@/store";
import localStorage from "redux-persist/es/storage";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const useSearchBuildPackage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  const spetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
  const lastSearch = useSelector((state) => state.dataStepz.lastSearch);
  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const routes = useSelector((state) => state.dataStepz.routes);
  const { userDetail } = useHeaderSession();

  const espcialtieData = useSelector((state) => state.dataSearchEngine.espcialtieData);
  const idEspecialtieData = useSelector((state) => state.dataSearchEngine.idEspecialtieData);
  const procedureData = useSelector((state) => state.dataSearchEngine.procedureData);
  const dateGoingSelectedData = useSelector((state) => state.dataSearchEngine.dateGoingSelectedData);
  const dateReturnSelectedData = useSelector((state) => state.dataSearchEngine.dateReturnSelectedData);
  const cityHotelData = useSelector((state) => state.dataSearchEngine.cityHotelData);
  const nationalityData = useSelector((state) => state.dataSearchEngine.nationalityData);
  const isRoundTripData = useSelector((state) => state.dataSearchEngine.isRoundTripData);
  const originSelectedFlightData = useSelector((state) => state.dataSearchEngine.originSelectedFlightData);
  const destinySelectedFlightData = useSelector((state) => state.dataSearchEngine.destinySelectedFlightData);

  const [activeIndex, setActiveIndex] = useState(spetVariableData);
  const [showCalendar, setShowCalendar] = useState(false);
  const [checkDate, setCheckDate] = useState(false);
  const [showSearchCityHotel, setShowSearchCityHotel] = useState(false);
  const [especialtie, setEspecialtie] = useState("");
  const [showSpecialties, setShowSpecialties] = useState(false);
  const [checkSpeciality, setCheckSpeciality] = useState(false);
  const [idSpecialty, setIdSpecialty] = useState(null);
  const [procedure, setProcedure] = useState("");
  const [showProcedures, setShowProcedures] = useState(false);
  const [checkProcedure, setCheckProcedure] = useState(false);
  const [daysProcedure, setDaysProcedure] = useState(0);
  const [modalQuestion, setModalQuestion] = useState(false);
  const [continuePackage, setContinuePackage] = useState(false);
  const [loadingLastSearch, setLoadingLastSearch] = useState(false);
  const [loadingHotel, setLoadingHotel] = useState(false);
  const [activeSessionModal, setActiveSessionModal] = useState(false)
  const [urlEspecialtieSearch, setUrlEspecialtieSearch] = useState("")

  const isIframe = false;

  const {
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    searcherFlight,
    isCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,
    isCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    setDateGoingSelectedApi,
    setDateReturnSelectedApi,
    setIsManagingPassengers,
    ellipsisText,
    validationSearchDestiny,
    setValidationSearchDestiny,
    validationSearchDate,
    builUrlFlight,
  } = useSearchFlights(isIframe);

  const {
    cardRef,
    openHotelSearch,
    paceholders,
    search,
    setSearch,
    destiny,
    openHotelSearchFuct,
    destinationSelectionFunc,
    findCountryClient,
    nacionalityDropdown,
    setNacionalityDropdown,
    dataNacionality,
    selectedNacionality,
    nacionality,
    codeNacionality,
    loading,
    validationForm,
    setValidationForm,
    buildUrlHotel,
    datesSelected,
    setDatesSelected,
  } = UseHotels(activeIndex);

  const {
    setTypeT,
    typeT,
    cityList,
    citySelected,
    setcitySelected,
    findOrigin,
    originList,
    originSelected,
    setOriginSelected,
    findDestiny,
    destinyList,
    destinySelected,
    setDestinySelected,
    setCityList,
    loadingCity,
    loadingDestiny,
    loadingOrigin,
    city,
    setCity,
    setOriginList,
    setDestinyList,
    cityVerify,
    setCityVerify,
    setDestinyVerify,
    setOriginVerify,
    hoursSe,
    setHoursSe,
    setCalendarVerify,
  } = useTransport();

  const { findSpecialist, modalNoFound, setModalNoFound } = UseSpecialist();
  const localStorage2 = localStorage.getItem("data-std")
  const isEmptyObject = (obj) => {
    if (obj === undefined || obj === null) {
      return true;
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const newSearch = () => {
    if (window.location.pathname === "/build-package") {
      dispatch(setDataPackage({}));
      dispatch(setRoutes({}));
      dispatch(setLastSearch({}));
      
      
      dispatch(setProceduresData([]));
      dispatch(resetState());
      // delete data localStorage
      localStorage.removeItem('form-data-procedure-std');
      localStorage.removeItem('data-std');
      localStorage.removeItem('has-been-paid-build-package');
      setModalQuestion(false);
      setLoadingHotel(false);
  
      // Recargar la página después de que todo se haya ejecutado
      setTimeout(() => {
        // First check if route is build-package
        navigate(0);
      }, 100);
    } else {
      dispatch(setDataPackage({}));
      dispatch(setRoutes({}));
      dispatch(setLastSearch({}));
      dispatch(setProceduresData([]));
      dispatch(resetState());

      localStorage.removeItem('form-data-procedure-std');
      localStorage.removeItem('data-std');
      localStorage.removeItem('has-been-paid-build-package');
      localStorage.removeItem('data-pay-build-package-status');
      localStorage.removeItem('request-id-pay-build-package');
      localStorage.removeItem('data-pay-build-package');
      localStorage.removeItem('data-pay-build-package-body-std');
      localStorage.removeItem('data-pay-build-package-body-aiop');
      localStorage.removeItem('data-pay-build-package-body-crm-packages');

      navigate("/build-package");
    }
  };

  const continueBuy = () => {
    setModalQuestion(false);
    dispatch(setEspcialtieData(lastSearch.espcialtieData));
    dispatch(setIdSpecialtyData(lastSearch.idEspecialtieData));
    dispatch(setProcedureData(lastSearch.procedureData));
    dispatch(setDateGoingSelectedData(lastSearch.dateGoingSelectedData));
    dispatch(setDateReturnSelectedData(lastSearch.dateReturnSelectedData));
    dispatch(setCityHotelData(lastSearch.cityHotelData));
    dispatch(setNationalityData(lastSearch.nationalityData));
    dispatch(setIsRoundTripData(lastSearch.isRoundTripData));
    dispatch(setOriginSelectedFlightData(lastSearch.originSelectedFlightData));
    dispatch(setDestinySelectedFlightData(lastSearch.destinySelectedFlightData));
    setActiveIndex(lastSearch.activeIndex);
    setDateGoingSelected(lastSearch.dateGoingSelectedData);
    setDateReturnSelected(lastSearch.dateReturnSelectedData);
    setContinuePackage(true);
    dispatch(setLastSearch({}));
  };

  const findBuildPack = async () => {
    const urlHotel = await buildUrlHotel(dateGoingSelected, dateReturnSelected, setLoadingHotel); // Esperar a que la función se complete
    const urlFlight = builUrlFlight();


    if (!isEmptyObject(lastSearch) && !isEmptyObject(dataPackage)) {
      setModalQuestion(true);
      return;
    } else {
      dispatch(setLastSearch({
        espcialtieData,
        idEspecialtieData,
        procedureData,
        dateGoingSelectedData,
        dateReturnSelectedData,
        cityHotelData,
        nationalityData,
        isRoundTripData,
        originSelectedFlightData,
        destinySelectedFlightData,
        activeIndex
      }));
    }

    if (continuePackage) {
      if (routes.std) {
        navigate(routes.std.route);

      } else if (routes.summary) {
        navigate(routes.summary.route);

      } else if (routes.clinics) {
        navigate(`${routes.clinics.route}${routes.clinics.search}`);

      } else if (routes.specialist) {
        navigate(`${routes.specialist.route}${routes.specialist.search}`);

      } else if (routes.hotels) {
        navigate(routes.hotels.route);

      } else if (routes.flights) {
        navigate(routes.flights.route);

      }

      return;
    } else {
      console.log("activeIndex antes de las validaciones", activeIndex);
      if (activeIndex === 0 && (!especialtie || !procedure || !destiny || !dateGoingSelected || !nacionality)) {
        console.log("activeIndex 0", activeIndex);
        const notify = (title, icon) =>
          toast(title, {
            icon: icon,
          }
        );
        
        setValidationForm(true);
        setCheckSpeciality(true);
        setCheckProcedure(true);
        setCheckDate(true);
  
        notify(t("common.all_fields_continue_search"), "❌");
  
        return;
      } else if (activeIndex === 1 && (!especialtie || !procedure || !destiny || !dateGoingSelected || selectedOrigin === "o")) {
        console.log("activeIndex 1", activeIndex);
        const notify = (title, icon) =>
          toast(title, {
            icon: icon,
          }
        );
        
        setValidationForm(true);
        setValidationSearchDestiny(false);
  
        if (!especialtie) {
          setCheckSpeciality(true);
        }
  
        if (!procedure) {
          setCheckProcedure(true);
        }
  
        if (!dateGoingSelected) {
          setCheckDate(true);
        }
        
        
        notify(t("common.all_fields_continue_search"), "❌");
  
        return;
      } else if (activeIndex === 2 && (!especialtie || !procedure || !destiny || !dateGoingSelected || !nacionality || selectedOrigin === "o")) {
        console.log("activeIndex 2", activeIndex);
        const notify = (title, icon) =>
          toast(title, {
            icon: icon,
          }
        );
        
        setValidationForm(true);
        setValidationSearchDestiny(false);
  
        if (!especialtie) {
          setCheckSpeciality(true);
        }
  
        if (!procedure) {
          setCheckProcedure(true);
        }
  
        if (!dateGoingSelected) {
          setCheckDate(true);
        }
        
        notify(t("common.all_fields_continue_search"), "❌");
  
        return;
      }
    }

    dispatch(setSpetVariableData(activeIndex));

    const urlEspecialtie = `/build-package/specialist?speciality=${procedure}&city=${paceholders}`
    setUrlEspecialtieSearch(urlEspecialtie)

    setLoadingHotel(true)

    if (activeIndex === 0) {
      if (urlHotel && especialtie && procedure) {

        dispatch(
          setUrls({
            specialist: urlEspecialtie,
            hotel: urlHotel.url,
          })
        );

        if (especialtie !== "Cirugia Plastica Estetica y Reconstructiva") {
          dispatch(setEspecialtieAvliable(false))
          navigate(
            `/build-package/std`
          );
        }

        const findSpecialistData = await findSpecialist(
          1,
          "",
          paceholders,
          procedure
        );

        if (findSpecialistData) {
          dispatch(setEspecialtieAvliable(true))
          if (!userDetail) {
            setLoadingHotel(false)
            setActiveSessionModal(true)
          } else {
            setLoadingHotel(false)
            navigate(
              `${urlEspecialtie}`
            );
          } 

        }
      } else {
        setModalNoFound(true);
      }
    } else if (activeIndex == 1) {
      if (urlFlight && especialtie && procedure) {
        dispatch(
          setUrls({
            specialist: urlEspecialtie,
            flight: urlFlight.url,
          })
        );

        if (especialtie !== "Cirugia Plastica Estetica y Reconstructiva") {
          dispatch(setEspecialtieAvliable(false))
          navigate(
            `/build-package/std`
          );
        }

        const findSpecialistData = await findSpecialist(
          1,
          "",
          paceholders,
          procedure
        );

        if (findSpecialistData) {
          dispatch(setEspecialtieAvliable(true))
          if (!userDetail) {
            setLoadingHotel(false)
            setActiveSessionModal(true)
          } else {
            setLoadingHotel(false)
            navigate(
              urlEspecialtie
            );
          }
        }
      }
    } else if (activeIndex == 2) {
      if (urlFlight && urlHotel && especialtie && procedure) {
        dispatch(
          setUrls({
            specialist: urlEspecialtie,
            hotel: urlHotel.url,
            flight: urlFlight.url,
          })
        );
        const findSpecialistData = await findSpecialist(
          1,
          "",
          paceholders,
          procedure
        );

        if (findSpecialistData) {
          dispatch(setEspecialtieAvliable(true))
          if (!userDetail) {
            setLoadingHotel(false)
            setActiveSessionModal(true)
          } else {
            setLoadingHotel(false)
            navigate(
              urlEspecialtie
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isRoundTripData) {
      setIsRoundTrip(true);
      setCheckDate(true);
    } else {
      setIsRoundTrip(false);
      setCheckDate(false);
    }

    setLoadingLastSearch(true);

    setTimeout(() => {
      setLoadingLastSearch(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (activeIndex !== 1) {
      setIsRoundTrip(true);
      setCheckDate(true);
      dispatch(setIsRoundTripData(true));
    } else {
      setIsRoundTrip(false);
      setCheckDate(false);
      dispatch(setIsRoundTripData(false));
    }
  }, [activeIndex]);

  useEffect(() => {
    if (!isEmptyObject(lastSearch) && !isEmptyObject(dataPackage)) {
      findBuildPack();
    }

    if (isEmptyObject(lastSearch) && !isEmptyObject(dataPackage) && (dateGoingSelectedData || dateReturnSelectedData)) {
      findBuildPack();
    }
  }, [lastSearch, dataPackage]);

  return {
    activeIndex,
    setActiveIndex,
    showCalendar,
    setShowCalendar,
    checkDate,
    setCheckDate,
    showSearchCityHotel,
    setShowSearchCityHotel,
    especialtie,
    setEspecialtie,
    showSpecialties,
    setShowSpecialties,
    checkSpeciality,
    setCheckSpeciality,
    idSpecialty,
    setIdSpecialty,
    procedure,
    setProcedure,
    showProcedures,
    setShowProcedures,
    checkProcedure,
    setCheckProcedure,
    daysProcedure,
    setDaysProcedure,
    modalQuestion,
    setModalQuestion,
    continuePackage,
    setContinuePackage,
    isIframe,
    cardRefDate,
    isRoundTrip,
    setIsRoundTrip,
    searcherFlight,
    isCardRefOrigin,
    selectedOrigin,
    getSelectedOrigin,
    isCardRefDestination,
    selectedDestination,
    getSelectedDestination,
    dateGoingSelected,
    setDateGoingSelected,
    dateReturnSelected,
    setDateReturnSelected,
    setDateGoingSelectedApi,
    setDateReturnSelectedApi,
    setIsManagingPassengers,
    ellipsisText,
    validationSearchDestiny,
    validationSearchDate,
    builUrlFlight,
    cardRef,
    openHotelSearch,
    paceholders,
    search,
    setSearch,
    destiny,
    openHotelSearchFuct,
    destinationSelectionFunc,
    findCountryClient,
    nacionalityDropdown,
    setNacionalityDropdown,
    dataNacionality,
    selectedNacionality,
    nacionality,
    codeNacionality,
    loading,
    validationForm,
    buildUrlHotel,
    datesSelected,
    setDatesSelected,
    setTypeT,
    typeT,
    cityList,
    citySelected,
    setcitySelected,
    findOrigin,
    originList,
    originSelected,
    setOriginSelected,
    findDestiny,
    destinyList,
    destinySelected,
    setDestinySelected,
    setCityList,
    loadingCity,
    loadingDestiny,
    loadingOrigin,
    city,
    setCity,
    setOriginList,
    setDestinyList,
    cityVerify,
    setCityVerify,
    setDestinyVerify,
    setOriginVerify,
    hoursSe,
    setHoursSe,
    setCalendarVerify,
    findSpecialist,
    modalNoFound,
    setModalNoFound,
    isEmptyObject,
    newSearch,
    continueBuy,
    findBuildPack,
    dataPackage,
    loadingLastSearch,
    loadingHotel,
    activeSessionModal,
    setActiveSessionModal,
    urlEspecialtieSearch
  };
};

export default useSearchBuildPackage;