import React, { useEffect } from "react";
import "./styles.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useTranslation } from "react-i18next";

const Paginado = ({
  nextHandler,
  prevHandler,
  currentPage,
  pageRangeDisplayed = 5,
  totalPages
}) => {


  const { t, i18n } = useTranslation("global");

  const handleFirstPageClick = () => {
    nextHandler(true, 1);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 200);
  };

  const handleLastPageClick = () => {
    nextHandler(true, totalPages);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 200);
  };


  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(
      1,
      currentPage - Math.floor(pageRangeDisplayed / 2)
    );
    let endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

    if (endPage - startPage < pageRangeDisplayed - 1) {
      startPage = Math.max(1, endPage - pageRangeDisplayed + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`contain-button-current ${currentPage === i ? "" : "active"
            }`}
          onClick={() => nextHandler(true, i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handleSkipPages = (skipCount) => {
    const newPage = Math.min(Math.max(currentPage + skipCount, 1), totalPages);
    nextHandler(true, newPage);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 200);
  };

  return (
    <div className="contain-paginate-hotels">
      <div className="contain-paginate-hotels-buttons">
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <button
            className={`${currentPage <= 1
              ? "contain-button-prev"
              : "contain-button-prev active"
              }`}
            onClick={handleFirstPageClick}
            disabled={currentPage <= 1}
          >
            <IoIosArrowBack
              style={{ position: "absolute", right: 13 }}
              size={25}
            />
            <IoIosArrowBack size={30} />
          </button>

          <button
            className={`${currentPage <= 1
              ? "contain-button-prev"
              : "contain-button-prev active"
              }`}
            onClick={prevHandler}
            disabled={currentPage <= 1}
          >
            <IoIosArrowBack size={30} />
          </button>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {renderPageNumbers()}

          {currentPage + 5 < totalPages && (
            <button
              className={`contain-button-skip`}
              onClick={() => handleSkipPages(5)}
            >
              + 5
            </button>
          )}
        </div>

        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <button
            className={`${currentPage >= totalPages
              ? "contain-button-next"
              : "contain-button-next active"
              }`}
            onClick={() => nextHandler(false, 1)}
            disabled={currentPage >= totalPages}
          >
            <IoIosArrowForward size={30} />
          </button>

          <button
            className={`${currentPage >= totalPages
              ? "contain-button-next"
              : "contain-button-next active"
              }`}
            onClick={handleLastPageClick}
            disabled={currentPage >= totalPages}
          >
            <IoIosArrowForward size={30} />
            <IoIosArrowForward
              style={{ position: "absolute", left: 13 }}
              size={25}
            />
          </button>
        </div>
      </div>

      <div className="pagination-info">
        {t('pagination.page')} {currentPage} {t('pagination.of')} {totalPages}
      </div>
    </div>
  );
};

export default Paginado;
