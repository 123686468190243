import React from "react";
import "./styles.css";
import TextComponent from "../../../share/components/fonts/TextComponent";
import { useTranslation } from "react-i18next";


const DetailPay = ({ acomodation, price, taxes, total, discount }) => {
  const { t } = useTranslation("global");

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }

  var porcentaje = 35;
  var resultado = Number(total) * (porcentaje / 100);

  return (
    <div className="detail-pay-hotel">
      <TextComponent fontWeight="bold" size={"md"} tag="h2">{t("hotels.reserve.payment_detail")}</TextComponent>
      <div className="row-detail-pay-normal">
        <TextComponent size={"md"} fontWeight="light">{t("hotels.reserve.accommodation_for")} {acomodation}</TextComponent>
        <span>{price}</span>
      </div>
      <div className="row-detail-pay-normal">
        <TextComponent size={"md"} fontWeight="light">{t("hotels.reserve.charges")}</TextComponent>
        <span>{taxes}</span>
      </div>
      <div className="row-detail-pay row-total-pay">
        <TextComponent fontWeight="bold" size={"md"}>{t("hotels.reserve.total")}</TextComponent>
        <span><b>$ {formatearCantidad(total)}</b></span>
      </div>
      <div className="line-separation"></div>
      <div className="row-aditional-services">
        <TextComponent size={"md"} fontWeight="bold">{t("hotels.reserve.charges")}</TextComponent>
        <span>{t("hotels.reserve.charges_description")}</span>
      </div>
      {/* <div className="row-detail-pay row-secure-hotel">
        <section>
          <p>Seguro Hotelero</p>
          <span>Por persona por dia</span>
        </section>
        <span style={{ color: "#004274", fontWeight: "bold" }}>8.900 COP</span>
      </div> */}
    </div>
  );
};

export default DetailPay;
