import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AllCampsForm = () => {
  const { t } = useTranslation("global");

  const [formData, setFormData] = useState({
  });


  const [FormErrors, setFormErrors] = useState({
  })


  const obtenerEstadoInicial = (formularios) => {
    const estadoInicial = {};
  
    formularios?.formulario?.forEach(campo => {
      if (campo.type === 'checkbox') {
        if (campo.options) {
          // Para checkboxes con opciones, inicializa cada opción con false
          campo.options.forEach(option => {
            estadoInicial[option.key] = false; // Inicializa cada opción
          });
        } else {
          // Para un solo checkbox
          estadoInicial[campo.key] = false;
        }
      } else if (campo.type === 'text' || campo.type === 'date') {
        estadoInicial[campo.key] = ""; // Inicializa como cadena vacía
      } else if (campo.type === 'select') {
        estadoInicial[campo.key] = ""; // O valor por defecto para select
      }
    });
  
    // Retorna el estado inicial y el nombre del procedimiento
    return { data: estadoInicial, pro: formularios.procedimiento };
  };
  

  const manejarCambio = (e, key, isCheckbox = false, procedimiento, camps) => {
    const value = isCheckbox ? e.target.checked : e.target.value;

    // Actualizar los datos del procedimiento correspondiente
    setFormData(prevData => {
        const updatedData = {
            ...prevData,
            [procedimiento]: {
                ...prevData[procedimiento],
                [key]: value
            }
        };

        // Revisar si el campo tiene dependencias
        camps?.formulario.forEach(campo => {
            if (campo.dependsOn && campo.dependsOn.key === key) {
                // Habilitar o deshabilitar el campo dependiente
                const isDependentFieldEnabled = value === campo.dependsOn.value;
                updatedData[procedimiento][campo.key] = isDependentFieldEnabled ? '' : null;
            }
        });

        return updatedData;
    });

    // Validar si el campo es requerido y está vacío
    const campo = camps?.formulario.find(campo => campo.key === key);
    if (campo?.required && !value) {
        setFormErrors(prevErrors => ({
            ...prevErrors,
            [procedimiento]: {
                ...prevErrors[procedimiento],
                [key]: 'Este campo es obligatorio'
            }
        }));
    } else {
        setFormErrors(prevErrors => ({
            ...prevErrors,
            [procedimiento]: {
                ...prevErrors[procedimiento],
                [key]: ''
            }
        }));
    }

    // Validar dependencias (si el campo dependiente está habilitado, se vuelve obligatorio)
    camps?.formulario.forEach(campo => {
        if (campo.dependsOn && campo.dependsOn.key === key) {
            const isDependentFieldEnabled = value === campo.dependsOn.value;
            const dependentValue = formData?.[procedimiento]?.[campo.key];

            if (isDependentFieldEnabled && campo.required && !dependentValue) {
                setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [procedimiento]: {
                        ...prevErrors[procedimiento],
                        [campo.key]: 'Este campo es obligatorio'
                    }
                }));
            } else {
                setFormErrors(prevErrors => ({
                    ...prevErrors,
                    [procedimiento]: {
                        ...prevErrors[procedimiento],
                        [campo.key]: ''
                    }
                }));
            }
        }
    });
};

  const cirugiasConfig = {
    mamoplastia: {
      keywords: ["mamaria", "mamoplastia", "mama", "mastopexia", "mamopexia"],
      require_images: [
        "/images/PASOS_CIRUGÍAS_PASO_1_CIRUGÍA_MAMARIA.jpg",
        "/images/PASOS_CIRUGÍAS_PASO_2_CIRUGÍA_MAMARIA.jpg"
      ],
      formulario: [
        { label: t("build_package.steps.std.form.important_questions.mammoplasty.bra_size"), key: "size_brazier", type:'text', required: true },
        { label: t("build_package.steps.std.form.important_questions.mammoplasty.bra_size_reach"), key: "want_size", type: 'text', required: true }, 
        {
          label: t("build_package.steps.std.form.important_questions.mammoplasty.goal.text"),
          key: 'hope_to_achieve',
          type: 'checkbox',
          options: [
            { key: 'add_size', label: t("build_package.steps.std.form.important_questions.mammoplasty.goal.add_size") },
            { key: 'reduce_size', label: t("build_package.steps.std.form.important_questions.mammoplasty.goal.reduce_size") },
            { key: 'get_up', label: t("build_package.steps.std.form.important_questions.mammoplasty.goal.lift") },
            { key: 'same_size_firmer', label: t("build_package.steps.std.form.important_questions.mammoplasty.goal.same_size") },
            { key: 'correct_asymmetry', label: t("build_package.steps.std.form.important_questions.mammoplasty.goal.correct_asymmetry") },
            { key: 'reduce_nipple', label: t("build_package.steps.std.form.important_questions.mammoplasty.goal.reduce_nipples_size") },
            { key: 'others', label: t("build_package.steps.std.form.important_questions.mammoplasty.goal.others") }
          ],
          required: false
        },
        {
          label: t("build_package.steps.std.form.important_questions.mammoplasty.goal.others_placeholder"),
          key: "which_others",
          type: 'text', 
          dependsOn:{
            key: "others",
            value: true,  // Habilitar solo si el campo 'others' es true
          },
          required: false,
        },
        {
          label: t("build_package.steps.std.form.important_questions.mammoplasty.mammary_surgery"),
          key: "performed_breast_surgery",
          type: 'checkbox',
          required: true
        },
        {
          label: t("build_package.steps.std.form.important_questions.mammoplasty.surgery_details"),
          key: "performed_breast_surgery_description",
          type: 'text',
          required: false,
          dependsOn:{
            key: "performed_breast_surgery",
            value: true,  // Habilitar solo si el campo 'performed_breast_surgery' es true
          }
        },
        {
          label: t("build_package.steps.std.form.important_questions.mammoplasty.surgery_date"),
          key: "performed_breast_surgery_date",
          type: 'date',
          required: false,
          dependsOn:{
            key: "performed_breast_surgery",
            value: true,  // Habilitar solo si el campo 'performed_breast_surgery' es true
          }
        },
        {
          label: t("build_package.steps.std.form.important_questions.mammoplasty.implant_place.text"),
          key: "know_implant_place",
          type: 'select',
          options: [
            t("build_package.steps.std.form.important_questions.mammoplasty.implant_place.glandular"),
            t("build_package.steps.std.form.important_questions.mammoplasty.implant_place.submuscular"),
            t("build_package.steps.std.form.important_questions.mammoplasty.implant_place.muscular")
          ],
          dependsOn:{
            key: "performed_breast_surgery",
            value: true,  // Habilitar solo si el campo 'performed_breast_surgery' es true
          },
          required: false
        }, 
        {
          label: t("build_package.steps.std.form.important_questions.mammoplasty.concern_or_question.text"),
          key: 'biggest_concern_doubt',
          type: 'checkbox', 
          options: [
            { key: 'scars', label: t("build_package.steps.std.form.important_questions.mammoplasty.concern_or_question.scars") },
            { key: 'specialist_suitability', label: t("build_package.steps.std.form.important_questions.mammoplasty.concern_or_question.specialist_suitability") },
            { key: 'security_clearance', label: t("build_package.steps.std.form.important_questions.mammoplasty.concern_or_question.procedure_security") },
            { key: 'expected_results', label: t("build_package.steps.std.form.important_questions.mammoplasty.concern_or_question.expected_results") },
            { key: 'recovery_time', label: t("build_package.steps.std.form.important_questions.mammoplasty.concern_or_question.recovery_time") },
            { key: 'info_about_implants', label: t("build_package.steps.std.form.important_questions.mammoplasty.concern_or_question.info_about_implants") },
            { key: 'info_about_techniques', label: t("build_package.steps.std.form.important_questions.mammoplasty.concern_or_question.info_about_techniques") }
          ],
          required: false
        },
        {
          label: t("build_package.steps.std.form.important_questions.mammoplasty.more_to_consider"),
          key: "something_you_consider_important",
          type: 'text',
          required: true
        },
        {
          label: t("build_package.steps.std.form.important_questions.mammoplasty.some_recommended"),
          key: "someone_recommended_us",
          type: 'checkbox',
          required: true
        },
        {
          label: t("build_package.steps.std.form.important_questions.mammoplasty.who_recommended"),
          key: "recommend_description",
          type: 'text',
          dependsOn:{
            key: "someone_recommended_us",
            value: true,  // Habilitar solo si el campo 'performed_breast_surgery' es true
          },
          required: false,
        },
       
      ]
    },
    rinoplastia: {
      keywords: ["rinoplastia","rino"],
      formulario: [

        {
          label: "¿Has sufrido fracturas de nariz?",
          key: "someone_recommended_us",
          type: 'checkbox',
          required: false
        },
        {
          label: "¿Quien nos recomendo? (si aplica)",
          key: "recommend_description",
          type: 'text',
          required: false
        }

      ]
    },
    liposuccion: {
      keywords: ["lipo","liposuccion","lipoabdominoplastia"],
      formulario: [
        {
          label: "¿Te has echo alguna lipo?",
          key: "someone_recommended_us",
          type: 'checkbox',
          required: false
        },
        {
          label: "¿Quien nos recomendo? (si aplica)",
          key: "recommend_description",
          type: 'text',
          required: true
        }

      ]
    }
  };


  const obtenerFormulariosPorTexto = (texto) => {
    const formulariosEncontrados = [];
  
    // Recorrer las configuraciones de las cirugías
    Object.keys(cirugiasConfig).forEach(procedimiento => {
      const { keywords, formulario, require_images } = cirugiasConfig[procedimiento];
  
      // Verificar si alguna palabra clave coincide con el texto ingresado
      if (keywords.some(keyword => texto.toLowerCase().includes(keyword))) {
        // Si hay coincidencias, agregar el procedimiento y el formulario encontrado al array
        formulariosEncontrados.push({
          procedimiento,   // Aquí se añade el nombre del procedimiento (ej: "Rinoplastia")
          formulario,       // Aquí se añade el formulario correspondiente
          require_images
        });
      }
    });
  
    return formulariosEncontrados;
  };
  

  return {
    cirugiasConfig,
    formData,
    setFormData,
    manejarCambio,
    obtenerFormulariosPorTexto,
    obtenerEstadoInicial,
    setFormErrors,
    FormErrors
  }
}

export default AllCampsForm