import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import ConfirmModal from "@/ui/views/CartCheckout/components/ConfirmModal";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const StepStep = ({ id, title, onClick, step, disabled }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  const stepRef = useRef(null);
  const [active, setActive] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasAlreadyPaid, setHasAlreadyPaid] = useState(false);

  const currentStep = useSelector((state) => state.dataStepz.currentStep);
  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const routes = useSelector((state) => state.dataStepz.routes);
  const setSpetVariableData = useSelector(
    (state) => state.dataSearchEngine.spetVariableData
  );

  const confirmProcedure = () => {
    setShowConfirmModal(false);
    navigate("/build-package/std");
  };

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    }
  );

  const changeStep = (step) => {
    if (disabled) {
      notify(t("build_package.steps.std.valoration.already_paid"), "❌");
      return;
    }
    
    if (step === 1 && routes.specialist) {
      if (hasAlreadyPaid) {
        notify(t("build_package.steps.std.valoration.already_paid"), "❌");
        return;  
      }

      navigate(`${routes.specialist.route}${routes.specialist.search}`);

    } else if (step === 2 && routes.clinics) {
      if (hasAlreadyPaid) {
        notify(t("build_package.steps.std.valoration.already_paid"), "❌");
        return;  
      }
      navigate(`${routes.clinics.route}${routes.clinics.search}`);

    }else if (step === 3 && routes.hotels && (setSpetVariableData === 0 || setSpetVariableData === 2)) {
      if (hasAlreadyPaid) {
        notify(t("build_package.steps.std.valoration.already_paid"), "❌");
        return;  
      }
      
      navigate(`${routes.hotels.route}`);

    }  else if (step === 3 && routes.flights && setSpetVariableData === 1) {
      if (hasAlreadyPaid) {
        notify(t("build_package.steps.std.valoration.already_paid"), "❌");
        return;  
      }

      navigate(`${routes.flights.route}`);
    } else if (step === 4 && routes.flights && setSpetVariableData === 2) {
      if (hasAlreadyPaid) {
        notify(t("build_package.steps.std.valoration.already_paid"), "❌");
        return;  
      }

      navigate(`${routes.flights.route}`);
    } else if (step === 4 && routes.clinics && setSpetVariableData === 2) {
      if (hasAlreadyPaid) {
        notify(t("build_package.steps.std.valoration.already_paid"), "❌");
        return;  
      }

      navigate(`${routes.clinics.route}${routes.clinics.search}`);
    } else if (step === 4 && routes.summary && (setSpetVariableData === 0 || setSpetVariableData === 1)) {
      navigate(`${routes.summary.route}`);
    } else if (step === 5 && routes.summary && setSpetVariableData === 2) {
      navigate(`${routes.summary.route}`);
    } else if (step === 5 && (setSpetVariableData === 0 || setSpetVariableData === 1)) {
      setShowConfirmModal(true);
    } else if (step === 6 && (setSpetVariableData === 0 || setSpetVariableData === 1)) {
      if (!hasAlreadyPaid) {
        notify(t("Aún no has realizado el pago de la valoración"), "❌");
        return;  
      }

      navigate("/build-package/valuation-payment"); 
    } else if (step === 6 && setSpetVariableData === 2) {
      setShowConfirmModal(true);
    } else if (step === 7 && setSpetVariableData === 2) {
      if (!hasAlreadyPaid) {
        notify(t("Aún no has realizado el pago de la valoración"), "❌");
        return;  
      }

      navigate("/build-package/valuation-payment");
    }
  };

  useEffect(() => {
    if (currentStep === step) {
      setActive(true);

      if (window.innerWidth < 640 && stepRef.current) {
        stepRef.current.scrollIntoView({ behavior: "smooth", inline: "center" });
      }
    }
  }, [currentStep, step]);

  const isCompleted = currentStep > step;

  useEffect(() => {
    const checkStatusPayment = () => {
        const checkIfHasBeenPaid = localStorage.getItem('has-been-paid-build-package')

        if (checkIfHasBeenPaid) {
            setHasAlreadyPaid(true);
        }
    }

    checkStatusPayment()
  }, [step])

  return (
    <>
      <div className={styles.itemSectionAll} ref={stepRef}>
        <div className={styles.stepToStep}>
          <div
            className={`${styles.itemSection} ${active ? styles.active : ""} ${isCompleted ? styles.completed : ""
              }`}
            onClick={() => changeStep(step)}
          >
            <h2 className={styles.itemText}>{step}</h2>
            <div className={styles.itemNumberTitle}>
              <h3 className={styles.itemText}>{title}</h3>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        confirmProcedure={confirmProcedure}
        hasCloseButton={false}
      />
    </>
  );
};

export default StepStep;
