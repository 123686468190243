import "./styles.css";
import img1 from "./img1.png";
import img2 from "./img2.png";
import img3 from "./img3.png";
import img4 from "./img4.png";
import SliderCard from "../../home/components/SliderOferts/SliderCard";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import SliderCardLowCostHealth from "../../home/components/SliderOferts/SliderCardLowCostHealth";

const BestExperiences = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [CardsType, setCardsType] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1354,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const searchTypeCards = () => {
    const url = `${process.env.REACT_APP_URL_API_AIOP}api/medic_packages`;
    axios
      .get(url)
      .then((res) => {
        const packs = res.data?.packs.data || [];
        const duplicatedPacks = [...packs, ...packs]; // Duplicar los elementos
        // Ordenar los packs por precio de menor a mayor
        duplicatedPacks.sort((a, b) => a.price - b.price);
        setCardsType(duplicatedPacks);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    searchTypeCards();
  }, []);

  const detailPack = (item, id) => {
    navigate(`/TourismHealth/DetailProcedure/${id}`, {
      state: {
        data: item,
      },
    });
  };

  const [loadingImage, setLoadingImage] = useState(true);

  const handleClickPack = (item, id) => {
    navigate(`/packages/${id}`);
  }; 

  return (
    <div className="content-best-experiences">
      <div className="header-experiences">
        <h2>{t("packs.health.featured_title")}</h2>
        <p>{t("packs.health.description")}</p>
      </div>
      <section className="slider-experience">
        <Slider {...settings}>
          {CardsType.map((item, key) => {
            return (
              <SliderCardLowCostHealth
                carousel={true}
                boxShadow={true}
                img={item.image}
                imgOption={true}
                price={item.price}
                key={key}
                title={item.name}
                typeCurrency={item?.currency}
                item={item}
                loadingImage={loadingImage}
                setLoadingImage={setLoadingImage}
                colorButton={"var(--blueLight)"}
                startDate={
                  item?.start_date ? item?.start_date : item?.trip_from
                }
                dueDate={item?.due_date ? item?.due_date : item?.trip_up}
                typePack={item?.due_date ? false : true}
                handleClickPack={item?.due_date ? handleClickPack : detailPack}
              />
            );
          })}
        </Slider>
      </section>
    </div>
  );
};

export default BestExperiences;
