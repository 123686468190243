import React from 'react';
import { Dialog } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { MdCameraAlt, MdFileDownloadDone } from 'react-icons/md';
import { FaRegFileImage } from 'react-icons/fa';
import { BsCheckCircleFill } from 'react-icons/bs';
import { useState } from 'react';
import "../styles.css";
import { useTranslation } from 'react-i18next';


const PhotoUploadDialog = ({
  open,
  handleClose,
  procedureName,
  formsFilter,
  uploadedImages,
  FormErrors,
  setUploadedImages,
  setFormData,
  setFormErrors
}) => {
  const { t, i18n } = useTranslation("global");


  const [loadedImages, setLoadedImages] = useState({});
  const [imageErrors, setImageErrors] = useState({});

  const handleImageUpload = (e, procedimiento, index) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Image = reader.result;

        setUploadedImages((prevState) => ({
          ...prevState,
          [procedimiento]: {
            ...prevState[procedimiento],
            [index]: URL.createObjectURL(file),
          },
        }));

        setFormData((prevData) => ({
          ...prevData,
          [procedimiento]: {
            ...prevData[procedimiento],
            require_images: {
              ...prevData[procedimiento]?.require_images,
              [index]: base64Image,
            },
          },
        }));

        setFormErrors((prevErrors) => {
          const newErrors = { ...prevErrors };

          if (newErrors[procedimiento]?.require_images?.[index]) {
            delete newErrors[procedimiento].require_images[index];

            if (Object.keys(newErrors[procedimiento].require_images).length === 0) {
              delete newErrors[procedimiento].require_images;
            }

            if (Object.keys(newErrors[procedimiento]).length === 0) {
              delete newErrors[procedimiento];
            }
          }

          return newErrors;
        });
      };

      reader.readAsDataURL(file);
    }
  };

  // Función para manejar cuando una imagen se carga correctamente
  const handleImageLoad = (key) => {
    setLoadedImages(prevState => ({
      ...prevState, // Mantiene el estado previo
      [key]: true  // Marca la imagen como cargada
    }));
    setImageErrors(prevState => ({
      ...prevState, // Mantiene el estado previo
      [key]: false // Marca que no hay error al cargar la imagen
    }));
  };

  // Función para manejar cuando una imagen no se carga correctamente
  const handleImageError = (key) => {
    setImageErrors(prevState => ({
      ...prevState, // Mantiene el estado previo
      [key]: true  // Marca que hay un error al cargar la imagen
    }));
  };


  return (
    <Dialog
      BackdropProps={{
        sx: {
          backgroundColor: '#021624b0',
        },
      }}
      PaperProps={{ sx: { width: "100%", maxWidth: "850px!important", padding: "26px", borderRadius: '8px' } }}
      open={open}
      onClose={handleClose}
    >
      <div className='container_form_photos'>
        <div className='container_photos'>
          <div className='divTopModal'>
            <h3>{t("build_package.steps.std.form.important_questions.attach_required_photos")} ({procedureName === "Mamoplastia" && i18n.language === "en" ? "Mammoplasty" : procedureName}) *</h3>
            <IoClose onClick={handleClose} />
          </div>

          <div className='contRecomendationsModal'>
            <h5>{t("build_package.steps.std.form.important_questions.indications.text")}</h5>
            <p>- {t("build_package.steps.std.form.important_questions.indications.first")}</p>
            <p>- {t("build_package.steps.std.form.important_questions.indications.second")}</p>
            <p>- {t("build_package.steps.std.form.important_questions.indications.third")}</p>
          </div>

          <div style={{ marginBottom: '10px' }}>
            <p style={{ color: '#225B87' }}>{t("build_package.steps.std.form.important_questions.indications.description")}  ({procedureName === "Mamoplastia" && i18n.language === "en" ? "Mammoplasty" : procedureName}).</p>
          </div>

          {(() => {
            const uploadedCount = uploadedImages[procedureName] ? Object.keys(uploadedImages[procedureName]).length : 0;
            const requiredImagesCount = formsFilter[0].require_images.length - uploadedCount;
            const requiredImagesText = requiredImagesCount > 1
              ? t("build_package.steps.std.form.important_questions.images_to_continue", { count: requiredImagesCount })
              : t("build_package.steps.std.form.important_questions.image_to_continue", { count: requiredImagesCount });

            if (requiredImagesCount === 0) {
              return (
                <p style={{ color: "green", marginBottom: '10px' }}>
                  {t("build_package.steps.std.form.important_questions.selected_images_successfully")}
                </p>
              );
            } else {
              return (
                FormErrors[procedureName]?.require_images && (
                  <p style={{ color: "var(--redPrimary)", marginBottom: '10px' }}>
                    {requiredImagesText}
                  </p>
                )
              );
            }
          })()}

          <div className="grid_container">
            {formsFilter[0]?.require_images?.map((img, index) => {
              const uploadedImage = uploadedImages[procedureName]?.[index];
              const imageKey = `${procedureName}-${index}`;
              return (
                <div key={index} className="itemFileImage">
                  <div className="itemFileImageContent">
                    {(!loadedImages[imageKey] || imageErrors[imageKey]) && (
                      <div className='img-transport-skeleton-cards'>
                        <MdCameraAlt size={40} color="#c8c8c8" />
                      </div>
                    )}
                    <img
                      src={uploadedImage || img}
                      alt={`Imagen ${index + 1}`}
                      onLoad={() => handleImageLoad(imageKey)}
                      onError={() => handleImageError(imageKey)}
                      style={{ display: (loadedImages[imageKey] && !imageErrors[imageKey]) ? 'block' : 'none' }}
                    />
                  </div>
                  <label htmlFor={`file-${index}`} className={`custumFileUploadDialog ${uploadedImage ? 'uploaded' : ''}`}>
                    <div className={`iconDialog ${uploadedImage ? 'uploaded' : ''}`}>
                      {uploadedImage ? (<MdFileDownloadDone size={18} />) : (<FaRegFileImage size={18} />)}
                    </div>
                    <div className="text">
                      <span className={`uploadedText ${uploadedImage ? 'uploaded' : ''}`}>
                        {uploadedImage ? `IMAGEN_${index + 1}` : t("build_package.steps.std.form.important_questions.attach_photo")}
                      </span>
                    </div>
                    <input
                      id={`file-${index}`}
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, procedureName, index)}
                    />
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        <div className='bottomTextModal'>
          <p>{t("build_package.steps.std.form.important_questions.before_send")}</p>
        </div>

        <div className='positionButton'>
          <button onClick={handleClose} className='openModalButton'>
            {t("build_package.steps.std.form.important_questions.confirm_button")} <BsCheckCircleFill size={17} />
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default PhotoUploadDialog;