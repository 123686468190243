import React, { useEffect, useRef, useState } from "react";
import useUpsellChoose from "../../../../viewModels/flights/upsell.choose.viewModel";
import "./styles.css";
import { ArrowRight2, CloseCircle, TickCircle, Warning2 } from "iconsax-react";
import { currencyFormat, formatCurrencyString } from "../../../../utils";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { LuLoader2 } from "react-icons/lu";
import TextComponent from "../../../share/components/fonts/TextComponent";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import { FaShoppingCart } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsBackpack2Fill } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import BagComponent from "./BagComponent";
import toast, { Toaster } from "react-hot-toast";
import Loading from "@/ui/views/share/components/loading";
import { Dialog } from "@mui/material";

const UpsellChoose = ({
  getandOpen,
  closeGetandOpen,
  isRoundTrip,
  outwardJourney,
  returnJourney,
  recommendation,
  toBuy,
  price,
  continueBuy,
  setDataCabin,
  setDataComponents,
  allPassengersSelected,
  setPriceUpsell,
  currency,
  activeSessionModal,
  company,
  airlines,
}) => {
  const {
    isLoading,
    isEmpty,
    openUpsell,
    setOpenUpsell,
    getUpsell,
    clearError,
    farePrice,
    selectedFare,
    cleanData,
    familiesCombinationsCode,
    chooseFare,
    showDetails,
    textToLowerCase,
    selectedFareModal,
    openModal,
    closeModal,
    upsell,
    savePrice,
    setFarePrice,
    setPriceUppsell,
  } = useUpsellChoose(
    getandOpen,
    closeGetandOpen,
    isRoundTrip,
    outwardJourney,
    returnJourney,
    recommendation,
    setDataCabin,
    setDataComponents,
    allPassengersSelected,
    setPriceUpsell,
    currency,
    continueBuy,
    airlines,
    company,
    
  );

  const {t} = useTranslation("global");
  const typeOfCoin = { name: currency, abbreviation: currency, simbol: "$" };
  const [viewLuggage, setViewLuggage] = useState(true);
  const [localTarifaLast, setLocalTarifaLast] = useState("QP");
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValueVuelta, setSelectedValueVuelta] = useState(null);

  const isRoundTripLocal = localStorage.getItem("isRoundTrip");

  const [activeWay, setActiveWay] = useState(true);
  const [activeTrip, setActiveTrip] = useState(false);
  const [valueGoing, setValueGoing] = useState(0);

  const handleReturn = () => {
    setActiveTrip(false);
    setActiveWay(true);
  };

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });
  const [validateSelecteds, setValidateSelecteds] = useState(false);

  useEffect(() => {
    if (activeSessionModal) {
      setOpenUpsell(false)
    }

  }, [activeSessionModal]);

  return (
    <>
      {/* <Toaster position="top-center" reverseOrder={false} /> */}
      {!isEmpty && (
        <UpsellQuery
          isLoading={isLoading}
          isOpen={openUpsell}
          open={getUpsell}
          close={setOpenUpsell}
        />
      )}

      <UpsellQueryEmpty
        clear={clearError}
        isEmpty={isEmpty}
        isLoading={isLoading}
        openUpsell={openUpsell}
      />

      <Dialog
        open={openUpsell && !isEmpty}
        onClose={() => setOpenUpsell(false)}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "810px!important",
            borderRadius: '12px',
            maxHeight: '80vh' // 70% del alto de la pantalla
          }
        }}
        BackdropProps={{
          sx: {
            backgroundColor: '#021624b0' // Color con opacidad
          }
        }}
      >
        <div
          className={"upsellContentModal"}
        >
          <span className="upsellContentOptionsTarifsView">
            {!activeTrip ? t("fligth.search_fligth.going") : t("fligth.search_fligth.return")}
          </span>
          <button
            onClick={() => setOpenUpsell(false)}
            className="upsellContentModalClose"
          >
            <MdClose size={25} />
          </button>

          <div className="upsellContentOptions">
            <h3 style={{ fontSize: 18, fontWeight: 700, color: "#104172" }}>
              {t("fligth.search_fligth.select_best_tariff")}
            </h3>
            <div className="upsellContentOptionsTarifs">
              <div
                className={`${activeWay
                  ? "upsellContentOptionsTarifsWay active"
                  : "upsellContentOptionsTarifsWay"
                  }`}
              >
                <span
                  className={`${viewLuggage
                    ? "upsellContentOption active"
                    : "upsellContentOption"
                    }`}
                  onClick={() => setViewLuggage(true)}
                >
                  <div className="upsellContentGoing">
                    <Tariffa
                      name={t("fligth.search_fligth.going")}
                      isRoundTrip={isRoundTrip}
                      selectedFare={selectedFare}
                      cleanData={cleanData}
                      combinations={familiesCombinationsCode}
                      choose={chooseFare}
                      textToLowerCase={textToLowerCase}
                      showDetails={showDetails}
                      setValidateSelecteds={setValidateSelecteds}
                      validateSelecteds={validateSelecteds}
                      farePrice={farePrice}
                      currency={currency}
                      uppsel={upsell}
                      localTarifaLast={localTarifaLast}
                      setLocalTarifaLast={setLocalTarifaLast}
                      allPassengersSelected={allPassengersSelected}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      price={price}
                      activeTrip={activeTrip}
                      valueGoing={valueGoing}
                      setValueGoing={setValueGoing}
                      airlines={airlines}
                      company={company}
                    />
                  </div>
                </span>
              </div>

              <div
                className={`${!activeTrip
                  ? "upsellContentOptionsTarifsTrip"
                  : "upsellContentOptionsTarifsTrip active"
                  }`}
              >
                {isRoundTripLocal && (
                  <span
                    className={`${viewLuggage
                      ? "upsellContentOption"
                      : "upsellContentOption active"
                      }`}
                    onClick={() => setViewLuggage(false)}
                  >
                    <div className="upsellContentReturn">
                      <Tariffa
                        name={t("fligth.search_fligth.return")}
                        isRoundTrip={isRoundTrip}
                        selectedFare={selectedFare}
                        cleanData={cleanData}
                        combinations={familiesCombinationsCode}
                        choose={chooseFare}
                        textToLowerCase={textToLowerCase}
                        showDetails={showDetails}
                        setValidateSelecteds={setValidateSelecteds}
                        validateSelecteds={validateSelecteds}
                        priceQual={farePrice}
                        currency={currency}
                        uppsel={upsell}
                        localTarifaLast={localTarifaLast}
                        setLocalTarifaLast={setLocalTarifaLast}
                        allPassengersSelected={allPassengersSelected}
                        selectedValue={selectedValueVuelta}
                        setSelectedValue={setSelectedValueVuelta}
                        price={price}
                        activeTrip={activeTrip}
                        valueGoing={valueGoing}
                        setValueGoing={setValueGoing}
                        airlines={airlines}
                        company={company}
                      />
                    </div>
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* <ComponentModal
            isOpen={openModal}
            data={selectedFareModal}
            close={closeModal}
          /> */}

          <section
            className="legend-bags-tips"
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              border: "1px solid #0042741a",
              padding: ".8rem",
              borderRadius: "5px",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", gap: ".4rem" }}
            >
              <IoMdCheckmark color="#104172" width={20} height={20} />{" "}
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  color: "#004274",
                }}
              >
                {t("fligth.configure_flight.includes_tariff")}
              </span>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", gap: ".4rem" }}
            >
              <FaShoppingCart color="#104172" width={20} height={20} />{" "}
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  color: "#004274",
                }}
              >
                {t("fligth.configure_flight.contact_airline")}
              </span>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", gap: ".4rem" }}
            >
              <IoIosClose color="#104172" width={20} height={20} />{" "}
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  color: "#004274",
                }}
              >
                {t("fligth.configure_flight.no_includes_tariff")}
              </span>
            </div>
          </section>

          <p style={{ fontSize: "12px" }}>
            {t("fligth.configure_flight.benefits")}
          </p>

          <div className="upsellContentContinue">
            {activeTrip && (
              <button
                className="upsellContentContinueButton"
                onClick={() => {
                  handleReturn();
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                  paddingRight: 25,
                }}
              >
                <IoIosArrowBack size={20} />
                {t("fligth.configure_flight.return_to_search")}
              </button>
            )}
            <div className="upsellContentContinueTitle">
              {t("fligth.configure_flight.final_price")}
              <span>
                ${currencyFormat(farePrice?.priceTotalFare, typeOfCoin).total}
              </span>
            </div>

            {isRoundTripLocal === "true" ? (
              !activeTrip && activeWay ? (
                <button
                  onClick={() => {
                    if (selectedValue) {
                      setActiveTrip(true);
                      setActiveWay(false);
                      savePrice(farePrice, false);
                      setValidateSelecteds(false)
                    } else {
                      notify(t("fligth.configure_flight.select_tariff"), "❌");
                    }
                  }}
                  className="upsellContentContinueButton"
                >
                  {t("fligth.configure_flight.next")}
                </button>
              ) : (
                <button
                  onClick={() => {
                    if (selectedValue && validateSelecteds) {
                      setSelectedValue(null);
                      savePrice(farePrice, true);
                    } else {
                      notify(t("fligth.configure_flight.select_tariff"), "❌");
                    }
                  }}
                  className={`${validateSelecteds
                    ? "upsellContentContinueButton"
                    : "upsellContentContinueButton disabled"
                    }`}
                >
                  {t("fligth.configure_flight.continue")}
                </button>
              )
            ) : (
              <button
                onClick={() => {
                  if (selectedValue) {
                    setSelectedValue(null);
                    savePrice(farePrice, true);
                  } else {
                    notify(t("fligth.configure_flight.select_tariff"), "❌");
                  }
                }}
                className={`${validateSelecteds
                  ? "upsellContentContinueButton"
                  : "upsellContentContinueButton disabled"
                  }`}
              >
                {t("fligth.configure_flight.continue")}
              </button>
            )}
          </div>
        </div>
      </Dialog>


    </>
  );
};
export default UpsellChoose;

const Tariffa = ({
  name,
  isRoundTrip,
  selectedFare,
  cleanData,
  combinations,
  choose,
  textToLowerCase,
  showDetails,
  setValidateSelecteds,
  farePrice,
  currency,
  uppsel,
  localTarifaLast,
  setLocalTarifaLast,
  allPassengersSelected,
  setSelectedValue,
  selectedValue,
  price,
  activeTrip,
  valueGoing,
  setValueGoing,
  company,
  airlines
}) => {
  const { t } = useTranslation("global");
  const position = name === t("fligth.search_fligth.going") ? 0 : 1;
  const data = cleanData(selectedFare, name);
  const typeOfCoin = { name: currency, abbreviation: currency, simbol: "$" };
  const nameFare =
    selectedFare?.components[position]?.fareFamilyDetails?.fareFamilyname;
  const [isOpenList, setIsOpenList] = useState(false);
  const [arrowServices, setArrowServices] = useState(0);

  function removeDuplicates(arr) {
    const uniqueObjects = arr.reduce((unique, item) => {
      if (!unique[item.oneWay]) {
        unique[item.oneWay] = item;
      }
      return unique;
    }, {});
    return Object.values(uniqueObjects);
  }

  const getChoose = (value, data) => {
    setIsOpenList(false);
    choose(name, value, data);
    setSelectedValue(value);
    setValidateSelecteds(true);
  };

  const optionsCombinationsOneWay = removeDuplicates(combinations);
  const optionsCombinationsRoundTrip = combinations.filter(
    (obj) => obj?.oneWay === localTarifaLast
  );
  const optionsCombinations =
    name === t("fligth.search_fligth.going")
      ? optionsCombinationsOneWay
      : optionsCombinationsRoundTrip;

  useEffect(() => {
    // if (optionsCombinations.length > 0 && selectedValue === null) {
    //   const defaultValue =
    //     name === t("fligth.search_fligth.going")
    //       ? optionsCombinations[0]?.oneWay
    //       : optionsCombinations[0]?.roundtrip;
    //   setSelectedValue(defaultValue);
    //   choose(name, defaultValue, false);
    //   setValidateSelecteds(true);
    // }
  }, [name, optionsCombinations]);

  function contienePalabras(texto, ...palabrasClave) {
    const textoMinusculas = texto.toLowerCase();
    for (let palabra of palabrasClave) {
      if (textoMinusculas.includes(palabra.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  const toLowerCaset = (textt) => {
    const textConvert = textt;
    return textConvert?.toLowerCase();
  };

  const sliderRef = useRef(null);

  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);

  const [showArrows, setShowArrows] = useState(false);

  const handleResize = () => {
    // Verifica si hay más de 3 slides o si el ancho es menor a 800px
    setShowArrows(optionsCombinations.length > 3 || window.innerWidth < 800);
  };

  useEffect(() => {
    handleResize(); // Ejecuta la función al cargar el componente

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [optionsCombinations.length]);


  const CustomArrow = ({ className, style, onClick, isDisabled }) => (
    <button
      className={className}
      style={{
        ...style,
        display: isDisabled ? "none" : "block",
      }}
      onClick={onClick}
      disabled={isDisabled}
    />
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: showArrows,
    nextArrow: <CustomArrow isDisabled={isNextDisabled} />,
    prevArrow: <CustomArrow isDisabled={isPrevDisabled} />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: showArrows,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: showArrows,
          dots: false,
        },
      }
    ],
  };


  return (
    <div className="upsellCardBodyColumn">
      <div className="upsellCardHeadListContaine">
        {optionsCombinations.length > 0 && (
          <Slider ref={sliderRef} {...settings}>
            {optionsCombinations.map((index, key) => {
              const detailsType = name === t("fligth.search_fligth.going") ? "details" : "details2";
              const value =
                name === t("fligth.search_fligth.going")
                  ? index?.oneWay
                  : index?.roundtrip;
              const isSelected = value === selectedValue;
              console.log(index)
              const adultPrice = Number(
                index.passengers[0]
                  ? index?.passengers[0]?.count * index?.passengers[0]?.fare
                  : 0
              );
              const boyPrice = Number(
                index.passengers[1]
                  ? index?.passengers[1]?.count * index?.passengers[1]?.fare
                  : 0
              );
              const babyPrice = Number(
                index.passengers[2]
                  ? index?.passengers[2]?.count * index?.passengers[2]?.fare
                  : 0
              );
              const items = index?.[`${detailsType}`]?.ocFeeInformation?.map(
                (item, key) => {
                  const typeA =
                    item?.feeDescription?.dataInformation?.indicator;
                  const typeB =
                    item?.serviceDetails?.specialRequirementsInfo?.serviceType;
                  const bagAvailable = typeA === "INC" && typeB === "BG";
                  const textCondition = item?.feeFreeFlowDescription?.freeText;
                  const isCarry = contienePalabras(
                    textCondition,
                    t("fligth.configure_flight.carry_on"),
                    t("fligth.configure_flight.carry_on_dash"),
                    t("fligth.configure_flight.carryon"),
                    t("fligth.configure_flight.carry"),
                    t("fligth.configure_flight.carry10kg"),
                    t("fligth.configure_flight.10kg"),
                    t("fligth.configure_flight.1_cabin_bag_up_to_8kg"),
                    t("fligth.configure_flight.2_cabin_bags_up_to_8kg")
                  );
                  const isLaptop = contienePalabras(
                    textCondition,
                    t("fligth.configure_flight.laptop"),
                    t("fligth.configure_flight.laptop_or_handbag"),
                    t("fligth.configure_flight.personal_item")
                  );
                  const bagBig = contienePalabras(
                    textCondition,
                    t("fligth.configure_flight.1st_checked_bag"),
                    t("fligth.configure_flight.first_excess_bag"),
                    t("fligth.configure_flight.bigbag"),
                    t("fligth.configure_flight.upto50lb"),
                    t("fligth.configure_flight.20_kg"),
                    t("fligth.configure_flight.first_bag_23k"),
                    t("fligth.configure_flight.upto55lb"),
                    t("fligth.configure_flight.first_checked_baggage"),
                    t("fligth.configure_flight.first_checked_bag"),
                    t("fligth.configure_flight.1_checked_bag_up_to_23kg")
                  );
                  const extraBag = contienePalabras(
                    textCondition,
                    t("fligth.configure_flight.2nd_checked_bag"),
                    t("fligth.configure_flight.second_excess_bag"),
                    t("fligth.configure_flight.2_checked_bags_up_to_23kg_each"),
                    t("fligth.configure_flight.second_bag_23k"),
                    t("fligth.configure_flight.second_checked_baggage"),
                    t("fligth.configure_flight.second_checked_bag")
                  );
                  const megaBig = contienePalabras(
                    textCondition,
                    t("fligth.configure_flight.3rd_checked_bag"),
                    t("fligth.configure_flight.third_bag_23kg"),
                    t("fligth.configure_flight.third_checked_bag"),
                    t("fligth.configure_flight.1_checked_bag_up_to_32kg"),
                    t("fligth.configure_flight.2_checked_bags_up_to_32kg_each")
                  );

                  const typeBag = isCarry
                    ? iconList.IconBagSmall
                    : bagBig
                      ? iconList.IconBagMedium
                      : extraBag
                        ? iconList.IconBagBig
                        : megaBig
                          ? iconList.IconExtraBag
                          : "";

                  return {
                    key,
                    isLaptop,
                    isCarry,
                    bagBig,
                    extraBag,
                    megaBig,
                    bagAvailable,
                    typeBag,
                  };
                }
              );

              const sortedItems = items?.sort((a, b) => {
                if (a?.isLaptop) return -1;
                if (b?.isLaptop) return 1;
                if (a?.isCarry) return -1;
                if (b?.isCarry) return 1;
                if (a?.bagBig) return -1;
                if (b?.bagBig) return 1;
                if (a?.extraBag) return -1;
                if (b?.extraBag) return 1;
                if (a?.megaBig) return -1;
                if (b?.megaBig) return 1;
                return 0;
              });

              return (
                <BagComponent
                  value={value}
                  allPassengersSelected={allPassengersSelected}
                  getChoose={getChoose}
                  index={index}
                  key={key}
                  key1={key + 1}
                  isSelected={isSelected}
                  position={position}
                  setLocalTarifaLast={setLocalTarifaLast}
                  sortedItems={sortedItems}
                  typeOfCoin={typeOfCoin}
                  adultPrice={adultPrice}
                  babyPrice={babyPrice}
                  boyPrice={boyPrice}
                  detailsType={detailsType}
                  toLowerCaset={toLowerCaset}
                  arrowServices={arrowServices}
                  setArrowServices={setArrowServices}
                  setSelectedValue={setSelectedValue}
                  setValidateSelecteds={setValidateSelecteds}
                  total={price.totalAmount}
                  activeTrip={activeTrip}
                  valueGoing={valueGoing}
                  setValueGoing={setValueGoing}
                  company={company}
                  airlines={airlines}
                />
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
};

const UpsellQueryEmpty = ({ clear, isEmpty, isLoading, openUpsell }) => {
  const [t] = useTranslation("global");

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      clear();
    }
  };

  return (
    <div
      className={` ${!isLoading && openUpsell && isEmpty
        ? "upsellSectionEmpty"
        : "upsellSectionEmpty close"
        }`}
      onClick={handleClick}
    >
      <div
        className={` ${!isLoading && openUpsell && isEmpty
          ? "upsellSectionEmptyContent"
          : "upsellSectionEmptyContent close"
          }`}
      >
        <div className="upsellSectionEmptyHead">
          <div className="upsellSectionEmptyHeadLeft">
            <Warning2 size="40" color="#104172" />
            <h3 className="upsellSectionEmptyHeadTitle">
              {t("fligth.upsell_choose.sorry_moment")}
            </h3>
          </div>

          <button onClick={clear} className="upsellContentModalClose">
            <MdClose size={25} />
          </button>
        </div>
        <div className="upsellSectionEmptyBody">
          <div className={"upsellSectionEmptyBodyGroup"}>
            <div className={"upsellSectionEmptyBodyGroupVisible"}>
              <p className="upsellSectionEmptyBodyGroupVisibleText">
                {t("fligth.configure_flight.recommendations")}
              </p>
            </div>
            <div className="upsellSectionEmptyBodyGroupHidden">
              <p className="upsellSectionEmptyBodyGroupHiddenText">
                {t("fligth.configure_flight.wanna_modify")}{" "}
                <a
                  href="https://wa.me/573004040116"
                  target="_blank"
                  className="a-link-contact"
                  rel="noreferrer"
                >
                  3004040116
                </a>
              </p>
            </div>
          </div>

          <div className="upsellSectionEmptyBodyGroupButtonContinue">
            <button onClick={handleClick} className="upsellSectionButtonContinue">{t("fligth.configure_flight.select_other_flight")}</button>
          </div>
        </div>


      </div>
    </div>
  );
};

const UpsellQuery = ({ isLoading, isOpen, open, close }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      {isLoading && <Loading size={90} />}

      <button
        className="btnConfigFlight"
        onClick={open}
        disabled={isLoading}
        style={{ opacity: isLoading ? 0.5 : 1 }}
      >
        {isLoading ? (
          <div className="loadConfigFlight">
            <LuLoader2 size={20} />
          </div>
        ) : (
          t("fligth.baggage.flight_config")
        )}
      </button>
    </>
  );
};
