import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import axios from "axios";
import { Slider, Tooltip } from "@mui/material";
import { TiArrowSortedDown } from "react-icons/ti";
import TooltipContainer from "@/ui/views/share/components/tooltipContainer";
import { useTranslation } from "react-i18next";


const Filter = ({
  findSpecialist,
  setCity,
  setProcedure,
  city,
  procedure,
  setModal,
  seeFilter,
  specialist,
  priceMax,
  priceMin,
  handleDoubleRangeChange,
  setRange,
  range,
  handleDoubleRangeChangeCommitted,
  filterPerCity,
  filterPerProcedure,
  filterPerSpecialist,
  speciality,
  setSpeciality,
  filterReset
}) => {
  const { t } = useTranslation("global");
  const [cities, setCities] = useState([]);
  const inputRef = useRef(null);
  const inputEsRef = useRef(null);
  const [procedures, setProcedures] = useState([])
  const [proceduresCopy, setProceduresCopy] = useState([])
  const [specialities, setSpecialities] = useState([])
  const [specialitiesCopy, setSpecialitiesCopy] = useState([])
  const [marksPrice, setMarksPrice] = useState([priceMin, priceMax]);
  const [firstRender, setFirstRender] = useState(false);
  const [showProcedures, setShowProcedures] = useState(false)
  const [showEspecialty, setShowEspecialty] = useState(false)
  const [especialtiesSearch, setEspecialtiesSearch] = useState([]);
  const [procedureSearch, setProcedureSearch] = useState([]);
  const [loading, setLoading] = useState(false)

  const specialtiesRef = useRef(null);
  const procedureRef = useRef(null);
  const [cardEspecialties, setCardEspecialties] = useState(false);
  const [cardProcedure, setCardProcedure] = useState(false);

  const selectSpeRef = useRef(null);
  const selectProRef = useRef(null);
  const selectCitRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      // Si el clic ocurrió fuera del TooltipContainer
      if (specialtiesRef.current && !specialtiesRef.current.contains(event.target)) {
        setShowEspecialty(false);
        setCardProcedure(false)
      }
    }

    function handleClickOutside2(event) {
      // Si el clic ocurrió fuera del TooltipContainer
      if (procedureRef.current && !procedureRef.current.contains(event.target)) {
        setShowProcedures(false);
        setCardEspecialties(false)
      }
    }

    // Añadir el event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutside2);
    
    // Limpiar el event listener al desmontar el componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutside2);
    };
  }, [specialtiesRef,procedureRef]);

  const resetSelect = () => {
    if (selectSpeRef.current) {
      selectSpeRef.current.value = ""; // Restablece el select a la primera opción
    }
    if (selectProRef.current) {
      selectProRef.current.value = ""; // Restablece el select a la primera opción
    }
    if (selectCitRef.current) {
      selectCitRef.current.value = ""; // Restablece el select a la primera opción
    }
    filterReset()
    setModal(false); // También puedes realizar otras acciones aquí
  };

  function valuetext(value) {
    return `${"$" + value}`;
  }

  const formatCOP = (value) => {
    if (value >= 1000000) {
      return "$ " + (value / 1000000).toFixed(2) + " M";
    } else {
      return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumFractionDigits: 0, // Elimina los decimales
      }).format(value);
    }
  };

  const marks = [
    {
      value: priceMin,
      label: formatCOP(marksPrice[0]) + " COP",
    },
    {
      value: priceMax,
      label: formatCOP(marksPrice[1]) + " COP",
    },
  ];

  useEffect(() => {
    const url = `${process.env.REACT_APP_URL_API_FINANMED}`;
    axios
      .get(`${url}/list/ciudades`)
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get(`${url}/category`)
      .then((res) => {
        setSpecialities(res.data);
        setSpecialitiesCopy(res.data);
      })
      .catch((err) => {
        console.error(err);
      });


  }, []);


  useEffect(() => {
    if (firstRender) {
      setMarksPrice([range[0], range[1]]);
    } else {
      setFirstRender(true);
    }
  }, [range]);


  useEffect(() => {
    const url = `${process.env.REACT_APP_URL_API_FINANMED}`;

    if (speciality.name) {
      setLoading(true)
      axios
        .get(`${url}/category/sub/${speciality.id}`)
        .then((res) => {
          setProcedures(res.data);
          setEspecialtiesSearch(res.data)
          setLoading(false)
          setProceduresCopy(res.data);
        })
        .catch((err) => {
          console.error(err);
        });



    }
    if (speciality.name == "") {
      setSpecialities(specialitiesCopy)
    } else {
      const newSpecialties = specialitiesCopy.filter((especialty) =>
        especialty?.name && especialty?.name?.toLowerCase().includes(speciality?.name?.toLowerCase()))
      setSpecialities(newSpecialties)
    }

  }, [speciality])

  // Functions
  const handleClick = () => {
    if (speciality) {
      setProcedure({
        name:""
      });
      setShowProcedures(true);
      setCardEspecialties(true);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }


  };


  const handleClickSpecialty = () => {
    setSpeciality({
      name:""
    })
    setShowEspecialty(true)
    setCardProcedure(true)
    if (inputEsRef.current) {
      inputEsRef.current.focus();
    }

  }


  useEffect(() => {
    if (procedure.name === "") {
      setEspecialtiesSearch(procedures);
    } else {
      setEspecialtiesSearch(procedures?.filter((especialty) =>
        especialty?.name && especialty?.name?.toLowerCase().includes(procedure?.name?.toLowerCase())
      ));
    }
  }, [procedure]);


  return (
    <div className={styles.contain_filters}>
      <div className={styles.filters}>
        <h2>{t("build_package.filters.title")}</h2>
        
      </div>

      <div className={styles.filters}>
        <h2>{t("build_package.filters.specialty.text")}</h2>
        <span className={styles.subtitles}>{t("build_package.filters.specialty.description")}</span>
        <div className="searchBuildPackageContentInput" onClick={handleClickSpecialty} style={{ padding: ".4rem", borderRadius: "5px" }} ref={specialtiesRef}>
          <div className="searchBuildPackageContentInputContent">
            <input
              type="text"
              style={{ padding: "0", border: "none" }}
              placeholder={showEspecialty ? '' : t("build_package.filters.specialty.placeholder") }
              ref={inputEsRef}
              value={speciality?.name}
              onChange={(e) => {
                setSpeciality(prevState => ({
                  ...prevState,  // Mantener todos los valores anteriores
                  name: e.target.value  // Actualizar solo el campo 'name'
                }))
              }}
            />
          </div>
          <TiArrowSortedDown size={20} color="var(--bluePrimary)" />
          {
            cardProcedure && (
              <TooltipContainer
                show={showEspecialty}
                hidden={setShowEspecialty}
                sizeWidth={100}
                sizeHeight={80}
              >
                <ul className="contentElemetsSearchInputBuildPackage">
                  {
                    specialities.length === 0 ? (
                      <li className="list-not-results">
                        {t("build_package.filters.without_results")}
                      </li>
                    ) : (
                      specialities.map((especialtieItem, index) => (
                        <Tooltip title={especialtieItem.name} arrow placement="right">
                          <li key={index} onClick={(e) => {
                            e.stopPropagation();
                            setSpeciality(especialtieItem);
                            filterPerSpecialist(especialtieItem.id)
                            setShowEspecialty(false);
                            setCardProcedure(false);
                          }}>
                            <span>{especialtieItem.name}</span>
                          </li>
                        </Tooltip>
                      ))
                    )
                  }
                </ul>
              </TooltipContainer>
            )
          }
        </div>
      </div>

      <div className={`${styles.filters} ${speciality.name && !loading ? "" : styles.disable}`} >
        <h2>{t("build_package.filters.procedure.text")}</h2>
        <span className={styles.subtitles}>{t("build_package.filters.procedure.description")}</span>
        <div className="searchBuildPackageContentInput" onClick={handleClick} style={{ padding: ".4rem", borderRadius: "5px" }} ref={procedureRef}>
          <div className="searchBuildPackageContentInputContent">
            <input
              type="text"
              style={{ padding: "0", border: "none" }}
              placeholder={showProcedures ? '' : t("build_package.filters.procedure.placeholder")}
              ref={inputRef}
              value={procedure?.name}
              onChange={(e) => {
                setProcedure(prevState => ({
                  ...prevState,  // Mantener todos los valores anteriores
                  name: e.target.value  // Actualizar solo el campo 'name'
                }))
              }}
            />
          </div>
          <TiArrowSortedDown size={20} color="var(--bluePrimary)" />
          {
            cardEspecialties && (
              <TooltipContainer
                show={showProcedures}
                hidden={setShowProcedures}
                sizeWidth={100}
                sizeHeight={80}
              >
                <ul className="contentElemetsSearchInputBuildPackage">
                  {
                    especialtiesSearch.length === 0 ? (
                      <li className="list-not-results">
                        {t("build_package.filters.without_results")}
                      </li>
                    ) : (
                      especialtiesSearch.map((especialtieItem, index) => (
                        <Tooltip title={especialtieItem.name} arrow placement="right">
                          <li key={index} onClick={(e) => {
                            e.stopPropagation();
                            setProcedure(especialtieItem);
                            filterPerProcedure(especialtieItem.id)
                            setShowProcedures(false);
                            setCardEspecialties(false);
                          }}>
                            <span>{especialtieItem.name}</span>
                          </li>
                        </Tooltip>
                      ))
                    )
                  }
                </ul>
              </TooltipContainer>
            )
          }
        </div>
      </div>

      <div className={styles.filters}>
        <h2>{t("build_package.filters.price.text")}</h2>
        <span className={styles.subtitles}>{t("build_package.filters.price.description")}</span>
        <Slider
          getAriaLabel={() => "Temperature range"}
          value={range}
          onChange={handleDoubleRangeChange}
          onChangeCommitted={handleDoubleRangeChangeCommitted}
          max={priceMax}
          min={priceMin}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          marks={marks}
          sx={{
            '& .MuiSlider-markLabel': {
              fontSize: '10px', // Ajustar el tamaño de los labels a 10px
              fontWeight: "600",
              color: 'var(--bluePrimary)'
            },
            color: 'var(--bluePrimary)', // Usar la variable CSS para el color principal
            '& .MuiSlider-thumb': {
              backgroundColor: 'var(--bluePrimary)', // Usar la variable para el thumb
              width: "15px",
              height: "15px",
            },
            '& .MuiSlider-markLabel[data-index="0"]': {
              left: '15% !important', // Ajuste de la posición para el index 0
            },
            '& .MuiSlider-markLabel[data-index="1"]': {
              left: '85% !important', // Ajuste de la posición para el index 1
            },
            '& .MuiSlider-rail': {
              color: '#bdbdbd', // Puedes dejar este color o personalizarlo
            },
            '& .MuiSlider-track': {
              color: 'var(--bluePrimary)', // Usar la variable para la pista activa
            },
          }}
        />
      </div>

      <button
          onClick={() => {
            setProcedure({
              name:""
            });
            setCity("");
            setSpeciality({
              name:""
            })
            // filterPerCity("")
            setCardEspecialties(false)
            setShowProcedures(false)
            setShowEspecialty(false)
            setCardProcedure(false)
            setRange([priceMin, priceMax])
            setModal(false);
            resetSelect()
          }}
        >
          {t("build_package.filters.button_text")}
        </button>


    </div>
  )
}

export default Filter