import React from 'react';
import { IoClose } from 'react-icons/io5';
import styles from "./styles.module.css"
import { useTranslation } from 'react-i18next';

const ClinicFilters = ({ searchTerm, setSearchTerm, handleClear, handleSearch }) => {
    const { t } = useTranslation("global");

    return (
        <div className={styles.contain_filters}>
            <div className={styles.filters}>
                <h2>{t("build_package.steps.clinic.filters")}</h2>
            </div>
            <div className={styles.contentFilter}>
                <div className={styles.filters}>
                    <h2>{t("build_package.steps.clinic.name")}</h2>
                    <span>{t("build_package.steps.clinic.filter_name")}</span>
                    <div className={styles.inputClose}>
                        <input
                            type="text"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            value={searchTerm}
                            placeholder={t("build_package.steps.clinic.name_placeholder")}
                        />
                        <button className={styles.closeButton} onClick={handleClear}>
                            <IoClose size={18} />
                        </button>
                    </div>
                </div>
            </div>
            <button className={styles.buttonSearch} onClick={handleSearch}>
                {t("build_package.steps.clinic.search")}
            </button>
        </div>
    );
};

export default ClinicFilters;