import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./disabledModal.css";
import { FiAlertTriangle } from "react-icons/fi";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

const Modal = ({ show, onClose, children }) => {
  const modalRoot = document.createElement("div");
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    document.body.appendChild(modalRoot);
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.removeChild(modalRoot);
      document.body.style.overflow = "auto";
    };
  }, [modalRoot, show]);

  const handleClickClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 500);
  };

  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={`AlertInfoContentModal ${show && !isClosing ? "fadeInBackground" : "fadeOutBackground"
        }`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          handleClickClose()
        }
      }}
    >
      <div
        className={`AlertInfoContentModalContent ${show && !isClosing ? "fadeIn" : "fadeOut"
          }`}
      >
        <button
          className="AlertInfoContentModalContentClose"
          onClick={() => handleClickClose()}
        >
          <MdClose size={20} color="#104172" />
        </button>
        <div className="AlertInfoContentModalContentInfo">{children}</div>
      </div>
    </div>,

    modalRoot
  );
};

const DisabledModal = ({
  item,
  children,
  active = true,
  width = "100%",
  color = "",
  linkExit = false,
  center,
}) => {
  const { t } = useTranslation("global");
  const [openModalFlight, setOpenModalFlight] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = (e) => {
    e.preventDefault();
    if (!item.disable) {
      setOpenModalFlight(true);
    }
  };

  const redirect = () => {
    if (linkExit && item.disable) {
      window.open(item.route, "_blank");
    } else if (item.disable && active) {
      navigate(item.route);
    }
  };

  return (
    <>
      <div
        onClick={handleOpenModal}
        style={{
          cursor: item.disable ? "pointer" : "not-allowed",
          width: width,
          height: "100%",
        }}
        className="contentpo"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span
          onClick={redirect}
          className={`iconLinkp ${isHovered ? "colorBlue" : ""}`}
          style={{
            cursor: item.disable === true ? "pointer" : "not-allowed",
            opacity: !item.disable ? "0.5" : 1,
            alignItems: center && "start",
          }}
        >
          {children}
        </span>
      </div>

      <Modal show={openModalFlight} onClose={() => setOpenModalFlight(false)}>
        <div className="iconAlertModalDevelop">
          <FiAlertTriangle size={25} color="#ff5b5b98" />
        </div>
        <h2 className="AlertInfoContentModalContentTitle">{t("underdevelopment.title")}</h2>
        <span className="AlertInfoContentModalContentDesc">
          {t("underdevelopment.first_description")} <br />
          {t("underdevelopment.second_description")}
        </span>
      </Modal>
    </>
  );
};

export default DisabledModal;
