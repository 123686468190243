import React, { useState, useRef, useCallback } from "react";
import "./styles.css";
import styles from "./styles.module.css"
import PrimarySection from "./components/primarySection";
import CardRegisterNow from "./components/cardRegisterNow";
import AnyQuestions from "./components/anyQuestion";
import CarouselSevices from "../share/components/CarouselServices/CarouselSevices";
import CountDownTimer from "./components/CountDown/CountDownTime";
import NewsWellezy from "../../../Components/NewsWellezy/NewsWellezy";
import { useTranslation } from "react-i18next";
import ShopWellezy from "./components/shopWellezy/ShopWellezy";
import { useLocation } from "react-router-dom";
import SliderOferts from "./components/SliderOferts/SliderOferts";
import SvgIcon, { iconList } from "../share/components/iconSVG";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Grid, Modal } from "@mui/material";
import { Link } from "react-router-dom";
import { widgetsList } from "../../../infra/constants/app";
import CardPackageTurism from "../../../assets/images/home/cardPackageTurism.jpg";
import CardPackageTurism2 from "../../../assets/images/home/cardPackageTurism2.jpg";
import CardPackageTurism3 from "../../../assets/images/home/cardPackageTurism3.jpg";
import { useNavigate } from "react-router-dom";

import CardBannerPackageSalud from "../../../assets/images/home/turismSalud/cardTurismSalud.jpg";
import CardPackageSalud1 from "../../../assets/images/home/turismSalud/cardTurismSalud1.jpg";
import CardPackageSalud2 from "../../../assets/images/home/turismSalud/cardTurismSalud2.jpg";
import CardPackageSalud3 from "../../../assets/images/home/turismSalud/cardTurismSalud3.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useEffect } from "react";
import NeedInformation from "../tourismHealth/needInformation/NeedInformation";
import question from "./question.svg";
import earphones from "./earphones.svg";
import TextComponent from "../share/components/fonts/TextComponent";
import ContainerSeparations from "../../../Components/ContainerSeparations/ContainerSepartaion";
import "./modalStyles.css";
import { CloseCircle } from "iconsax-react";
import DisabledModal from "../share/components/disabledModal/disabledModal";
import { IoClose } from "react-icons/io5";
import { MdClose } from "react-icons/md";

function Home() {
  const fechaLanzamiento = new Date("2023-05-29T17:22:00");
  const { pathname } = useLocation();
  const cardsCa = useRef(null);
  const cardsCaR = useRef(null);
  const [t] = useTranslation("global");
  const [activeCard, setActiveCard] = useState(1); // Estado para la tarjeta activa
  const [activeCardTourism, setActiveCardTourism] = useState(1); // Estado para la tarjeta activa
  const fechaActual = new Date();
  const [modalOption, setModalOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const [lanzamiento, setLanzamiento] = useState(
    fechaLanzamiento > fechaActual ? false : true
  );

  const btns = {
    need: {
      title: t("home.need_information.title"),
      textButton: t("home.need_information.button_text"),
      description: t("home.need_information.description"),
      img: question,
    },
    agent: {
      title: t("home.agent_purchase.title"),
      textButton: t("home.agent_purchase.button_text"),
      description:
        t("home.agent_purchase.description"),
      img: earphones,
    },
  };

  const [isActive, setIsActive] = useState(false);
  const body = document.querySelector("body");
  const [bodyWidth, setBodyWidth] = useState(document.body.offsetWidth);

  const opcionInfo = {
    doc: {
      title: "Especialistas",
      description:
        "En Wellezy, nos enorgullece ofrecerte un amplio catálogo de especialistas médicos altamente calificados, listos para brindarte la mejor atención en salud. Ya sea que necesites una consulta general, un tratamiento especializado o simplemente asesoramiento, nuestros expertos están aquí para ayudarte.Con Wellezy, tienes acceso a servicios de salud de calidad de manera fácil y conveniente. Nuestro compromiso es tu bienestar, y estamos aquí para asegurarnos de que recibas el cuidado que mereces.",
    },
    cli: {
      title: "Clinicas",
      description:
        "En Wellezy, te ofrecemos un extenso catálogo de clínicas reconocidas, disponibles para brindarte servicios de salud de la más alta calidad. Ya sea que necesites una consulta, un procedimiento médico o tratamientos especializados, nuestras clínicas asociadas están equipadas para atender tus necesidades.Accede de manera fácil y rápida a los mejores servicios de salud con Wellezy. Nuestro compromiso es tu bienestar, y estamos dedicados a ofrecerte la mejor atención médica.",
    },
    proc: {
      title: "¿Necesitas algun procedimiento?",
      description:
        "En Wellezy, contamos con un amplio catálogo de procedimientos médicos diseñados para cubrir todas tus necesidades de salud. Ya sea que busques tratamientos especializados o cuidados preventivos, tenemos la solución perfecta para ti. Además, nuestros asesores están siempre a tu disposición para responder tus dudas y brindarte sugerencias personalizadas. Con Wellezy, recibir atención de calidad es fácil y accesible.",
    },
  };

  function activeChangeCards(classS, active) {
    const cardsSlides = document.querySelectorAll(`${classS} .slick-slide`);
    const cardSlideTrack = document.querySelector(`${classS} .slick-track`);
    cardsSlides.forEach((item, index) => {
      if (index + 1 === active) {
        item.classList.add("active-slide");
        item.classList.remove("inactive-slide");
        if (active === 3) {
          if (body.offsetWidth < 1100 && body.offsetWidth > 900) {
            cardSlideTrack.style.transform = "translate3d(-300px, 0px, 0px)";
          } else if (body.offsetWidth < 900 && body.offsetWidth > 700) {
            cardSlideTrack.style.transform = "translate3d(-200px, 0px, 0px)";
          }
        } else if (active === 2) {
          if (body.offsetWidth < 1100 && body.offsetWidth > 900) {
            cardSlideTrack.style.transform = "translate3d(-200px, 0px, 0px)";
          } else if (body.offsetWidth < 900 && body.offsetWidth > 700) {
            cardSlideTrack.style.transform = "translate3d(-100px, 0px, 0px)";
          }
        } else {
          cardSlideTrack.style.transform = "translate3d(0px, 0px, 0px)";
        }
      } else {
        item.classList.add("inactive-slide");
        item.classList.remove("active-slide");
      }
    });
  }

  useEffect(() => {
    const handleResize = () => {
      setBodyWidth(document.body.offsetWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    activeChangeCards(".turismSaludCards", activeCardTourism);
    activeChangeCards(".homeSectionDoubleCardWrap", activeCard);
  }, [bodyWidth]);

  useEffect(() => {
    activeChangeCards(".homeSectionDoubleCardWrap", activeCard);
  }, [activeCard]);

  useEffect(() => {
    activeChangeCards(".turismSaludCards", activeCardTourism);
  }, [activeCardTourism]);

  const cards = {
    card_1: {
      url: "/packages",
      typeUser: "serviceProvider",
      img: CardPackageTurism,
      title: t("browse_home.cards_packages.card_1.title"),
      description: t("browse_home.cards_packages.card_1.description"),
      button: t("browse_home.cards_packages.card_1.button"),
      more_info: t("browse_home.cards_packages.card_1.more_info"),
      action: () => { },
    },

    card_2: {
      url: "/packagesType?type=colombia",
      typeUser: "customer",
      img: CardPackageTurism2,
      title: t("browse_home.cards_packages.card_2.title"),
      description: t("browse_home.cards_packages.card_2.description"),
      button: t("browse_home.cards_packages.card_2.button"),
      more_info: t("browse_home.cards_packages.card_2.more_info"),
      action: () => { },
    },
    card_3: {
      url: "/packagesType?type=internacional",
      typeUser: "customer",
      img: CardPackageTurism3,
      title: t("browse_home.cards_packages.card_3.title"),
      description: t("browse_home.cards_packages.card_3.description"),
      button: t("browse_home.cards_packages.card_3.button"),
      more_info: t("browse_home.cards_packages.card_3.more_info"),
      action: () => { },
    },
  };

  const cardsTurism = {
    card_1: {
      url: "/Tourismhealth",
      typeUser: "serviceProvider",
      img: CardPackageSalud1,
      title: t("browse_home.cards_packages_turism.card_1.title"),
      description: t("browse_home.cards_packages_turism.card_1.description"),
      button: t("browse_home.cards_packages_turism.card_1.button"),
      more_info: t("browse_home.cards_packages_turism.card_1.more_info"),
      action: () => { },
    },

    card_2: {
      url: "/Specialists",
      typeUser: "customer",
      img: CardPackageSalud2,
      title: t("browse_home.cards_packages_turism.card_2.title"),
      description: t("browse_home.cards_packages_turism.card_2.description"),
      button: t("browse_home.cards_packages_turism.card_2.button"),
      more_info: t("browse_home.cards_packages_turism.card_2.more_info"),
      action: () => { },
    },
    card_3: {
      url: "/Clinics",
      typeUser: "customer",
      img: CardPackageSalud3,
      title: t("browse_home.cards_packages_turism.card_3.title"),
      description: t("browse_home.cards_packages_turism.card_3.description"),
      button: t("browse_home.cards_packages_turism.card_3.button"),
      more_info: t("browse_home.cards_packages_turism.card_3.more_info"),
      action: () => { },
    },
  };

  const [showOnlyThis, setShowOnlyThis] = useState([]);
  const MotionBox = styled(motion.div)`
    width: 100vw;
  `;

  const constraintsRef = useRef(null);

  function addWidgetURL(url) {
    const isWidget = widgetsList.find(
      (w) => w.path === window.location.pathname
    );
    return isWidget ? `/widget${url}` : url;
  }

  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current) => handleAfterChange(current),
    responsive: [
      {
        breakpoint: 1354,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  const handleAfterChange = (current) => {
    setActiveCard(current + 1);
    setActiveCardTourism(current + 1);
  };

  const servicesTravel = [
    {
      id: "fligths",
      title: t("carousel_services.fligth"),
      img: iconList.IconPlane,
      route: addWidgetURL("/fligths"),
    },
    {
      id: "hotel",
      title: t("carousel_services.hotel"),
      img: iconList.IconHotel,
      route: addWidgetURL("/hotel"),
    },
    {
      id: "tours",
      title: t("carousel_services.tours"),
      img: iconList.IconBag,
      route: addWidgetURL("/tours"),
    },
    {
      id: "transport",
      title: t("carousel_services.transfers"),
      img: iconList.IconTaxi,
      route: addWidgetURL("/transport"),
    },
    {
      id: "carRentals",
      title: t("carousel_services.car_rental"),
      img: iconList.IconRentaCar,
      route: addWidgetURL("/carRentals"),
    },
    {
      id: "packages",
      title: t("carousel_services.packages"),
      img: iconList.IconTravel,
      route: addWidgetURL("/packages"),
    },
  ];

  const servicesTravelList =
    showOnlyThis && showOnlyThis.length > 0
      ? servicesTravel.filter((route) => showOnlyThis.includes(route.id))
      : servicesTravel;

  const handleClick = (type, setCard) => {
    setCard(type); // Cambia la tarjeta activa cuando se hace clic
  };

  const [loadingImage, setLoadingImage] = useState(true);

  const handleActive = useCallback((value) => {
    setLoadingImage(true);
    setIsActive((prev) => {
      // Solo actualizar si el valor es diferente
      if (prev !== value) {
        return value;
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (cardsCa.current) {
        const slickTrack =
          cardsCa.current.innerSlider?.list.querySelector(".slick-track");
        if (slickTrack) {
          slickTrack.style.transform = "translate3d(0px, 0px, 0px)";
        }
      }
    };
    const handleResizeR = () => {
      if (cardsCaR.current) {
        const slickTrack =
          cardsCaR.current.innerSlider?.list.querySelector(".slick-track");
        if (slickTrack) {
          slickTrack.style.transform = "translate3d(0px, 0px, 0px)";
        }
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleResizeR);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleResizeR);
    };
  }, []);

  const [show, setShow] = useState(false);

  return (
    <div className="homeContainer">
      {lanzamiento === true ? (
        <>
          <section className="homeSectionCarrousel">
            {/* <p className="homeSectionCarrouselTitle">
              {t("browse_home.find_wellezy")}
            </p> */}
            <CarouselSevices />
          </section>
          <PrimarySection pathname={pathname} />

          <ContainerSeparations height={40} />

          <div
            className={`${modalOption ? "ModalChange" : "ModalChange close"}`}
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                setModalOption(false);
              }
            }}
          >
            <div
              className={`${modalOption ? "ModalChangeContent" : "ModalChangeContent close"
                }`}
            >
              <button
                className="ModalChangeCloseButton"
                onClick={() => setModalOption(false)}
              >
                <MdClose size={23} color="#104172" />
              </button>

              <div
                className="content-modal-card-title"
                style={{ border: "none", gap: 20 }}
              >
                <h2 style={{ fontSize: 24 }}>{opcionInfo?.[`${selectedOption}`]?.title}</h2>
                <p>{opcionInfo?.[`${selectedOption}`]?.description}</p>
              </div>
            </div>
          </div>



          <section className="homeSectionDoubleCardWrap">
            <Slider {...settings} ref={cardsCa}>
              <CardRegisterNow
                icons={true}
                type={cards.card_1}
                disable={true}
                url={cards.card_1.url}
                active={activeCard === 1}
                onClick={() => handleClick(1, setActiveCard)}
              />
              <CardRegisterNow
                icons={false}
                type={cards.card_2}
                url={cards.card_2.url}
                disable={true}
                active={activeCard === 2}
                onClick={() => handleClick(2, setActiveCard)}
              />
              <CardRegisterNow
                icons={false}
                type={cards.card_3}
                url={cards.card_3.url}
                active={activeCard === 3}
                disable={true}
                onClick={() => handleClick(3, setActiveCard)}
              />
            </Slider>
          </section>

          <ContainerSeparations height={40} />

          <div
            className="layout-need-information-tourism-health"
            style={{ margin: "0 auto" }}
          >
            <NeedInformation
              description={btns.need.description}
              img={btns.need.img}
              title={btns.need.title}
              textButton={btns.need.textButton}
              color={"#104172"}
              active={true}
              url={"/contact"}
              linkExit={true}
            />
          </div>


          <ContainerSeparations height={40} />

          <section className="turismSalud">
            <div className="turismSaludContain" style={{ margin: "0 auto" }}>
              <TextComponent
                children={t("browse_home.health_tourism.title")}
                className={"turismSaludTitle"}
                size={"xl"}
                fontWeight={"bold"}
              />
              <div className="turismSaludInfo">
                <div className={`cardTurismSaludNow`}>
                  <div className="cardTurismSaludNowImgActive">
                    <img src={CardBannerPackageSalud} alt="Image" />
                  </div>
                  <div className="cardTurismSaludContentActive">
                    <h2 className="cardTurismSaludNowTitleActive">
                      <ul>
                        <li>{t("browse_home.health_tourism.content.viaja")}</li>
                        <li>
                          {t("browse_home.health_tourism.content.mejora")}
                        </li>
                        <li>
                          {t("browse_home.health_tourism.content.disfruta")}
                        </li>
                      </ul>
                    </h2>
                  </div>

                  <div className="cardTurismSaludNowButtonContain">
                    <div className="cardTurismSaludHeader">
                      <TextComponent
                        children={t("browse_home.health_tourism.description")}
                        className={"cardTurismSaludNowDescriptionActive"}
                        size={"md"}
                        fontWeight={"light"}
                      />

                      <TextComponent
                        children={t("browse_home.health_tourism.semi_title")}
                        className={"cardTurismSaludNowSemiTitleActive"}
                        size={"lg"}
                        fontWeight={"bold"}
                      />
                    </div>
                    <button className="cardTurismSaludNowButtonLink">
                      <div className="cardTurismSaludNowClipBorder">
                        <a
                          href="/Tourismhealth"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          {t("browse_home.health_tourism.button")}
                        </a>
                      </div>
                    </button>
                  </div>
                </div>

                <ul className="turismSaludListButtons">
                  <li
                    onClick={() => {
                      navigate('/Specialists');
                    }}
                  >
                    <span className={`turismSaludButtonLink ${styles.link}`}>
                      {t("home.wellezy_benefits.specialist.title")}
                      <TextComponent
                        size={"sm"}
                        children={
                          t("home.wellezy_benefits.specialist.description")
                        }
                      />
                    </span>
                  </li>

                  <li
                    onClick={() => {
                      navigate('/Clinics');
                    }}
                  >
                    <span className={`turismSaludButtonLink ${styles.link}`}>
                      {t("home.wellezy_benefits.clinic.title")}
                      <TextComponent
                        size={"sm"}
                        children={
                          t("home.wellezy_benefits.clinic.description")
                        }
                      />
                    </span>
                  </li>

                  <li
                    onClick={() => {
                      navigate('/build-package');
                    }}
                  >
                    <span className={`turismSaludButtonLink ${styles.link}`}>
                      {t("home.wellezy_benefits.build_package.title")}
                      <TextComponent
                        size={"sm"}
                        children={
                          t("home.wellezy_benefits.build_package.description")
                        }
                      />
                    </span>
                  </li>

                  <li
                    onClick={() => {
                      navigate('/Tourismhealth');
                    }}
                  >
                    <span className={`turismSaludButtonLink ${styles.link}`}>
                      {t("home.wellezy_benefits.tourism_health.title")}
                      <TextComponent
                        size={"sm"}
                        children={
                          t("home.wellezy_benefits.tourism_health.description")
                        }
                      />
                    </span>
                  </li>

                  <li
                    onClick={() => {
                      navigate('/pqrs');
                    }}
                  >
                    <span className={`turismSaludButtonLink ${styles.link}`}>
                      {t("home.wellezy_benefits.pqrs.title")}
                      <TextComponent
                        size={"sm"}
                        children={
                          t("home.wellezy_benefits.pqrs.description")
                        }
                      />
                    </span>
                  </li>


                </ul>
              </div>

              <div className="turismSaludCards">
                <Slider {...settings} ref={cardsCaR}>
                  <CardRegisterNow
                    backgroundColor="#00AFE8"
                    colorButton="#104172"
                    backgroundColorDesactive="var(--blueLightTransparent)"
                    type={cardsTurism.card_1}
                    url={cardsTurism.card_1.url}
                    active={activeCardTourism === 1}
                    disable={true}
                    onClick={() => handleClick(1, setActiveCardTourism)}
                  />
                  <CardRegisterNow
                    backgroundColor="#00AFE8"
                    colorButton="#104172"
                    type={cardsTurism.card_2}
                    backgroundColorDesactive="var(--blueLightTransparent)"
                    url={cardsTurism.card_2.url}
                    active={activeCardTourism === 2}
                    disable={true}
                    onClick={() => handleClick(2, setActiveCardTourism)}
                  />
                  <CardRegisterNow
                    backgroundColor="#00AFE8"
                    colorButton="#104172"
                    type={cardsTurism.card_3}
                    backgroundColorDesactive="var(--blueLightTransparent)"
                    url={cardsTurism.card_3.url}
                    active={activeCardTourism === 3}
                    disable={true}
                    onClick={() => handleClick(3, setActiveCardTourism)}
                  />
                </Slider>
              </div>
            </div>
          </section>
          <ContainerSeparations height={40} />
          <div
            className="layout-need-information-tourism-health"
            style={{ margin: "0 auto" }}
          >
            <NeedInformation
              description={btns.agent.description}
              img={btns.agent.img}
              title={btns.agent.title}
              textButton={btns.agent.textButton}
              color={"#104172"}
              active={true}
              url={"https://wa.link/uq1q0p"}
              linkExit={true}
            />
          </div>
          <ContainerSeparations height={40} />
          <section
            className={
              isActive ? "ofertsTurismSaludActive" : "ofertsTurismSalud"
            }
          >
            <div className="ofertsTurismSaludContain">
              <div className="ofertsTurismSaludButtons">
                <img
                  src="https://res.cloudinary.com/dovavvnjx/image/upload/v1718984052/Group_843_zxokaz.png"
                  alt="Ofertas"
                />
                <button
                  className={
                    isActive
                      ? "ofertsTurismSaludButton1Active"
                      : "ofertsTurismSaludButton1"
                  }
                  onClick={() => handleActive(false)}
                >
                  <div
                    className={
                      isActive
                        ? "ofertsTurismSaludCircle1Active"
                        : "ofertsTurismSaludCircle1 "
                    }
                  ></div>
                  {t("browse_home.oferts.option_1")}
                  <div
                    className={
                      !isActive
                        ? "ofertsTurismSaludArrow1Active"
                        : "ofertsTurismSaludArrow1"
                    }
                  ></div>
                </button>
                <button
                  className={
                    isActive
                      ? "ofertsTurismSaludButton2Active"
                      : "ofertsTurismSaludButton2"
                  }
                  onClick={() => handleActive(true)}
                >
                  <div
                    className={
                      isActive
                        ? "ofertsTurismSaludCircle2Active"
                        : "ofertsTurismSaludCircle2"
                    }
                  ></div>
                  {t("browse_home.oferts.option_2")}
                  <div
                    className={
                      isActive
                        ? "ofertsTurismSaludArrow2Active"
                        : "ofertsTurismSaludArrow2"
                    }
                  ></div>
                </button>
                <TextComponent
                  children={t("browse_home.oferts.description")}
                  className={"ofertsTurismSaludDescription"}
                  size={"md2"}
                />
              </div>
              <SliderOferts
                loadingImage={loadingImage}
                setLoadingImage={setLoadingImage}
                colorButton={isActive ? "#00AFE8" : "#104172"}
                optionSearch={isActive}
              />
            </div>
          </section>
          <NewsWellezy />
          <AnyQuestions title={t("browse_home.have_doubt")} />
        </>
      ) : (
        <CountDownTimer
          targetDate={fechaLanzamiento}
          setLanzamiento={setLanzamiento}
          lanzamiento={lanzamiento}
        />
      )}
    </div>
  );
}
export default Home;
