import React, { useState } from "react";
import style from "./styles.module.css";
import { MdCameraAlt } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.css";
import { useTranslation } from "react-i18next";

const CardTour = ({ data, detailTour, width = "" }) => {
  const { image, name, fare, start_time, end_time, id, type_currency } = data;

  const { t } = useTranslation("global");

  const location = useLocation();
  const navigate = useNavigate();

  const [imageLoaded, setImageLoaded] = useState(false);

  const isTour = location.pathname.includes("/tours");

  const handleClick = () => {
    if (isTour) {
      navigate(`/tour/${id}`);
    }
  };

  const recortarTexto = (cantidadCaracteres, texto) =>
    texto.length <= cantidadCaracteres
      ? texto
      : texto.slice(0, cantidadCaracteres) + "...";

  return (
    <div className="card-packages-new" style={{ width: width ? width : "" }}>
      {!imageLoaded && (
        <div className="img-transport-skeleton">
          <MdCameraAlt size={40} color="#c8c8c8" />
        </div>
      )}
      <img
        src={`${process.env.REACT_APP_URL_API_AIOP}storage/tour/${image}`}
        alt={name}
        onLoad={() => setImageLoaded(true)}
        style={{ display: imageLoaded ? "block" : "none" }}
      />
      <div className="description">
        <h2>{recortarTexto(22, name)}</h2> 
        <p> 
          {t("tourism.available_until")} {end_time} 
        </p>
      </div>
      <div className="price">
        <span className="back-price">{fare} {type_currency}</span>
        <span className="see-more" onClick={handleClick}>
          {t("tourism.see_more")}
        </span>
      </div>
    </div>
  );
};

export default CardTour;
