import React, { useState } from 'react';
import styles from './styles.module.css';
import { FaRegFileImage } from 'react-icons/fa6';
import { MdCameraAlt, MdFileDownloadDone, MdFileUpload } from 'react-icons/md';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Dialog } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';



const ProcedureModal = ({ formsFilter, FormErrors, uploadedImages, handleImageUpload, validateForm }) => {
    const { t, i18n } = useTranslation("global");
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
        validateForm()
    };

    // Estado para controlar si las imágenes se han cargado o no
    const [loadedImages, setLoadedImages] = useState({});

    // Estado para controlar si hay errores al cargar las imágenes
    const [imageErrors, setImageErrors] = useState({});

    const [openCreateContact, setOpenCreateContact] = useState(false);

    const handleCrearCat = () => {
        setOpenCreateContact(true);
    };

    const handleCloseCrearCat = () => {
        setOpenCreateContact(false);
    };

    // Función para manejar cuando una imagen se carga correctamente
    const handleImageLoad = (key) => {
        setLoadedImages(prevState => ({
            ...prevState, // Mantiene el estado previo
            [key]: true  // Marca la imagen como cargada
        }));
        setImageErrors(prevState => ({
            ...prevState, // Mantiene el estado previo
            [key]: false // Marca que no hay error al cargar la imagen
        }));
    };

    // Función para manejar cuando una imagen no se carga correctamente
    const handleImageError = (key) => {
        setImageErrors(prevState => ({
            ...prevState, // Mantiene el estado previo
            [key]: true  // Marca que hay un error al cargar la imagen
        }));
    };



    return (
        <>
            <button onClick={handleCrearCat} className={styles.openModalButton}>
                {t("build_package.steps.std.form.important_questions.attach_required_photos")} 
                <MdFileUpload size={20} />
            </button>

            <Dialog
                PaperProps={{ sx: { width: "100%", maxWidth: "850px!important", padding: "26px", borderRadius: '8px' } }}
                open={openCreateContact}
                onClose={handleCloseCrearCat}
                BackdropProps={{
                    sx: {
                        backgroundColor: '#021624b0' // Color con opacidad
                    }
                }}
            >
                <div className={styles.container_form_photos}>
                    {formsFilter.map(item => (
                        <div className={styles.container_photos} key={item.procedimiento}>
                            <div className={styles.divTopModal}>
                                <h3>{t("build_package.steps.std.form.important_questions.attach_required_photos")} 
                                    {" "}
                                    ({i18n.language === "es" && item.procedimiento === "mamoplastia" ? "Mamoplastia" 
                                    : i18n.language === "en" && item.procedimiento === "mamoplastia" ? "Mammoplasty" 
                                    : item.procedimiento}) 
                                    {" "}
                                    <span className="text-red-500 text-[17px]">*</span>
                                </h3>
                                <IoClose onClick={handleCloseCrearCat} />
                            </div>

                            <div className={styles.contRecomendationsModal}>
                                <h5>{t("build_package.steps.std.form.important_questions.indications.text")}</h5>
                                <p>- {t("build_package.steps.std.form.important_questions.indications.first")}</p>
                                <p>- {t("build_package.steps.std.form.important_questions.indications.second")}</p>
                                <p>- {t("build_package.steps.std.form.important_questions.indications.third")}</p>
                            </div>

                            <div className="mt-3">
                                <p style={{ color: '#225B87' }}>
                                    {t("build_package.steps.std.form.important_questions.indications.description")} 
                                    {" "}
                                    ({i18n.language === "es" && item.procedimiento === "mamoplastia" ? "Mamoplastia" 
                                    : i18n.language === "en" && item.procedimiento === "mamoplastia" ? "Mammoplasty" 
                                    : item.procedimiento}).
                                </p>
                            </div>

                            {(() => {
                                // Contar la cantidad de imágenes cargadas para el procedimiento actual
                                const uploadedCount = uploadedImages[item.procedimiento] ? Object.keys(uploadedImages[item.procedimiento]).length : 0;

                                // Calcular la cantidad de imágenes requeridas restando las imágenes cargadas
                                const requiredImagesCount = item.require_images.length - uploadedCount;

                                // Generar el texto correcto basado en la cantidad de imágenes requeridas
                                const requiredImagesText = requiredImagesCount > 1
                                    ? t("build_package.steps.std.form.important_questions.images_to_continue", { count: requiredImagesCount })
                                    : t("build_package.steps.std.form.important_questions.image_to_continue", { count: requiredImagesCount })

                                if (requiredImagesCount === 0) {
                                    return (
                                        <p style={{ color: "green", marginBottom: '10px' }}>
                                            {t("build_package.steps.std.form.important_questions.selected_images_successfully")}
                                        </p>
                                    );
                                } else {
                                    return (
                                        FormErrors[item.procedimiento]?.require_images && (
                                            <p style={{ color: "var(--redPrimary)", marginBottom: '10px' }}>
                                                {/*  {FormErrors[item.procedimiento]?.require_images.message} - */}
                                                {requiredImagesText}
                                            </p>
                                        )
                                    );
                                }
                            })()}

                            <div className={styles.grid_container}>
                                {item?.require_images?.map((img, index) => {
                                    const uploadedImage = uploadedImages[item.procedimiento]?.[index];
                                    const imageKey = `${item.procedimiento}-${index}`;
                                    return (
                                        <div key={index} className={styles.itemFileImage}>
                                            <div className={styles.itemFileImageContent}>
                                                {(!loadedImages[imageKey] || imageErrors[imageKey]) && (
                                                    <div className='img-transport-skeleton-cards'>
                                                        <MdCameraAlt size={40} color="#c8c8c8" />
                                                    </div>
                                                )}
                                                <img
                                                    src={uploadedImage || img}
                                                    alt={`Imagen ${index + 1}`}
                                                    onLoad={() => handleImageLoad(imageKey)}
                                                    onError={() => handleImageError(imageKey)}
                                                    style={{ display: (loadedImages[imageKey] && !imageErrors[imageKey]) ? 'block' : 'none' }}
                                                />
                                            </div>
                                            <label htmlFor={`file-${index}`} className={`${styles.custumFileUpload}  ${uploadedImage ? styles.uploaded : ''}`}>
                                                <div className={`${styles.icon} ${uploadedImage ? styles.uploaded : ''}`}>
                                                    {
                                                        uploadedImage ? (<MdFileDownloadDone size={18} />) : (<FaRegFileImage size={18} />)
                                                    }
                                                </div>
                                                <div className="text">
                                                    <span className={`${styles.uploadedText} ${uploadedImage ? styles.uploaded : ''}`}>
                                                        {uploadedImage ? `IMAGEN_${index + 1}` : t("build_package.steps.std.form.important_questions.attach_photo")}
                                                    </span>
                                                </div>
                                                <input
                                                    id={`file-${index}`}
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleImageUpload(e, item.procedimiento, index)}
                                                />
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}

                    <div className={styles.bottomTextModal} >
                        <p>{t("build_package.steps.std.form.important_questions.before_send")}</p>
                    </div>

                    <div className={styles.positionButton}>
                        <button onClick={handleCloseCrearCat} className={styles.openModalButton}>
                            {t("build_package.steps.std.form.important_questions.confirm_button")} <BsCheckCircleFill size={17} />
                        </button>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ProcedureModal;
