import React, { useState } from "react";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import "./styles.css";
import doctor from "../../../../../assets/images/doctorExample.png";
import { useTranslation } from "react-i18next";
import { MdCameraAlt } from "react-icons/md";

const BannerFlow = ({ name, state, urlImage, stars, modal, setModal }) => {
  const [t] = useTranslation("global");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <>
      {modal ? (
        <div
          className="content-banner-flow"
          style={{ display: `${modal ? "flex" : "none"}` }}
        >
          <section className="contain-info-doctor-flow">
            <div className="content-img-doctor-flow">
              {!imageLoaded && !imageError && (
                <div className="img-hotel-skeleton">
                  <MdCameraAlt size={40} color="#c8c8c8" />
                </div>
              )}
              {imageError && (
                <div className="img-hotel-skeleton">
                  <MdCameraAlt size={40} color="#c8c8c8" />
                </div>
              )}
              {!imageError && (
                <img
                  src={`${process.env.REACT_APP_URL_API_AIOP}storage/medic_pack/${urlImage}`}
                  height={"200px"}
                  alt=""
                  onLoad={handleImageLoaded}
                  onError={handleImageError}
                  style={{ display: imageLoaded ? "block" : "none",
                    objectFit: "contain",
                   }}
                />
              )}
            </div>
            <div className="content-info-doctor-detail-flow">
              <h2>Pack {name}</h2>
              <p>{state}</p>
              <ul>
                <li>
                  <SvgIcon
                    name={iconList.IconStar}
                    size={30}
                    color={"#ffff00"}
                  />
                </li>
                <li>
                  <SvgIcon
                    name={iconList.IconStar}
                    size={30}
                    color={"#ffff00"}
                  />
                </li>
                <li>
                  <SvgIcon
                    name={iconList.IconStar}
                    size={30}
                    color={"#ffff00"}
                  />
                </li>
                <li>
                  <SvgIcon
                    name={iconList.IconStar}
                    size={30}
                    color={"#ffff00"}
                  />
                </li>
                <li>
                  <SvgIcon
                    name={iconList.IconStar}
                    size={30}
                    color={"#ffff00"}
                  />
                </li>
              </ul>
              <p>(86) - 90% {t("specialist.booking_flow.recomment")}</p>
            </div>
          </section>
        </div>
      ) : (
        <div
          className="content-banner-flow"
          style={{ padding: "1rem", alignItems: "center" }}
        >
          <section className="contain-info-doctor-flow">
            <div className="content-img-doctor-flow">
              {!imageLoaded && !imageError && (
                <div
                  className="skeleton-loader"
                  style={{ width: "50px", height: "50px" }}
                ></div>
              )}
              {imageError && (
                <div className="image-error-placeholder">
                  Imagen no disponible
                </div>
              )}
              {!imageError && (
                <img
                  src={`${process.env.REACT_APP_URL_API_AIOP}storage/medic_pack/${urlImage}`}
                  style={{
                    width: "50px",
                    height: "50px",
                    display: imageLoaded ? "block" : "none",
                  }}
                  alt=""
                  onLoad={handleImageLoaded}
                  onError={handleImageError}
                />
              )}
            </div>
            <div className="content-info-doctor-detail-flow">
              <h2 style={{ fontSize: "18px" }}>Pack {name}</h2>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default BannerFlow;
