import { useState, useEffect } from 'react';
import styles from './styles.module.css';
import ModalContainer from '@/ui/views/share/components/modalContainer';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const FlightTimer = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const initialTimeInSeconds = 1200; // 20 minutos en segundos
    const [timeLeft, setTimeLeft] = useState(initialTimeInSeconds);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t, i18n } = useTranslation("global");
    const calculateTimeLeft = (startTime) => {
        const now = Date.now();
        const elapsed = Math.floor((now - startTime) / 1000);
        return Math.max(initialTimeInSeconds - elapsed, 0);
    };

    useEffect(() => {
        const { controlNumberPNR } = state || {};
        const savedPNR = localStorage.getItem('savedPNR');
        const savedStartTime = localStorage.getItem('purchaseStartTime');

        if (controlNumberPNR && controlNumberPNR !== savedPNR) {
            resetTimer(controlNumberPNR);
        } else if (savedStartTime) {
            const startTime = parseInt(savedStartTime, 10);
            const newTimeLeft = calculateTimeLeft(startTime);
            setTimeLeft(newTimeLeft);

            if (newTimeLeft === 0) {
                handleTimeout();
            }
        } else {
            resetTimer(controlNumberPNR);
        }
    }, [state]);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    const newTimeLeft = prevTime - 1;
                    if (newTimeLeft <= 0) {
                        clearInterval(timer);
                        handleTimeout();
                        return 0;
                    }
                    return newTimeLeft;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [timeLeft]);

    const resetTimer = (controlNumberPNR) => {
        const now = Date.now();
        localStorage.setItem('savedPNR', controlNumberPNR);
        localStorage.setItem('purchaseStartTime', now.toString());
        setTimeLeft(initialTimeInSeconds);
    };

    const handleTimeout = () => {
        setIsModalOpen(true);
        const urlFlights = localStorage.getItem('urlFlights');
        clearTimer();

        setTimeout(() => {
            navigate(urlFlights || '/', { replace: true });
        }, 5000);
    };

    const clearTimer = () => {
        localStorage.removeItem('savedPNR');
        localStorage.removeItem('purchaseStartTime');
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <>
            <div className={`${styles.purchaseTimer} ${!timeLeft ? styles.expiredTime : ""}`}>
                {timeLeft > 0 ? (
                    <h2 className={styles.timeLeft}>
                        {t("fligth.flight_pays.estimated_time")}: <span>{formatTime(timeLeft)}</span>
                    </h2>
                ) : (
                    <h2 className={styles.expired}>{t("fligth.flight_pays.lose_time")}.</h2>
                )}
            </div>

            <ModalContainer show={isModalOpen} width={500} hidden={setIsModalOpen}>
                <div className={styles.modalAlertTime}>
                    <h2>{t("fligth.flight_pays.lose_time")}</h2>
                    <p>{t("fligth.flight_pays.purchase_again")}.</p>

                    <div className={styles.loadContainer}>
                        <AiOutlineLoading3Quarters className={styles.load} size={30} />
                        {t("fligth.flight_pays.redirect")}...
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default FlightTimer;
