import TooltipContainer from "@/ui/views/share/components/tooltipContainer";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { setProcedureData } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

const InputSearchProcedures = ({
  showProcedures,
  setShowProcedures,
  procedure,
  setProcedure,
  checkProcedure,
  idSpecialty,
  setDaysProcedure,
}) => {
  const { t } = useTranslation("global");

  // Variables
  const dispatch = useDispatch();

  const inputRef = useRef(null);
  const specialtiesRef = useRef(null);
  const [cardEspecialties, setCardEspecialties] = useState(false);
  const [especialties, setEspecialties] = useState([]);
  const [especialtiesSearch, setEspecialtiesSearch] = useState([]);
  const [firstRender, setFirstRender] = useState(false);
  const [available, setAvailable] = useState(false);

  const procedureData = useSelector(
    (state) => state.dataSearchEngine.procedureData
  );

  // Functions
  const handleClick = () => {
    if (idSpecialty) {
      setProcedure("");
      setShowProcedures(true);
      setCardEspecialties(true);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const updateProcedureData = (especialtieSelect) => {
    dispatch(setProcedureData(especialtieSelect));
  };

  const getEspecialties = () => {
    let response = axios.get(
      `https://pdtclientsolutions.com/crm-public/api/category/sub/${idSpecialty}`
    );

    response
      .then((res) => {
        setEspecialties(res.data);
        setAvailable(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // UseEffects
  useEffect(() => {
    if (idSpecialty === "") {
      setEspecialties([]);
    }
    setProcedure(procedureData.name);
    setDaysProcedure(procedureData);
  }, []);

  useEffect(() => {
    setFirstRender(true);
    getEspecialties();
  }, [idSpecialty]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        specialtiesRef.current &&
        !specialtiesRef.current.contains(event.target)
      ) {
        setShowProcedures(false);
        setCardEspecialties(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [specialtiesRef, setShowProcedures]);

  useEffect(() => {
    const sortedEspecialties = [...especialties].sort((a, b) =>
      a.name.localeCompare(b.name)
    );    

    if (procedure === "") {
      setEspecialtiesSearch(sortedEspecialties);
    } else {
      setEspecialtiesSearch(
        sortedEspecialties.filter(
          (especialty) =>
            especialty?.name &&
            especialty?.name?.toLowerCase().includes(procedure?.toLowerCase())
        )
      );
    }
  }, [procedure, especialties]);

  //

  return (
    <div
      className="searchBuildPackageContentInput"
      onClick={handleClick}
      style={{
        borderColor:
          procedure == "" && checkProcedure ? "var(--redPrimary)" : "",
        opacity: idSpecialty ? 1 : 0.5,
        pointerEvents: idSpecialty ? "" : "none",
      }}
      ref={specialtiesRef}
    >
      <div className="searchBuildPackageContentInputContent">
        <TextComponent
          children={t("build_package.search_engine.procedure.text")}
          size={"md2"}
          tag={"p"}
          color={
            procedure == "" && checkProcedure
              ? "var(--redPrimary)"
              : "var(--greySecundary)"
          }
        />
        <input
          type="text"
          placeholder={showProcedures ? "" : t("build_package.search_engine.procedure.placeholder")}
          ref={inputRef}
          value={procedure}
          onChange={(e) => setProcedure(e.target.value)}
        />
      </div>
      <TiArrowSortedDown size={20} color="var(--bluePrimary)" />
      {cardEspecialties && (
        <TooltipContainer
          show={showProcedures}
          hidden={setShowProcedures}
          sizeWidth={100}
          sizeHeight={80}
        >
          <ul className="contentElemetsSearchInputBuildPackage">
            {especialtiesSearch.length === 0 ? (
              <li className="list-not-results">{t("build_package.search_engine.without_results")}</li>
            ) : (
              especialtiesSearch.map((especialtieItem, index) => (
                <Tooltip title={especialtieItem.name} arrow placement="right">
                  <li
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      setProcedure(especialtieItem.name);
                      setShowProcedures(false);
                      setCardEspecialties(false);
                      updateProcedureData(especialtieItem);
                      setDaysProcedure(especialtieItem);
                    }}
                  >
                    <span>{especialtieItem.name}</span>
                  </li>
                </Tooltip>
              ))
            )}
          </ul>
        </TooltipContainer>
      )}
    </div>
  );
};

export default InputSearchProcedures;
