import React from "react";

const Modal = ({ showModal, hasCloseButton, onClose, children }) => {
  if (!showModal) return null;

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 sm:w-96 p-6 relative">
        {hasCloseButton && (
          <button
            onClick={onClose}
            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
            aria-label="Close Modal"
          >
            ✖
          </button>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;