import React, { useEffect, useRef, useState } from "react";
import style from "./styles.module.css";
import CardLastSearch from "./CardLastSearch";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useTranslation } from "react-i18next";

const LastSearch = ({ title, description, items }) => {
  const { t, i18n } = useTranslation("global");
  const [searchStorage, setSearchStorage] = useState(
    JSON.parse(localStorage.getItem("storageLastSearch")) || []
  );

  function convertirRangoFechas(fechaInicio, fechaFin) {
    // Crear objetos Date a partir de las cadenas de entrada
    const fecha1 = new Date(fechaInicio);
    const fecha2 = new Date(fechaFin);

    // Arreglo con los nombres de los meses
    const meses = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Obtener los componentes de la fecha de inicio
    const diaInicio = fecha1.getDate();
    const mesInicio = i18n.language === "en" ? months[fecha1.getMonth()] : meses[fecha1.getMonth()];

    // Obtener los componentes de la fecha de fin
    const diaFin = fecha2.getDate();
    const mesFin = i18n.language === "en" ? months[fecha2.getMonth()] : meses[fecha2.getMonth()];

    // Formatear las fechas en el formato deseado
    const fechaFormateada = i18n.language === "en" ? `From ${mesInicio} ${diaInicio} to ${mesFin} ${diaFin}` : `Del ${diaInicio} de ${mesInicio} al ${diaFin} de ${mesFin}`;

    return fechaFormateada;
  }

  function convertirRangoFechasHotel(fechaInicio, fechaFin) {
    // Crear un arreglo con los nombres de los meses
    const meses = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Función para convertir una cadena en formato d/m/yyyy a un objeto Date
    function convertirCadenaAFecha(cadena) {
      const partes = cadena.split("/");
      const dia = parseInt(partes[1]);
      const mes = parseInt(partes[0]) - 1; // Los meses en Date van de 0 a 11
      const año = parseInt(partes[2]);
      return new Date(año, mes, dia);
    }

    // Convertir las cadenas de entrada a objetos Date
    const fecha1 = convertirCadenaAFecha(fechaInicio);
    const fecha2 = convertirCadenaAFecha(fechaFin);

    // Obtener los componentes de la fecha de inicio
    const diaInicio = fecha1.getDate();
    const mesInicio = i18n.language === "en" ? months[fecha1.getMonth()] : meses[fecha1.getMonth()];

    // Obtener los componentes de la fecha de fin
    const diaFin = fecha2.getDate();
    const mesFin = i18n.language === "en" ? months[fecha2.getMonth()] : meses[fecha2.getMonth()];

    // Formatear las fechas en el formato deseado
    const fechaFormateada = i18n.language === "en" ? `From ${mesInicio} ${diaInicio} to ${mesFin} ${diaFin}` : `Del ${diaInicio} de ${mesInicio} al ${diaFin} de ${mesFin}`;

    return fechaFormateada;
  }

  function convertirFechaTransport(cadena) {
    // Dividir la cadena por los guiones
    const partes = cadena.split("-");

    // Extraer las partes correspondientes a día, mes y año
    const año = partes[0];
    const mes = partes[1];
    const día = partes[2];
    const hora = partes[3];
    const minutos = partes[4];

    // Arreglo con los nombres de los meses
    const meses = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Convertir el mes a su nombre correspondiente
    const nombreMes = i18n.language === "en" ? months[parseInt(mes) - 1] : meses[parseInt(mes) - 1];

    // Formatear la nueva cadena de fecha
    const nuevaCadena = i18n.language === "en" ? `From ${nombreMes} ${día} ${hora}:${minutos}` : `Del ${día} de ${nombreMes} ${hora}:${minutos}`;

    return nuevaCadena;
  }

  let personsHotel = 0;

  searchStorage.map((item) => {
    if (item?.type === "hotel") {
      item?.rooms.map((room) => {
        personsHotel += room?.adult + room?.child;
        return null;
      });
    }
    return null;
  });

  const sliderRef = useRef(null);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const handleNext = () => {
    if (!isNextDisabled) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  const handlePrev = () => {
    if (!isPrevDisabled) {
      sliderRef.current.scrollBy({
        left: -sliderRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  const updateButtonState = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      setIsPrevDisabled(scrollLeft === 0);
      setIsNextDisabled(scrollLeft + clientWidth >= scrollWidth - 1);
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;

    if (slider) {
      const handleScroll = () => {
        updateButtonState();
      };

      slider.addEventListener("scroll", handleScroll);
      updateButtonState();

      return () => {
        slider.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    updateButtonState();
  }, [searchStorage.length]);

  return (
    <section className={style.contentLastSearch}>
      <div className={style.lastDetacadosTitle}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>

      <div className={style.allCardLastSearch}>
        {searchStorage.length <= 0 ? (
          <h3
            style={{
              textAlign: "center",
              margin: "2rem 0",
              color: "var(--greyPrimary)",
            }}
          > 
            {t("hotels.last_search.without_recent_searchs")}
          </h3>
        ) : (
          <div className={style.sliderContainerSearch}>
            <div ref={sliderRef} className={style.sliderSearch}>
              {searchStorage.map((item, index) => (
                <div key={index} className={style.slideSearch}>
                  {item.type === "transport" ? (
                    <CardLastSearch
                      description1={`${item.adult + item.baby} ${
                        item.adult + item.baby > 1 
                        ? t("hotels.last_search.persons")
                        : t("hotels.last_search.person")
                      }`}
                      description2={convertirFechaTransport(item.dateApi)}
                      title={item.city}
                      img="https://www.uber-assets.com/image/upload/f_auto,q_auto:eco,c_fill,w_956,h_638/v1630533248/assets/a4/7c98e3-19c8-4d27-a0a3-ad0a3dde2886/original/explore-right.png"
                      type={t("hotels.last_search.types.transport")}
                      url={item.url}
                      disable
                      active
                    />
                  ) : item.type === "hotel" ? (
                    <CardLastSearch
                      description1={`${personsHotel} ${
                        personsHotel > 1 
                        ? t("hotels.last_search.persons")
                        : t("hotels.last_search.person")
                      }`}
                      description2={convertirRangoFechasHotel(
                        item.arrival,
                        item.departure
                      )}
                      title={item.destiny}
                      img="https://content.r9cdn.net/rimg/dimg/16/60/a2791cb8-city-26923-164c90b0ab4.jpg?crop=true&width=1366&height=768&xhint=2659&yhint=2675"
                      type={t("hotels.last_search.types.hotel")}
                      url={item.url}
                      disable
                      active
                    />
                  ) : (
                    <CardLastSearch
                      description1={`${item.adult + item.baby + item.boy} ${
                        item.adult + item.baby + item.boy > 1
                          ? t("hotels.last_search.persons")
                          : t("hotels.last_search.person")
                      }`}
                      description2={convertirRangoFechas(
                        item.departureDate,
                        item.return
                      )}
                      title={`${item.iataO} - ${item.iataD}`}
                      img="https://wokii.com/wp-content/uploads/2020/09/johan-van-wambeke-_2viofugqvg-unsplash-scaled.jpg"
                      type={t("hotels.last_search.types.flight")}
                      url={item.url}
                      disable
                      active
                    />
                  )}
                </div>
              ))}
            </div>
            <button
              className={`${style.sliderSearchButton} ${style.prev} ${
                isPrevDisabled ? `${style.disabled}` : ""
              }`}
              disabled={isPrevDisabled}
              onClick={handlePrev}
            >
              <MdOutlineKeyboardArrowLeft size={25} />
            </button>
            <button
              className={`${style.sliderSearchButton} ${style.next} ${
                isNextDisabled ? `${style.disabled}` : ""
              }`}
              onClick={handleNext}
              disabled={isNextDisabled}
            >
              <MdOutlineKeyboardArrowRight size={25} />
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default LastSearch;
