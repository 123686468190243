import React, { useState, useEffect, useCallback } from "react";
import "./styles.css";
import Loading from "../../../share/components/loading";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import ContainerFlights from "./components/containerFlights";
import FlowPack from "../../../share/components/flowPack/FlowPack";
import { useLocation } from "react-router-dom";
import FlightFilters from "./components/filterFlights/filterFlights";
import { filterFlights, setGlobalFilters } from "../../../../../Hooks/useFillters"; 
import TextComponent from "../../../share/components/fonts/TextComponent";
import { is } from "react-date-range/dist/locale";
import { CiSearch } from "react-icons/ci";
import { useTranslation } from "react-i18next";

function FlightDetailResultsList({
  allPassengersSelected,
  loading,
  flightsList,
  isRoundTrip,
  queryError,
  nameOrigin,
  nameDestiny,
  searcherFlight,
  setDirectFlight,
  directFlight,
  dollarFlight,
  setDollarFlight
}) {
  const { t } = useTranslation("global");
  const { pathname } = useLocation();
  const location = useLocation();
  const [filters, setFilters] = useState({
    isRoundTrip: isRoundTrip,
    range: [flightsList?.priceMin ?? 0, flightsList?.priceMax ?? Infinity],
    timeRange: [0, 1440],
    airline: "",
    typeFlightWithBags: null,
    executiveFlight: false,
    directFlight: false,
    dollarFlight: dollarFlight,
  });

  useEffect(() => {
    localStorage.setItem('urlFlights', location.pathname);
    console.log(localStorage.getItem('urlFlights'));

  }, [location]);



  const [filteredFlights, setFilteredFlights] = useState([]);
  const [firstRender, setFirstRender] = useState(false)

  const updateFilteredFlights = useCallback(() => {
    if (flightsList?.recommendation) {
      setGlobalFilters(filters);
      const results = filterFlights(flightsList?.recommendation, flightsList);
      setFilteredFlights(results);
    }
  }, [flightsList, filters]);



  useEffect(() => {

    updateFilteredFlights();

  }, [updateFilteredFlights]);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      isRoundTrip: isRoundTrip,
    }));

  }, [isRoundTrip]);

  useEffect(() => {
    setFilters({
      isRoundTrip: isRoundTrip,
      range: [flightsList?.priceMin ?? 0, flightsList?.priceMax ?? Infinity],
      timeRange: [0, 1440],
      airline: "",
      typeFlightWithBags: null,
      executiveFlight: false,
      directFlight: false,
      dollarFlight: dollarFlight,
    })

  }, [flightsList])



  const handleFiltersChange = (newFilters) => {
    setFilters((currentFilters) => {
      if (newFilters.especial) {
        const filteredCurrentFilters = { ...currentFilters };
        return { ...filteredCurrentFilters, ...newFilters };
      } else {
        return { ...currentFilters, ...newFilters };
      }
    });
  };


  return (
    <>
      {loading && <Loading size={80} />}
      <div className="containerFlight">
        {!loading && (
          flightsList?.Seg1?.length > 0 &&
          <FlightFilters
            price={flightsList?.recommendation}
            nameOrigin={nameOrigin}
            nameDestiny={nameDestiny}
            Seg1={flightsList?.Seg1}
            Seg2={flightsList?.Seg2}
            isRoundTrip={filters.isRoundTrip}
            onFiltersChange={handleFiltersChange}
            allPassengersSelected={allPassengersSelected}
            priceMax={Number(flightsList?.priceMax)}
            priceMin={Number(flightsList?.priceMin)}
            searcherFlight={searcherFlight}
            hour0Seg1={flightsList?.hour?.[0].min || {}}
            hour1Seg1={flightsList?.hour?.[0].max || {}}
            hour0Seg2={flightsList?.hour?.[1]?.min || {}}
            hour1Seg2={flightsList?.hour?.[1]?.max || {}}
            hour1={flightsList?.hour?.[1] || {}}
            companies={flightsList?.companies}
            directFlight={directFlight}
            setDirectFlight={setDirectFlight}
            dollarFlight={dollarFlight}
            setDollarFlight={setDollarFlight}
          />

        )}
        {!loading && queryError && (
          <div className="errosSection">
            <SvgIcon color={"#DEDEDE"} size={140} name={iconList.IconSearch} />
            <TextComponent className={"errosSectionIconText"} children={t("fligth.search_fligth.error_api")} size={"lg"} fontWeight="bold" />

          </div>
        )}
        {!loading && !queryError && (
          <div className="column" style={{ justifyContent: "flex-start" }}>
            {pathname.includes("/packages") && <FlowPack type={"fligth"} />}
            {filteredFlights.length > 0 ? (
              filteredFlights.map((flight, key) => {
                if (flight.seg?.Seg1.length > 0) {
                  return <ContainerFlights
                    key={key}
                    nameOrigin={nameOrigin}
                    nameDestiny={nameDestiny}
                    Seg1={flightsList?.Seg1}
                    Seg2={flightsList?.Seg2}
                    isRoundTrip={filters.isRoundTrip}
                    rangePrice={filters.range}
                    recommendation={flight}
                    allPassengersSelected={allPassengersSelected}
                    currency={dollarFlight}
                    bestOffer={key === 0}
                  />
                }
              })
            ) : (
              <p style={{ textAlign: "center", fontWeight: "600", fontSize: "22px", color: "rgb(0 0 0 / 12%)", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: ".5rem" }} >{t("fligth.search_fligth.no_flights")} <CiSearch size={50} color="rgb(0 0 0 / 12%)" /></p>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default FlightDetailResultsList;
