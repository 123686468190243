import React, { useEffect, useState, useCallback, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close'
import './Assets/styles.css';
import { useTranslation } from "react-i18next";

function PassengerAgeComponent({ passengerAges, setPassengerAges, validate, setValidate }) {
    const [inputAge, setInputAge] = useState("");
    const { t } = useTranslation("global");
    const handleAddAge = useCallback(() => {
        if(!isNaN(inputAge) && Number(inputAge) >= 0 && inputAge !== "" && !inputAge.includes(" ")){
            const newPassengerAges = [...passengerAges, inputAge];
            setPassengerAges(newPassengerAges);
            setValidate("");
            setInputAge("");
        }
    }, [inputAge, passengerAges, setPassengerAges, setValidate]);

    const handleInputChange = useCallback((event) => {
        setInputAge(event.target.value);
    }, []);

    const handleRemoveAge = useCallback((index) => {
        const newPassengerAges = [...passengerAges];
        newPassengerAges.splice(index, 1);
        setPassengerAges(newPassengerAges);

        if (newPassengerAges.length === 0) {
            setValidate("age");
        }
    }, [passengerAges, setPassengerAges, setValidate]);

    const inputClass = useMemo(() => {
        if (passengerAges.length >= 5) {
            return "input-style input-age input-disable";
        } else if (validate === "age") {
            return "input-style input-age input-validate";
        } else {
            return "input-style input-age";
        }
    }, [passengerAges.length, validate]);

    const buttonClass = useMemo(() => passengerAges.length >= 5 ? "button-disable": " button-add", [passengerAges.length]);

    const containerClass = useMemo(() => validate == "age" ? "container-age input-validate": "container-age", [validate]);

    return (
        <div className='add-age-container'>
            <label htmlFor="">{t("travel_insurance.info_passengers")}</label>
            <div className="input-age-container">
                <div className="container-add-age"> 
                    <input 
                        type="text" 
                        className={inputClass} 
                        value={inputAge} 
                        onChange={handleInputChange} 
                        disabled={passengerAges.length >= 5} 
                        placeholder={t("travel_insurance.write")}
                    />
                    <button className={buttonClass} onClick={handleAddAge}>{t("travel_insurance.add")}</button>
                </div>
                <div className={containerClass}>
                    {
                        passengerAges.map((age, index) => (
                            <p key={index} className="icon-button" onClick={() => handleRemoveAge(index)}>
                                <span key={index}>
                                    {age}
                                    <CloseIcon style={{ fontSize: 15 }} fontSize="small"/>
                                </span>
                            </p>
                        ))
                    }
                </div>
            </div>
            { validate === "age" ? (<p className="validate-p">{t("travel_insurance.is_required")}</p>): "" }
        </div>
    );
}

export default PassengerAgeComponent;