import { imageListClasses, Tooltip } from '@mui/material'
import React, { useState } from 'react' // Importa useState
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../../../utils';
import { MdCameraAlt } from "react-icons/md";
import { useTranslation } from 'react-i18next';

const CardPackages = ({ pack, imgType, width = "" }) => {
    const { image, price, name, currency, due_date, start_date, id } = pack;

    const [loadedImages, setLoadedImages] = useState({});
    const [imageErrors, setImageErrors] = useState({});
    
    const { t } = useTranslation("global");
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/packages/${id}`)
    }

    function textCutter(cantidadCaracteres, texto) {
        if (texto.length <= cantidadCaracteres) {
            return texto;
        }
        return texto.slice(0, cantidadCaracteres) + "...";
    }

    const imageUrl = `${process.env.REACT_APP_URL_API_AIOP}storage/pack/${image}`;

    const handleImageLoad = (key) => {
        setLoadedImages(prevState => ({
            ...prevState,
            [key]: true
        }));
        setImageErrors(prevState => ({
            ...prevState,
            [key]: false
        }));
    };

    const handleImageError = (key) => {
        setImageErrors(prevState => ({
            ...prevState,
            [key]: true
        }));
    };


    return (
        <div className='card-packages-new ' style={{ width: width ? width : "" }}>
            {(!loadedImages["imageKey"] || imageErrors["imageKey"]) && (
                <div className='img-transport-skeleton-cards'>
                    <MdCameraAlt size={40} color="#c8c8c8" />
                </div>
            )}
            <img
                src={imageUrl}
                alt={name}
                onLoad={() => handleImageLoad("imageKey")}
                onError={() => handleImageError("imageKey")}
                style={{ display: (loadedImages["imageKey"] && !imageErrors["imageKey"]) ? 'block' : 'none' }}
            />
            <div className='description'>
                <Tooltip title={name} placement="bottom" >
                    <h2>{textCutter(22, name)}</h2>
                </Tooltip> 
                {t("packs.tourism.available_until")} {due_date}
            </div>
            <div className='price'>
                <span className='back-price'>
                    {formatMoney(price)} {currency}
                </span>
                <span className='see-more' onClick={handleClick}>
                    {t("common.see_more.capitalized")}
                </span>
            </div>
        </div>
    )
}

export default CardPackages