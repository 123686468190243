import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./styles.css";

const Recapchat = ({ language, siteKey, onChangeCaptcha }) => {
  const [recaptchaKey, setRecaptchaKey] = useState(0);

  useEffect(() => {
    // Force the recaptcha recreation when changing the language
    setRecaptchaKey((prevKey) => prevKey + 1);
  }, [language]);

  return (
    <ReCAPTCHA
      key={recaptchaKey} // Change the key force the disassembly and assembly of the component
      sitekey={siteKey}
      onChange={onChangeCaptcha}
      hl={language} // Pass the language directly as prop (documented in the API)
    />
  );
};

export default Recapchat;
