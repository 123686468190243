import TooltipContainer from "@/ui/views/share/components/tooltipContainer";
import { Tooltip } from "@mui/material";
import axios from "axios";
import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const InputEspecialties = ({
    showSpecialties,
    setShowSpecialties,
    especialtie,
    setEspecialtie,
    checkSpeciality,
    setCheckSpeciality,
    setIdSpecialty
}) => {
    const specialtiesRef = useRef(null);
    const inputRef = useRef(null);
    
    const { t } = useTranslation("global");
    const [cardEspecialties, setCardEspecialties] = useState(false);
    const [especialties, setEspecialties] = useState([]);
    const [especialtiesSearch, setEspecialtiesSearch] = useState([]);

    // Functions
    const handleClick = () => {
        setEspecialtie("");
        setIdSpecialty(null);
        setShowSpecialties(true);
        setCardEspecialties(true);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const getEspecialties = () => {
        let response = axios.get("https://pdtclientsolutions.com/crm-public/api/category");

        response.then((res) => {
            setEspecialties(res.data);
        })
        .catch((err) => {
            console.error(err);
        });
    };

    // UseEffects
    useEffect(() => {
        getEspecialties();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(specialtiesRef.current && !specialtiesRef.current.contains(event.target)){
                setShowSpecialties(false);
                setCardEspecialties(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [specialtiesRef, setShowSpecialties]);

    useEffect(() => {
        const sortedEspecialties = [...especialties].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        if (especialtie === "") {
            setEspecialtiesSearch(sortedEspecialties.filter((especialty) =>
                especialty.name
            ));
        } else {
            setEspecialtiesSearch(sortedEspecialties.filter((especialty) =>
                especialty.name && especialty.name.toLowerCase().includes(especialtie.toLowerCase())
            ));
        }
    }, [especialtie, especialties]);

    useEffect(() => {
        // Set default specialty if not already set
        // if (especialtie === "" && especialties.length > 0) {
        //     const defaultSpecialty = especialties.find(especialty => especialty.name === "Cirugia Plastica Estetica y Reconstructiva");
        //     if (defaultSpecialty) {
        //         setEspecialtie(defaultSpecialty.name);
        //         setIdSpecialty(defaultSpecialty.id);
        //     }
        // }
    }, [especialties, especialtie, setEspecialtie, setIdSpecialty]);

    return (
        <div className={styles.containerInputSearch} onClick={handleClick} ref={specialtiesRef}>
            <input type="text"
                placeholder={t("build_package.filters.specialty.placeholder")}
                ref={inputRef}
                value={especialtie}
                onChange={(e) => {
                    setEspecialtie(e.target.value);
                    setCheckSpeciality(false);
                }}
                className={styles.inputSearchEspecialties}
                style={checkSpeciality ? {borderColor: "var(--redPrimary)"} : {}}
            />
            {
                cardEspecialties && (
                    <TooltipContainer
                        show={showSpecialties}
                        hidden={setShowSpecialties}
                        sizeWidth={100}
                        sizeHeight={80}
                    >
                        <ul className="contentElemetsSearchInputBuildPackage">
                            {
                                especialtiesSearch.length === 0 ? (
                                    <li className="list-not-results">
                                        {t("build_package.filters.without_results")}
                                    </li>
                                ) : (
                                    especialtiesSearch.map((especialtieItem, index) => (
                                        <Tooltip title={especialtieItem.name} arrow placement="right" key={index}>
                                            <li onClick={(e) => {
                                                e.stopPropagation();
                                                setEspecialtie(especialtieItem.name);
                                                setShowSpecialties(false);
                                                setCardEspecialties(false);
                                                setIdSpecialty(especialtieItem.id);
                                                setCheckSpeciality(false);
                                            }}>
                                                <span>{especialtieItem.name}</span>
                                            </li>
                                        </Tooltip>
                                    ))
                                )
                            }
                        </ul>
                    </TooltipContainer>
                )
            }
        </div>
    );
}

export default InputEspecialties;