import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { LuCalendarPlus, LuLoader2 } from 'react-icons/lu'
import { BiSolidRightArrow } from 'react-icons/bi';
import axios from 'axios'

import useHeaderSession from '@/ui/viewModels/session/header.session.viewModel'
import CarouselSevices from '@/ui/views/share/components/CarouselServices/CarouselSevices'
import { formatToISO, formatYYYYMMDDToDDMMYYYY } from '@/common/services/dateUtils'
import { setCurrentStep, setDataPackage, setLastSearch, setProceduresData, setRoutes, } from "@/store/slices/dataStepz/dataStepzSlice";

import styles from "./styles.module.css"

import Loading from '@/ui/views/share/components/loading'
import StepHome from "../../components/Step/StepHome";
import StepStep from "../../components/Step/StepStep";
import DataSearch from '../../components/DataSearch/DataSearch'
import ModalAction from "../../components/ModalAction/ModalAction";
import SessionModal from '@/ui/views/session/components/SessionModal/SessionModal'
import AnyQuestions from '@/ui/views/home/components/anyQuestion'
import AllCampsForms from './AllCampsForm'
import FormProcedure from './formProcedure/FormProcedure'
import InputNacionalityStd from './components/InputNacionality/InputNacionalityStd'
import DateInputWithPicker from './components/SelectedDate/DateInputWithPicker'
import { v4 as uuidv4 } from 'uuid';
import PaymentApprovedBanner from '@/common/components/PaymentApprovedBanner';

const Std = () => {
    const { userDetail } = useHeaderSession()
    const navigate = useNavigate()
    const [activeFormIndex, setActiveFormIndex] = useState(null);
    const [activeSession, setActiveSession] = useState(false)
    const [paceholders, setPaceholders] = useState("");
    const [nacionality, setNacionality] = useState("");
    const [nacionalityDropdown, setNacionalityDropdown] = useState(false);
    const [dataNacionality, setDataNacionality] = useState([]);
    const [loadinNa, setLoadinNa] = useState(false)
    const [hasAlreadyPaid, setHasAlreadyPaid] = useState(false);

    const {
        manejarCambio,
        formData,
        setFormData,
        obtenerFormulariosPorTexto,
        obtenerEstadoInicial,
        FormErrors,
        setFormErrors
    } = AllCampsForms()
    const [eps, setEps] = useState("")
    const [height, setHeight] = useState("")
    const [weight, setWeight] = useState("")
    const [phone, setPhone] = useState(null)
    const [prefixPhone, setPrefixPhone] = useState(null)
    const [typeProcedure, setTypeProcedure] = useState("")
    const [haveChild, setHaveChild] = useState(false)
    const [quantityChild, setQuantityChild] = useState("")
    const [drinkAlcohol, setDrinkAlcohol] = useState(false)
    const [smoke, setSmoke] = useState(false)
    const [sons, setSons] = useState(false)
    const [ages, setAges] = useState([])
    const [showAges, setShowAges] = useState(true);
    const [haveSurgery, setHaveSurgery] = useState(false)
    const [whichSurgery, setWhichSurgery] = useState("")
    const [haveIllness, setHaveIllness] = useState(false)
    const [whichIllness, setWhichIllness] = useState("")
    const [drugsConsume, setDrugsConsume] = useState(false)
    const [whichDrugs, setWhichDrugs] = useState("")
    const [consumeMedicaments, setConsumeMedicaments] = useState(false)
    const [whichMedicaments, setWhichMedicaments] = useState("")
    const [youAlergicKnowed, setYouAlergicKnowed] = useState(false)
    const [whichAlergicKnowed, setWhichAlergicKnowed] = useState("")
    const [youAlergic, setYouAlergic] = useState(false)
    const [whichAlergic, setWhichAlergic] = useState("")
    const [validation, setValidation] = useState(false)
    const [terms, setTerms] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [operationDate, setOperationDate] = useState("")

    const [procedures, setProcedures] = useState([])

    const { t, i18n } = useTranslation("global");
    const dispatch = useDispatch();
    const location = useLocation();

    const dataPackage = useSelector((state) => state.dataStepz.dataPackage); 
    const dataSearch = useSelector((state) => state.dataSearchEngine);
    const especialtieAvliable = useSelector((state) => state.dataStepz.especialtieAvliable);

    const setSpetVariableData = useSelector((state) => state.dataSearchEngine.spetVariableData);
    const idEspecialtieData = useSelector((state) => state.dataSearchEngine.idEspecialtieData);
    const procedureData = useSelector((state) => state.dataSearchEngine.procedureData);

    const formsFilter = obtenerFormulariosPorTexto(procedureData?.name)

    const routes = useSelector((state) => state.dataStepz.routes);

    const [dataStepzTitles, setDataStepzTitles] = useState([]);
    const [activeStep, setActiveStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([1]);

    const [checkboxCheck, setCheckboxCheck] = useState(false); 

    const [procedureDate, setProcedureDate] = useState("");
    const [selectedProcedureDate, setSelectedProcedureDate] = useState(null); 

    const [loadingLink, setLoadingLink] = useState(false);

    const handleStepClick = (step) => {
        if (step <= activeStep + 1) {
            setActiveStep(step);
            if (!completedSteps.includes(step)) {
                setCompletedSteps([...completedSteps, step]);
            }
        }
    };

    function getBaseUrl(url) {
        const parsedUrl = new URL(url);
        const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
        return baseUrl;
    }

    useEffect(() => {
        if (setSpetVariableData === 2) {
            dispatch(setCurrentStep(6));
        } else {
            dispatch(setCurrentStep(5))
        }

        dispatch(
            setRoutes({
                ...routes,
                std: {
                    route: location.pathname,
                    search: location.search,
                },
            })
        );
        const url = `${process.env.REACT_APP_URL_API_FINANMED}/category/sub/47`
        axios.get(url)
            .then((res) => {
                setProcedures(res.data)
            })
            .catch(err => {
                console.error(err)
            })

        if (!userDetail) {
            setActiveSession(true)
        }


    }, []);

    useEffect(() => {
        const inicialFormData = {};

        formsFilter.forEach(item => {
            const { data, pro } = obtenerEstadoInicial(item);
            inicialFormData[pro] = data;
        });

        setFormData(inicialFormData);
    }, []);

    useEffect(() => {
        function handleSetDataStepzTitles() {
            let updatedTitles = [];
            if (setSpetVariableData === 0) {
                updatedTitles = [
                    t("build_package.steps.titles.specialist"),
                    t("build_package.steps.titles.clinic"),
                    t("build_package.steps.titles.hotel"),
                    t("build_package.steps.titles.resume"),
                    t("build_package.steps.titles.tds"),
                    t("build_package.steps.titles.valuation_payment"),
                ];
            } else if (setSpetVariableData === 1) {
                updatedTitles = [
                    t("build_package.steps.titles.specialist"),
                    t("build_package.steps.titles.clinic"),
                    t("build_package.steps.titles.flight"),
                    t("build_package.steps.titles.resume"),
                    t("build_package.steps.titles.tds"),
                    t("build_package.steps.titles.valuation_payment"),
                ];
            } else if (setSpetVariableData === 2) {
                updatedTitles = [
                    t("build_package.steps.titles.specialist"),
                    t("build_package.steps.titles.clinic"),
                    t("build_package.steps.titles.hotel"),
                    t("build_package.steps.titles.flight"),
                    t("build_package.steps.titles.resume"),
                    t("build_package.steps.titles.tds"),
                    t("build_package.steps.titles.valuation_payment"),
                ];
            } else if (setSpetVariableData === 3) {
                updatedTitles = [
                    t("build_package.steps.titles.specialist"),
                    t("build_package.steps.titles.clinic"),
                    t("build_package.steps.titles.flight"),
                    t("build_package.steps.titles.hotel"),
                    t("build_package.steps.titles.resume"),
                    t("build_package.steps.titles.tds"),
                    t("build_package.steps.titles.valuation_payment"),
                ];
            }
    
            setDataStepzTitles(updatedTitles);
        }
    
        handleSetDataStepzTitles();
    }, [setSpetVariableData, t]);

    const createLinkPay = async() => {
        try{ 
            setLoadingLink(true);

            if (
                !height ||
                !weight ||
                (sons === true && !quantityChild) ||
                ((quantityChild && !ages) || (quantityChild && !ages.some(age => age.split(" ")[0] ))) ||
                (haveSurgery === true && !whichSurgery) ||
                (haveIllness === true && !whichIllness) ||
                (drugsConsume === true && !whichDrugs) ||
                (consumeMedicaments === true && !whichMedicaments) ||
                (youAlergicKnowed === true && !whichAlergicKnowed) ||
                (youAlergic === true && !whichAlergic) ||
                !terms ||
                !operationDate ||
                !validateForm()
            ) {
                setValidation(true)
                validateForm()
            } else {
                setValidation(false)
                let bodyPackage = {};

                if (especialtieAvliable) {
                    bodyPackage = {
                        specialtie_id: idEspecialtieData,
                        clinic_id: dataPackage.clinic.id,
                        procedure_id: procedureData.id,
                        name: userDetail.name,
                        email: userDetail.email,
                        phone: `+${prefixPhone} ${phone}`,
                    }

                    if (dataPackage.flight) {
                        let itinerary = [];

                        let itineraryFrom = {
                            dateArrival: dataPackage?.flight?.itinerary?.[0]?.dateArrival || '',
                            dateDeparture: dataPackage?.flight?.itinerary?.[0]?.dateDeparture || '',
                            from: dataPackage?.flight?.itinerary?.[0]?.from || '',
                            to: dataPackage?.flight?.itinerary?.[0]?.to || '',
                            company: dataPackage?.flight?.itinerary?.[0]?.company || '',
                            family: dataPackage?.flight?.itinerary?.[0]?.family || ''
                        };

                        itinerary = [itineraryFrom]

                        if (dataPackage?.flight?.itinerary?.length > 1) {
                            let itineraryTo = {
                                dateArrival: dataPackage.flight.itinerary[1]?.dateArrival || '',
                                dateDeparture: dataPackage.flight.itinerary[1]?.dateDeparture || '',
                                from: dataPackage.flight.itinerary[1]?.from || '',
                                to: dataPackage.flight.itinerary[1]?.to || '',
                                company: dataPackage.flight.itinerary[1]?.company || '',
                                family: dataPackage.flight.itinerary[1]?.family || ''
                            };

                            itinerary = [...itinerary, itineraryTo];
                        }

                        let flight = {
                            company: dataPackage.flight.company,
                            currency: dataPackage.flight.currency,
                            itinerary,
                            price: dataPackage.flight.priceTotal,
                        }

                        bodyPackage.flight = flight
                    }

                    if (dataPackage.hotel) {
                        let hotel = {
                            arrival: dataPackage.hotel.arrival,
                            departure: dataPackage.hotel.departure,
                            checkIn: dataPackage.hotel.dataHot.checkIn,
                            checkOut: dataPackage.hotel.dataHot.checkOut,
                            country: dataPackage.hotel.country,
                            countryClient: dataPackage.hotel.countryClient,
                            hotel: dataPackage.hotel.hotel,
                            price: dataPackage.hotel.price,
                            stars: dataPackage.hotel.stars,
                        }

                        bodyPackage.hotel = hotel
                    }
                }

                const bodySTD = {
                    eps: eps,
                    height: height,
                    weight,
                    number_children: sons ? ages : [],
                    previous_surgery: haveSurgery ? whichSurgery : t("build_package.steps.std.no"),
                    major_disease: haveIllness ? whichIllness : t("build_package.steps.std.no"),
                    drugs: drugsConsume ? whichDrugs : t("build_package.steps.std.no"),
                    drink_medication: consumeMedicaments ? whichMedicaments : t("build_package.steps.std.no"),
                    allergic_medication: youAlergic ? whichAlergic : t("build_package.steps.std.no"),
                    type_procedure: typeProcedure,
                    smoke: smoke ? t("build_package.steps.std.yes") : t("build_package.steps.std.no"),
                    alcohol: drinkAlcohol ? t("build_package.steps.std.yes") : t("build_package.steps.std.no"),
                    name: userDetail.name,
                    email: userDetail.email,
                    nacionality: nacionality,
                    date_operation: formatToISO(operationDate),
                    birth_date: userDetail?.birth_date,
                    form: formData,
                    // New fields
                    specialtie_id: idEspecialtieData,
                    clinic_id: dataPackage.clinic?.id || null,
                    procedure_id: procedureData?.id || null,
                    user_id: userDetail?.id || null,
                    flight: dataPackage.flight
                        ? { 
                            company: dataPackage.flight.company,
                            currency: dataPackage.flight.currency,
                            itinerary: dataPackage.flight.itinerary.map(itinerary => ({
                                dateArrival: itinerary.dateArrival || '',
                                dateDeparture: itinerary.dateDeparture || '',
                                from: itinerary.from || '',
                                to: itinerary.to || '',
                                company: itinerary.company || '',
                                family: itinerary.family || '',
                            })),
                            price: dataPackage.flight.priceTotal,
                            passengersSelected: dataPackage.flight?.passengersSelected,
                        }
                        : null,
                    hotel: dataPackage.hotel
                        ? {
                            arrival: dataPackage.hotel.arrival,
                            departure: dataPackage.hotel.departure,
                            checkIn: dataPackage.hotel.dataHot.checkIn,
                            checkOut: dataPackage.hotel.dataHot.checkOut,
                            country: dataPackage.hotel.country,
                            countryClient: dataPackage.hotel.countryClient,
                            hotel: dataPackage.hotel.hotel,
                            price: dataPackage.hotel.price,
                            stars: dataPackage.hotel.stars,
                            address: dataPackage.hotel.dataHab.dir,
                            photo: dataPackage.hotel.dataHab.foto,
                            checkin: dataPackage.hotel.dataHot.checkin,
                            checkout: dataPackage.hotel.dataHot.checkout,
                        }
                        : null,
                    specialist: dataPackage.specialist
                        ? { 
                            name_specialist: dataPackage.specialist.name,
                            email_specialist: dataPackage.specialist.email,
                            category: dataPackage.specialist?.categories[0].name,
                            specialties: dataPackage.specialist.specialties.map(({ specialty }) => ({
                                specialty: specialty || ''
                            }))
                        }
                        : null,
                    clinic: dataPackage.clinic
                        ? {
                            clinic_name: dataPackage.clinic.name,
                            clinic_address: dataPackage.clinic.address,
                            clinic_description: dataPackage.clinic.description,
                        }
                        : null,
                }

                const flight = {
                    specialist: {
                        name: dataPackage.specialist ? dataPackage.specialist.name : '',
                    },
                    clinic: {
                        name: dataPackage.clinic ? dataPackage.clinic.name : '',
                    },
                    company: dataPackage.flight?.company,
                    currency: dataPackage.flight?.currency,
                    itinerary: dataPackage.flight?.itinerary.map(itinerary => ({
                        dateArrival: itinerary.dateArrival || '',
                        dateDeparture: itinerary.dateDeparture || '',
                        from: itinerary.from || '',
                        to: itinerary.to || '',
                        company: itinerary.company || '',
                        family: itinerary.family || '',
                    })),
                    price: dataPackage.flight?.priceTotal,
                }

                const hotel = {
                    specialist: {
                        name: dataPackage.specialist ? dataPackage.specialist.name : '',
                    },
                    clinic: {
                        name: dataPackage.clinic ? dataPackage.clinic.name : '',
                    },
                    arrival: dataPackage.hotel?.arrival,
                    departure: dataPackage.hotel?.departure,
                    checkIn: dataPackage.hotel?.dataHot?.checkIn,
                    checkOut: dataPackage.hotel?.dataHot?.checkOut,
                    country: dataPackage.hotel?.country,
                    countryClient: dataPackage.hotel?.countryClient,
                    hotel: dataPackage.hotel?.hotel,
                    price: dataPackage.hotel?.price,
                    stars: dataPackage.hotel?.stars,
                    destination: dataSearch.cityHotelData,
                }

                const bodyAIOP = {
                    "user_id": userDetail?.id,
                    "type_search": dataSearch?.spetVariableData == 0 ? "Hotel" : dataSearch?.spetVariableData == 1 ? "Vuelo" : dataSearch?.spetVariableData == 2 ? "Vuelo + Hotel" : "",
                    "origin": dataSearch.originSelectedFlightData,
                    "destination": dataSearch.cityHotelData,
                    "dep_date": dataSearch.dateGoingSelectedData,
                    "arr_date": dataSearch.dateReturnSelectedData,
                    "flight": flight,
                    "hotel": hotel,
                }

                // Generate a randomly uuid of maximum 14 characters and only for payment
                const uuidSliced = uuidv4().slice(0, 14);

                const dataNew = {
                    name: userDetail?.name,
                    email: userDetail.email,
                    cel: userDetail?.prex_phone_public + userDetail?.phone_public,
                    localizator: uuidSliced,
                    type: "Std",
                    price: "200000",
                    currency: "COP",
                    url: `${getBaseUrl(window.location.href)}/build-package/valuation-payment`,
                };

                const url = process.env.REACT_APP_URL_CREATE_LINK;

                const payment = await axios.post(url, dataNew)

                const paymentData = {
                    ...payment.data,
                };

                const stdData = {
                    ...bodySTD,
                };

                const aiopData = {
                    ...bodyAIOP,
                };

                localStorage.setItem("data-pay-build-package", JSON.stringify(paymentData));
                localStorage.setItem("data-pay-build-package-body-std", JSON.stringify(stdData));
                localStorage.setItem("data-pay-build-package-body-aiop", JSON.stringify(aiopData));
                localStorage.setItem("data-pay-build-package-body-crm-packages", JSON.stringify(bodyPackage));
                localStorage.setItem("request-id-pay-build-package", payment.data?.requestId);

                window.location.href = await payment.data?.processUrl;
            }
        } catch (error) {
            console.error(error);
            throw new Error (error);
        } finally {
            setLoadingLink(false);
        }
    };

    useEffect(() => {
        const checkStatusPayment = () => {
            const checkIfHasBeenPaid = localStorage.getItem('has-been-paid-build-package')

            if (checkIfHasBeenPaid) {
                setHasAlreadyPaid(true);
            }
        }

        checkStatusPayment()
    }, [])

    const closeModal = (moda) => {
        navigate("/")
    }

    useEffect(() => {
        if (!userDetail) {
            setActiveSession(true)
        } else {
            setActiveSession(false)
        }
    }, [userDetail])

    const findCountryClient = (country) => {
        setLoadinNa(true);
        const url = `${process.env.REACT_APP_URL_API_FLY}restel/country`;
        axios
            .post(url, {
                code: country,
            })
            .then((res) => {
                setDataNacionality(res.data);
                setLoadinNa(false);
            })
            .catch((err) => {
                console.error(err);
                setLoadinNa(false);
            });
    };

    const selectedNacionality = (code, name) => {
        setNacionality(name);
    };

    const handleFormOpen = (index) => {
        setActiveFormIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const validateForm = () => {
        let valid = true;
        const errors = {};

        formsFilter.forEach(camps => {
            const procedimiento = camps.procedimiento;

            // Verify required images
            if (camps?.require_images?.length > 0) {
                const uploadedImages = formData[procedimiento]?.require_images || {};
                const uploadedCount = Object.keys(uploadedImages).length; // Count how many images have been uploaded

                if (uploadedCount < camps.require_images.length) {
                    valid = false;
                    errors[procedimiento] = {
                        ...errors[procedimiento],
                        require_images: {
                            message: `Para continuar, debes subir ${camps.require_images.length} imágenes.`
                        }
                    };
                }
            }

            camps.formulario.forEach(campo => {
                const value = formData[procedimiento]?.[campo.key];

                // Verify if the field depends on another
                const isDisabled = campo.dependsOn
                    ? formData[procedimiento]?.[campo.dependsOn.key] !== campo.dependsOn.value
                    : false;

                if (isDisabled) {
                    return; // Go to the next field without validation
                }

                // If the field is required and empty, mark error
                if (campo.required && !value) {
                    valid = false;
                    errors[procedimiento] = {
                        ...errors[procedimiento],
                        [campo.key]: 'Este campo es obligatorio'
                    };
                }
            });
        });
        setFormErrors(errors);
        return valid;
    };

    const [uploadedImages, setUploadedImages] = useState({});

    const handleImageUpload = (e, procedimiento, index) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Image = reader.result;// base64 of the image

                // Update the status of the uploaded images (for the previous view, you can continue using the temporary URL)
                setUploadedImages(prevState => ({
                    ...prevState,
                    [procedimiento]: {
                        ...prevState[procedimiento],
                        [index]: URL.createObjectURL(file),// Save the temporary URL for previous view
                    }
                }));

                // Update formdata with the image in base format64
                setFormData(prevData => ({
                    ...prevData,
                    [procedimiento]: {
                        ...prevData[procedimiento],
                        require_images: {
                            ...prevData[procedimiento]?.require_images,
                            [index]: base64Image // Save the image based64
                        }
                    }
                }));

                // Clean errors if the image was up correctly
                setFormErrors(prevErrors => {
                    const newErrors = { ...prevErrors };

                    // If there was an error in the image, eliminate it
                    if (newErrors[procedimiento]?.require_images?.[index]) {
                        delete newErrors[procedimiento].require_images[index];

                        // If there are no image errors, eliminate the property 'require_images'
                        if (Object.keys(newErrors[procedimiento].require_images).length === 0) {
                            delete newErrors[procedimiento].require_images;
                        }

                        // If the procedure no longer has errors, eliminate the entry of the procedure
                        if (Object.keys(newErrors[procedimiento]).length === 0) {
                            delete newErrors[procedimiento];
                        }
                    }

                    return newErrors;
                });
            };

            // Read the file as a base64
            reader.readAsDataURL(file);
        }
    };

    const [selectedOperationDate, setSelectedOperationDate] = useState(null);
    const calendarRef = useRef();

    useEffect(() => {
        // Verify if there is any error in the forms
        const formWithErrorIndex = formsFilter.findIndex(camps => {
            const procedimiento = camps?.procedimiento;
            const formErrorsForProcedure = FormErrors[procedimiento];

            // If the procedure has errors, return the index
            return formErrorsForProcedure && Object.keys(formErrorsForProcedure).some(key => formErrorsForProcedure[key]);
        });

        // If an errors form was found, we update the state to open that form
        if (formWithErrorIndex !== -1) {
            setActiveFormIndex(formWithErrorIndex);
        }
    }, [FormErrors, formsFilter]);


    // Recover data from localStorage al montar el componente
    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('data-std'));
        if (storedData) {
            setHeight(storedData.height || "");
            setWeight(storedData.weight || "");
            setDrinkAlcohol(storedData.drinkAlcohol || false);
            setSmoke(storedData.smoke || false);
            setSons(storedData.sons || false);
            setQuantityChild(storedData.quantityChild || "");
            setAges(storedData.ages || []);
            setHaveSurgery(storedData.haveSurgery || false);
            setWhichSurgery(storedData.whichSurgery || "");
            setHaveIllness(storedData.haveIllness || false);
            setWhichIllness(storedData.whichIllness || "");
            setDrugsConsume(storedData.drugsConsume || false);
            setWhichDrugs(storedData.whichDrugs || "");
            setConsumeMedicaments(storedData.consumeMedicaments || false);
            setWhichMedicaments(storedData.whichMedicaments || "");
            setYouAlergicKnowed(storedData.youAlergicKnowed || false);
            setWhichAlergicKnowed(storedData.whichAlergicKnowed || "");
            setYouAlergic(storedData.youAlergic || false);
            setWhichAlergic(storedData.whichAlergic || "");
            setSelectedOperationDate(storedData.selectedOperationDate ? new Date(storedData.selectedOperationDate) : null);
            setOperationDate(storedData.operationDate || "");
        }
    }, []);

    // save data to localStorage
    useEffect(() => {
        const data = { height, weight, drinkAlcohol, smoke, sons, quantityChild, ages, haveSurgery, whichSurgery, haveIllness, whichIllness, drugsConsume, whichDrugs, consumeMedicaments, whichMedicaments, youAlergicKnowed, whichAlergicKnowed, youAlergic, whichAlergic, selectedOperationDate, operationDate };
        localStorage.setItem('data-std', JSON.stringify(data));
    }, [height, weight, drinkAlcohol, smoke, sons, quantityChild, ages, haveSurgery, whichSurgery, haveIllness, whichIllness, drugsConsume, whichDrugs, consumeMedicaments, whichMedicaments, youAlergicKnowed, whichAlergicKnowed, youAlergic, whichAlergic, selectedOperationDate, operationDate]);


    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            {
                loading &&
                <Loading />
            }


            <SessionModal activeSessionModal={activeSession} setActiveSessionModal={setActiveSession} />
            <ModalAction open={open} setOpen={closeModal} />
            <CarouselSevices />
            <div className={styles.layoutMax}>
                {
                    especialtieAvliable && (
                        <>
                            <div className={styles.itemSectionAll}>
                                <StepHome />
                                {dataStepzTitles.map((item, index) => (
                                    <StepStep
                                    key={index}
                                    title={item}
                                    step={index + 1}
                                    onClick={handleStepClick}
                                    dataPackage={dataPackage}
                                    />
                                ))}
                            </div>

                            <DataSearch />
                        </>
                    )
                }
                {hasAlreadyPaid && <PaymentApprovedBanner />}

                <div
                    className={`transition-opacity duration-300 ${
                        hasAlreadyPaid ? "opacity-50 pointer-events-none" : "opacity-100"
                    }`}
                >    
                    <div className={styles.layout}>
                        <form action="" className={styles.form}>
                            <div className={styles.formTitle}>
                                <legend>{t("build_package.steps.std.title")}</legend>
                                <p>{t("build_package.steps.std.description")}</p>
                            </div>


                            <div className={styles.formSeparationInfo}>
                                <div className={styles.formSeparationItem}>

                                    <div className={styles.formTitleItem}>
                                        <h4>1. {t("build_package.steps.std.form.personal_data")}</h4>
                                        <p>{t("build_package.steps.std.form.personal_data_description")}</p>
                                    </div>

                                    <div className={styles.formItemsData}>
                                        <div className={styles['camp-two-check-column']}>
                                            <p>
                                                <span>{t("build_package.steps.std.form.mister")} / {t("build_package.steps.std.form.miss")}: </span>
                                                {userDetail?.name}
                                            </p>
                                            <p>
                                                <span>{t("build_package.steps.std.form.email")}: </span>
                                                {userDetail?.email}
                                            </p>
                                            <p>
                                                <span>{t("build_package.steps.std.form.birth_date")}: </span>
                                                {userDetail?.birth_date && formatYYYYMMDDToDDMMYYYY(userDetail?.birth_date)}
                                            </p>
                                            <p>
                                                <span>{t("build_package.steps.std.form.phone")}: +</span>{userDetail?.prex_phone_public} {userDetail?.phone_public}
                                            </p>
                                        </div>



                                        <div className={styles.formTitleItemAnyDescription}>
                                            <p>{t("build_package.steps.std.form.additional_information")}</p>
                                        </div>


                                        <div className={styles["camp-one"]}>
                                            <InputNacionalityStd
                                                nacionalityDropdown={nacionalityDropdown}
                                                setNacionalityDropdown={setNacionalityDropdown}
                                                nacionality={nacionality}
                                                validationForm={validation}
                                                paceholders={paceholders}
                                                findCountryClient={findCountryClient}
                                                loading={loadinNa}
                                                dataNacionality={dataNacionality}
                                                selectedNacionality={selectedNacionality}
                                            />

                                        </div>
                                        <div className={styles["camp-two"]} ref={calendarRef}>
                                            {/* Fecha de Operación */}
                                            <div className={styles.right}>
                                                <label htmlFor="operationDate">{t("build_package.steps.std.form.operation_date")}<span style={{ color: 'red' }}> *</span></label>
                                                <DateInputWithPicker
                                                    inputId="operationDate"
                                                    selectedDate={selectedOperationDate}
                                                    setSelectedDate={setSelectedOperationDate}
                                                    setOperationDate={setOperationDate}
                                                    isBirthday={false}
                                                    placeholder="dd/mm/yyyy"
                                                    icon={() => <LuCalendarPlus color="var(--blueLightTransparent)" size={17} />}
                                                    hasButton={false}
                                                    validationForm={validation}
                                                />
                                            </div>
                                        </div>

                                        <div className={styles["camp-three"]}>
                                            <div className={styles.center}>
                                                <label htmlFor="">{t("build_package.steps.std.form.height")}<span style={{ color: 'red' }}> *</span></label>
                                                <input
                                                    type="number"
                                                    placeholder='1.60 / 5.25'
                                                    className={!height && validation === true ? styles.obligatory : ""}
                                                    value={height}
                                                    onChange={(e) => setHeight(e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.right}>
                                                <label htmlFor="">{t("build_package.steps.std.form.weight")}<span style={{ color: 'red' }}> *</span></label>
                                                <input
                                                    type="number"
                                                    placeholder='60'
                                                    className={!weight && validation === true ? styles.obligatory : ""}
                                                    value={weight}
                                                    onChange={(e) => setWeight(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.formSeparationItem}>
                                    <div className={styles.formTitleItemAnyDescription}>
                                        <p>{t("build_package.steps.std.form.important_questions.text")}</p>
                                    </div>

                                    <div className={styles.formItemsCheckboxes}>
                                        <div className={styles['camp-two-check-column']}>
                                            <div className={styles.divOptions}>
                                                <span>
                                                    {t("build_package.steps.std.yes")}
                                                </span>
                                                <span>
                                                    {t("build_package.steps.std.no")}
                                                </span>
                                            </div>
                                            <div className={styles.checkboxCont}>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="drinkYes"
                                                        name="drinkAlcohol"
                                                        checked={drinkAlcohol === true}
                                                        onChange={() => setDrinkAlcohol(true)}
                                                    />
                                                </div>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="drinkNo"
                                                        name="drinkAlcohol"
                                                        checked={drinkAlcohol === false}
                                                        onChange={() => setDrinkAlcohol(false)}
                                                    />
                                                </div>
                                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.drink_alcohol")}</label>
                                            </div>
                                        </div>

                                        <div className={styles['camp-two-check-column']}>
                                            <div className={styles.checkboxCont}>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="smokeYes"
                                                        name="smoke"
                                                        checked={smoke === true}
                                                        onChange={() => {
                                                            setSmoke(true);
                                                        }}
                                                    />
                                                </div>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="smokeNo"
                                                        name="smoke"
                                                        checked={smoke === false}
                                                        onChange={() => {
                                                            setSmoke(false);
                                                        }}
                                                    />
                                                </div>
                                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.smoke")}</label>
                                            </div>
                                        </div>

                                        <div className={styles['camp-two-check-middle']}>
                                            <div className={styles.checkboxCont}>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="sonsYes"
                                                        name="sons"
                                                        checked={sons === true}
                                                        onChange={() => {
                                                            setSons(true);
                                                            setQuantityChild("");
                                                            setAges([]);
                                                        }}
                                                    />
                                                </div>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="sonsNo"
                                                        name="sons"
                                                        checked={sons === false}
                                                        onChange={() => {
                                                            setSons(false);
                                                            setQuantityChild("");
                                                            setAges([]);
                                                        }}
                                                    />
                                                </div>
                                                <label htmlFor="sons">{t("build_package.steps.std.form.important_questions.have_sons")}</label>
                                            </div>

                                            {/* Renderiza el campo para cantidad de hijos si se selecciona "Sí" */}
                                            {sons && (
                                                <div className={styles['camp-middle']}>
                                                    <label htmlFor="quantityChild">{t("build_package.steps.std.form.important_questions.how_many")} *</label>
                                                    <input
                                                        type="number"
                                                        id="quantityChild"
                                                        min="1"
                                                        max="30"
                                                        placeholder={t("build_package.steps.std.form.important_questions.have_sons_placeholder")}
                                                        className={!quantityChild && validation === true ? styles.obligatory : ""}
                                                        value={quantityChild}
                                                        onChange={(e) => {
                                                            let count = parseInt(e.target.value, 10);

                                                            if (count > 30) count = 30;
                                                            if (count < 1) count = 1;

                                                            setQuantityChild(count);

                                                            setAges((prevAges) => {
                                                                if (count > prevAges.length) {
                                                                    return [...prevAges, ...Array(count - prevAges.length).fill("")];
                                                                } else {
                                                                    return prevAges.slice(0, count);
                                                                }
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {/* Renderiza los inputs de edades si está expandido */}
                                            {sons && quantityChild > 0 && (
                                                <div
                                                    className={`relative max-h-52 overflow-y-scroll w-[48%] border border-gray-300 p-4 rounded-lg bg-gray-50 customScroll`}
                                                >
                                                    {/* Botón para expandir/colapsar */}
                                                    <button
                                                        type="button"
                                                        className="absolute top-2 right-2 text-sm text-blue-500 underline focus:outline-none"
                                                        onClick={() => setShowAges(!showAges)}
                                                    >
                                                        {showAges ?
                                                            <button className={`${styles.buttonArrow} ${styles.buttonArrowOpen}`} type="button">
                                                                <BiSolidRightArrow size={20} />
                                                            </button>
                                                            :
                                                            <div className="flex gap-2">
                                                                <span>
                                                                    {t("build_package.steps.std.form.important_questions.show_ages")}
                                                                </span>
                                                                <button className={styles.buttonArrow} type="button">
                                                                    <BiSolidRightArrow size={20} />
                                                                </button>
                                                            </div>
                                                        }
                                                    </button>

                                                    {showAges && (
                                                        <>
                                                            <label htmlFor="ages" className="block text-sm font-semibold mb-2">
                                                                {t("build_package.steps.std.form.important_questions.enter_ages")} <span className="text-red-500">*</span>
                                                            </label>
                                                            {ages.map((age, index) => (
                                                                <div key={index} className="mt-5 mb-4">
                                                                    <label
                                                                        htmlFor={`age-${index + 1}`}
                                                                        className="block text-sm font-medium text-gray-700 mb-2 w-fit"
                                                                    >
                                                                        {t("build_package.steps.std.form.important_questions.children")} {index + 1}:
                                                                    </label>
                                                                    <div className="flex gap-2">
                                                                    <input
                                                                        type="number"
                                                                        id={`age-${index + 1}`}
                                                                        placeholder={`${t("build_package.steps.std.form.important_questions.child_age")} ${index + 1}`}
                                                                        value={age.split(" ")[0] || ""}
                                                                        min="1"
                                                                        max="100"
                                                                        className={`mt-1 block w-full border rounded-md shadow-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                                                                            !age && validation ? styles.obligatory : !age.split(" ")[0] && validation ? styles.obligatory : ""
                                                                        }`}
                                                                        onChange={(e) => {
                                                                            const value = parseInt(e.target.value, 10);
                                                                            const unit = age.toString().split(" ")[1] || "años";
                                                                            setAges((prevAges) =>
                                                                                prevAges.map((item, i) =>
                                                                                    i === index
                                                                                        ? `${value > 100 ? 100 : value < 1 ? 1 : value} ${unit}` // Rango 1-100
                                                                                        : item
                                                                                )
                                                                            );
                                                                            
                                                                        }}
                                                                    />
                                                                    {/* Select para unidad (años/meses) */}
                                                                    <select
                                                                        className="w-[100px] mt-1 block border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                        value={age.split(" ")[1] || "años"}
                                                                        onChange={(e) => {
                                                                            const unit = e.target.value;
                                                                            const value = age.toString().split(" ")[0] || "";
                                                                            setAges((prevAges) =>
                                                                                prevAges.map((item, i) =>
                                                                                    i === index ? `${value} ${unit}` : item
                                                                                )
                                                                            );
                                                                        }}
                                                                    >
                                                                        <option value="años">{t("build_package.steps.std.form.important_questions.years")}</option>
                                                                        <option value="meses">{t("build_package.steps.std.form.important_questions.months")}</option>
                                                                    </select>
                                                                </div>
                                                                    {(!age || !age.split(" ")[0]) && validation && (
                                                                        <p className="text-red-500 text-[12px] font-[400]">
                                                                            {t("build_package.steps.std.form.important_questions.ages_error")}
                                                                        </p>
                                                                    )}
                                                                
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>


                                        <div className={styles['camp-two-check-middle']}>
                                            <div className={styles.checkboxCont}>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="haveSurgeryYes"
                                                        name="haveSurgery"
                                                        checked={haveSurgery === true}
                                                        onChange={() => {
                                                            setHaveSurgery(true);
                                                        }}
                                                    />
                                                </div>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="haveSurgeryNo"
                                                        name="haveSurgery"
                                                        checked={haveSurgery === false}
                                                        onChange={() => {
                                                            setHaveSurgery(false);
                                                        }}
                                                    />
                                                </div>
                                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.surgery")}</label>
                                            </div>
                                            {
                                                haveSurgery &&
                                                <div className={styles['camp-middle']}>
                                                    <label htmlFor="">{t("build_package.steps.std.form.important_questions.previous_surgery")} *</label>
                                                    <input
                                                        type="text"
                                                        placeholder={i18n.language === "en" ? "example: Liposuction, Mastopexy" : "ejemplo: Liposucción, Mastopexia"}
                                                        className={!whichSurgery && validation === true ? styles.obligatory : ""}
                                                        value={whichSurgery}
                                                        onChange={(e) => setWhichSurgery(e.target.value)}
                                                    />
                                                </div>
                                            }

                                        </div>
                                        <div className={styles['camp-two-check-middle']}>
                                            <div className={styles.checkboxCont}>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="haveIllnessYes"
                                                        name="haveIllness"
                                                        checked={haveIllness === true}
                                                        onChange={() => {
                                                            setHaveIllness(true);
                                                        }}
                                                    />
                                                </div>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="haveIllnessNo"
                                                        name="haveIllness"
                                                        checked={haveIllness === false}
                                                        onChange={() => {
                                                            setHaveIllness(false);
                                                        }}
                                                    />
                                                </div>
                                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.important_illness")}</label>
                                            </div>
                                            {
                                                haveIllness &&
                                                <div className={styles['camp-middle']}>
                                                    <label htmlFor="">{t("build_package.steps.std.form.important_questions.what_illness")} *</label>
                                                    <input
                                                        type="text"
                                                        id="whichIllness"
                                                        className={!whichIllness && validation === true ? styles.obligatory : ""}
                                                        value={whichIllness}
                                                        onChange={(e) => setWhichIllness(e.target.value)}
                                                    />
                                                </div>
                                            }

                                        </div>

                                        <div className={styles['camp-two-check-middle']}>
                                            <div className={styles.checkboxCont}>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="drugsConsumeYes"
                                                        name="drugsConsume"
                                                        checked={drugsConsume === true}
                                                        onChange={() => {
                                                            setDrugsConsume(true);
                                                        }}
                                                    />
                                                </div>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="drugsConsumeNo"
                                                        name="drugsConsume"
                                                        checked={drugsConsume === false}
                                                        onChange={() => {
                                                            setDrugsConsume(false);
                                                        }}
                                                    />
                                                </div>
                                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.any_narcotics")}</label>
                                            </div>
                                            {
                                                drugsConsume &&
                                                <div className={styles['camp-middle']}>
                                                    <label htmlFor="">{t("build_package.steps.std.form.important_questions.what_illness")} *</label>
                                                    <input
                                                        type="text"
                                                        id="whichDrugs"
                                                        className={!whichDrugs && validation === true ? styles.obligatory : ""}
                                                        value={whichDrugs}
                                                        onChange={(e) => setWhichDrugs(e.target.value)}
                                                    />
                                                </div>
                                            }

                                        </div>
                                        <div className={styles['camp-two-check-middle']}>
                                            <div className={styles.checkboxCont}>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="consumeMedicamentsYes"
                                                        name="consumeMedicaments"
                                                        checked={consumeMedicaments === true}
                                                        onChange={() => {
                                                            setConsumeMedicaments(true);
                                                        }}
                                                    />
                                                </div>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="consumeMedicamentsNo"
                                                        name="consumeMedicaments"
                                                        checked={consumeMedicaments === false}
                                                        onChange={() => {
                                                            setConsumeMedicaments(false);
                                                        }}
                                                    />
                                                </div>
                                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.any_medication")}</label>
                                            </div>
                                            {
                                                consumeMedicaments &&
                                                <div className={styles['camp-middle']}>
                                                    <label htmlFor="">{t("build_package.steps.std.form.important_questions.what_medication")} *</label>
                                                    <input
                                                        type="text"
                                                        id="whichMedicaments"
                                                        className={!whichMedicaments && validation === true ? styles.obligatory : ""}
                                                        value={whichMedicaments}
                                                        onChange={(e) => setWhichMedicaments(e.target.value)}
                                                    />
                                                </div>
                                            }

                                        </div>

                                        <div className={styles['camp-two-check-middle']}>
                                            <div className={styles.checkboxCont}>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="youAlergicKnowedYes"
                                                        name="youAlergicKnowed"
                                                        checked={youAlergicKnowed === true}
                                                        onChange={() => {
                                                            setYouAlergicKnowed(true);
                                                        }}
                                                    />
                                                </div>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="youAlergicKnowedNo"
                                                        name="youAlergicKnowed"
                                                        checked={youAlergicKnowed === false}
                                                        onChange={() => {
                                                            setYouAlergicKnowed(false);
                                                        }}
                                                    />
                                                </div>
                                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.any_alergy")}</label>
                                            </div>
                                            {
                                                youAlergicKnowed &&
                                                <div className={styles['camp-middle']}>
                                                    <label htmlFor="">{t("build_package.steps.std.form.important_questions.what_alergy")} *</label>
                                                    <input
                                                        type="text"
                                                        id="whichAlergicKnowed"
                                                        className={!whichAlergicKnowed && validation === true ? styles.obligatory : ""}
                                                        value={whichAlergicKnowed}
                                                        onChange={(e) => setWhichAlergicKnowed(e.target.value)}
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <div className={styles['camp-two-check-middle']}>
                                            <div className={styles.checkboxCont}>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="youAlergicYes"
                                                        name="youAlergic"
                                                        checked={youAlergic === true}
                                                        onChange={() => {
                                                            setYouAlergic(true);
                                                        }}
                                                    />
                                                </div>
                                                <div className={styles.divcheck}>
                                                    <input
                                                        type="radio"
                                                        id="youAlergicNo"
                                                        name="youAlergic"
                                                        checked={youAlergic === false}
                                                        onChange={() => {
                                                            setYouAlergic(false);
                                                        }}
                                                    />
                                                </div>
                                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.is_alergic")}</label>
                                            </div>
                                            {
                                                youAlergic &&
                                                <div className={styles['camp-middle']}>
                                                    <label htmlFor="">{t("build_package.steps.std.form.important_questions.what_illness")} *</label>
                                                    <input
                                                        type="text"
                                                        id="whichAlergic"
                                                        className={!whichAlergic && validation === true ? styles.obligatory : ""}
                                                        value={whichAlergic}
                                                        onChange={(e) => setWhichAlergic(e.target.value)}
                                                    />
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                        <div className={styles.previewDate}>
                            <section className={styles.contPrev}>

                                {
                                    formsFilter.length > 0 ?
                                        formsFilter.map((item, index) => {
                                            return (
                                                <FormProcedure
                                                  key={index}
                                                  camps={item}
                                                  change={manejarCambio}
                                                  setFormData={setFormData}
                                                  isOpen={activeFormIndex === index}
                                                  onOpen={(index3) => handleFormOpen(index3)}
                                                  formError={FormErrors}
                                                  formData={formData}
                                                  index={index}
                                                  formsFilter={formsFilter}
                                                  FormErrors={FormErrors}
                                                  uploadedImages={uploadedImages}
                                                  handleImageUpload={handleImageUpload}
                                                  validateForm={validateForm}
                                                  setCheckboxCheck={setCheckboxCheck}
                                                  checkboxCheck={checkboxCheck} 
                                                  procedureDate={procedureDate}
                                                  setProcedureDate={setProcedureDate}
                                                  selectedProcedureDate={selectedProcedureDate}
                                                  setSelectedProcedureDate={setSelectedProcedureDate}
                                                />
                                            )
                                        })
                                        :
                                        <p>
                                            {t("build_package.steps.std.form.important_questions.no_forms")}
                                        </p>
                                }
                            </section>


                            <div className={styles.contContinue}>
                                <div className={styles.contNote}>
                                    <span>{t("build_package.steps.std.form.important_questions.note.text")}:</span>
                                    <p>
                                    {t("build_package.steps.std.form.important_questions.note.first_description")} <br /> {t("build_package.steps.std.form.important_questions.note.second_description")}
                                    </p>
                                </div>

                                <div className={styles.checkTerms}>
                                    <input type="checkbox" id='confirm' className={!terms && validation === true ? styles.obligatory : ""}
                                        checked={terms}
                                        onChange={() => {
                                            setTerms(!terms)
                                        }}
                                    />
                                    <label htmlFor='confirm'>
                                        {t("build_package.steps.std.form.important_questions.note.first_part_terms")} <span>{t("build_package.steps.std.form.important_questions.note.second_part_terms")}</span>
                                    </label>
                                </div>


                                <button
                                    className={`flex justify-center ${styles.btnContinue}`}
                                    disabled={!terms}
                                    style={{ opacity: !terms ? "0.5" : "1" }}
                                    onClick={createLinkPay}
                                >
                                    {
                                        loadingLink ? <LuLoader2 size={19} /> :
                                        t("build_package.steps.std.form.important_questions.note.continue_button")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AnyQuestions title={t("browse_home.have_doubt")} />
        </>
    )
}

export default Std;
