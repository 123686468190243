import "./styles.css";
import { useTranslation } from "react-i18next";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import CarouselSevices from "../../share/components/CarouselServices/CarouselSevices";
import useTransport from "../../../viewModels/transport/TransportC";
import AnyQuestions from "../../home/components/anyQuestion";
import question from "./question.svg";
import earphones from "./earphones.svg";
import SearchTransport from "./SearchTransport";
import NeedInformation from "../../tourismHealth/needInformation/NeedInformation";
import DisabledModal from "../../share/components/disabledModal/disabledModal";
import ReturnPage from "../../share/components/return/ReturnPage";
import LastSearch from "../../share/components/lastSearch/LastSearch";

const Transport = () => {
  const { t } = useTranslation("global");
  const {
    adult,
    baby,
    setTypeT,
    typeT,
    ciudadDropdown,
    setCiudadDropdown,
    aeropuertoDropdown,
    setAeropuertoDropdown,
    formValidate,
    passengersCal,
    findCity,
    cityList,
    citySelected,
    setcitySelected,
    findOrigin,
    originList,
    originSelected,
    setOriginSelected,
    findDestiny,
    destinyList,
    destinySelected,
    setDestinySelected,
    destinyDropdown,
    setDestinyDropdown,
    findServices,
    selectedDate,
    handleDateChange,
    loader,
    setCityList,
    loadingCity,
    loadingDestiny,
    loadingOrigin,
    city,
    setCity,
    setOriginList,
    setDestinyList,
    cityVerify,
    setCityVerify,
    destinyVerify,
    setDestinyVerify,
    originVerify,
    setOriginVerify,
    hoursSe,
    setHoursSe,
    date,
    setDate,
    calendarVerify,
    setCalendarVerify
  } = useTransport();

  const btns = {
    need: {
      title: t("browse_home.need_help.title"),
      textButton: t("browse_home.need_help.button"),
      description: t("browse_home.need_help.description"),
      img: question,
    },
    agent: {
      title: "Mi Agente Wellezy, ¡compra con atención personalizada!",
      textButton: "Conocer canales",
      description:
        "Tenemos varios canales de atención para que planifiques tu viaje con la asesoría de nuestros agentes expertos.",
      img: earphones,
    },
  };

  const active = false;
  const url = "/";
  const disable = false;
  const item = {
    route: url,
    disable: disable,
  };

  return (
    <div>
      {/* <Find /> */}
      <CarouselSevices current={t("carousel_services.transfers")} />
      <SearchTransport
        adult={adult}
        aeropuertoDropdown={aeropuertoDropdown}
        baby={baby}
        cityList={cityList}
        citySelected={citySelected}
        ciudadDropdown={ciudadDropdown}
        destinyDropdown={destinyDropdown}
        destinyList={destinyList}
        destinySelected={destinySelected}
        findDestiny={findDestiny}
        findOrigin={findOrigin}
        findServices={findServices}
        formValidate={formValidate}
        handleDateChange={handleDateChange}
        originList={originList}
        originSelected={originSelected}
        passengersCal={passengersCal}
        selectedDate={selectedDate}
        setAeropuertoDropdown={setAeropuertoDropdown}
        setCiudadDropdown={setCiudadDropdown}
        setDestinyDropdown={setDestinyDropdown}
        setDestinySelected={setDestinySelected}
        setOriginSelected={setOriginSelected}
        setTypeT={setTypeT}
        setcitySelected={setcitySelected}
        loader={loader}
        width={"100%"}
        setCityList={setCityList}
        loadingCity={loadingCity}
        loadingDestiny={loadingDestiny}
        loadingOrigin={loadingOrigin}
        setCity={setCity}
        setDestinyList={setDestinyList}
        setOriginList={setOriginList}
        typeT={typeT}
        cityVerify={cityVerify}
        setCityVerify={setCityVerify}
        originVerify={originVerify}
        setOriginVerify={setOriginVerify}
        destinyVerify={destinyVerify}
        setDestinyVerify={setDestinyVerify}
        date={date}
        setDate={setDate}
        hoursSe={hoursSe}
        setHoursSe={setHoursSe}
        calendarVerify={calendarVerify}
        setCalendarVerify={setCalendarVerify}
      />

      <LastSearch
        description={t("hotels.last_search.description")}
        title={t("hotels.last_search.title")}
      />

      <div
        className="layout-need-information-tourism-health"
        style={{ margin: "40px auto", marginBottom: 40, marginTop: 40 }}
      >
        <NeedInformation
          description={btns.need.description}
          img={btns.need.img}
          title={btns.need.title}
          textButton={btns.need.textButton}
          color={"#104172"}
          active={true}
          url={"/contact"}
        />
      </div>

      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default Transport;
