import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";
import { MdSafetyCheck, MdCreditCard, MdPendingActions } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Dialog } from "@mui/material";
import ModalPaymentCenter from "./ModalPaymentCenter";
import useUserProfile from "@/ui/viewModels/profile/user.profile.viewModel";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import {
  MdDelete,
} from "react-icons/md";

function textCutter(cantidadCaracteres, texto) {
  if (texto?.length <= cantidadCaracteres) {
    return texto;
  }
  return texto?.slice(0, cantidadCaracteres) + "...";
}

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

const itemsPayment = [
  {
    id: "1",
    title: "Hotel Sunrise",
    subtitle: "Amanecer en la ciudad",
    date: "01/09/2025",
    details: {
      flight: { price: 300000, title: "Vuelo Medellín - Bogotá" },
      hotel: { price: 150000, title: "Hotel Sunrise" }
    },
    amount_to_pay: 450000
  },
  {
    id: "2",
    title: "Vuelo Cali - Cartagena",
    subtitle: "Viaje de ensueño",
    date: "02/09/2025",
    details: {
      flight: { price: 350000, title: "Vuelo Cali - Cartagena" },
      hotel: { price: 200000, title: "Hotel Sunset" }
    },
    amount_to_pay: 550000
  },
  {
    id: "3",
    title: "Hotel Oceanview",
    subtitle: "Vista al océano",
    date: "03/09/2025",
    details: {
      flight: { price: 320000, title: "Vuelo Bogotá - Cali" },
      hotel: { price: 180000, title: "Hotel Oceanview" }
    },
    amount_to_pay: 500000
  },
  {
    id: "4",
    title: "Vuelo Medellín - Cartagena",
    subtitle: "Descubre Cartagena",
    date: "04/09/2025",
    details: {
      flight: { price: 370000, title: "Vuelo Medellín - Cartagena" },
      hotel: { price: 220000, title: "Hotel Seaside" }
    },
    amount_to_pay: 590000
  },
  {
    id: "5",
    title: "Hotel Mountainview",
    subtitle: "Vista a la montaña",
    date: "05/09/2025",
    details: {
      flight: { price: 310000, title: "Vuelo Cali - Medellín" },
      hotel: { price: 170000, title: "Hotel Mountainview" }
    },
    amount_to_pay: 480000
  },
  {
    id: "6",
    title: "Vuelo Bogotá - Barranquilla",
    subtitle: "Explora Barranquilla",
    date: "06/09/2025",
    details: {
      flight: { price: 360000, title: "Vuelo Bogotá - Barranquilla" },
      hotel: { price: 210000, title: "Hotel Riverside" }
    },
    amount_to_pay: 570000
  },
  {
    id: "7",
    title: "Hotel Lakeside",
    subtitle: "Junto al lago",
    date: "07/09/2025",
    details: {
      flight: { price: 330000, title: "Vuelo Cartagena - Cali" },
      hotel: { price: 190000, title: "Hotel Lakeside" }
    },
    amount_to_pay: 520000
  },
  {
    id: "8",
    title: "Vuelo Medellín - Santa Marta",
    subtitle: "Viaje a Santa Marta",
    date: "08/09/2025",
    details: {
      flight: { price: 340000, title: "Vuelo Medellín - Santa Marta" },
      hotel: { price: 200000, title: "Hotel Hillside" }
    },
    amount_to_pay: 540000
  },
  {
    id: "9",
    title: "Hotel Cityview",
    subtitle: "Vista a la ciudad",
    date: "09/09/2025",
    details: {
      flight: { price: 300000, title: "Vuelo Cali - Bogotá" },
      hotel: { price: 150000, title: "Hotel Cityview" }
    },
    amount_to_pay: 450000
  },
  {
    id: "10",
    title: "Vuelo Barranquilla - Cartagena",
    subtitle: "Conoce Cartagena",
    date: "10/09/2025",
    details: {
      flight: { price: 350000, title: "Vuelo Barranquilla - Cartagena" },
      hotel: { price: 200000, title: "Hotel Beachside" }
    },
    amount_to_pay: 550000
  },
  {
    id: "11",
    title: "Hotel Countryside",
    subtitle: "En el campo",
    date: "11/09/2025",
    details: {
      flight: { price: 320000, title: "Vuelo Bogotá - Medellín" },
      hotel: { price: 180000, title: "Hotel Countryside" }
    },
    amount_to_pay: 500000
  },
  {
    id: "12",
    title: "Vuelo Cali - Santa Marta",
    subtitle: "Descubre Santa Marta",
    date: "12/09/2025",
    details: {
      flight: { price: 370000, title: "Vuelo Cali - Santa Marta" },
      hotel: { price: 220000, title: "Hotel Forestview" }
    },
    amount_to_pay: 590000
  },
  {
    id: "13",
    title: "Hotel Desertview",
    subtitle: "Vista al desierto",
    date: "13/09/2025",
    details: {
      flight: { price: 310000, title: "Vuelo Medellín - Cali" },
      hotel: { price: 170000, title: "Hotel Desertview" }
    },
    amount_to_pay: 480000
  },
  {
    id: "14",
    title: "Vuelo Bogotá - Cartagena",
    subtitle: "Viaje a Cartagena",
    date: "14/09/2025",
    details: {
      flight: { price: 360000, title: "Vuelo Bogotá - Cartagena" },
      hotel: { price: 210000, title: "Hotel Cliffside" }
    },
    amount_to_pay: 570000
  },
  {
    id: "15",
    title: "Hotel Pacific",
    subtitle: "Vista al Pacífico",
    date: "15/09/2025",
    details: {
      flight: { price: 400000, title: "Vuelo Barranquilla - Medellín" },
      hotel: { price: 250000, title: "Hotel Pacific" }
    },
    amount_to_pay: 650000
  }
];


const MyCart = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filter, setFilter] = useState(null);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [currentItems, setCurrentItems] = useState([]);
  const [t] = useTranslation("global");

  const [openViewItem, setOpenCreateContact] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { getUserPaymentsHub } = useUserProfile();
  const [loadingLink, setLoadingLink] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      const items = await getUserPaymentsHub();
      setCurrentItems(items);
      setFilteredItems(items);
    };
    fetchItems();
  }, []);

  const handleFilterChange = (status) => {
    setFilter(status);
    if (status) {
      setFilteredItems(currentItems.filter((item) => item.status === status));
    } else {
      setFilteredItems(currentItems);
    }
  };

  const handleOpenDialogEdit = (item) => {
    setSelectedItem(item);
    setOpenCreateContact(true);
  };

  const createLinkPay = async (item) => {
    try {
      setLoadingLink(true);

      const authUserSession = JSON.parse(localStorage.getItem("@authUserSession") || "{}");
      const uuidSliced = uuidv4().slice(0, 14);

      const dataNew = {
        name: authUserSession?.user?.name || "",
        email: authUserSession?.user?.email || "",
        cel: authUserSession?.user?.prex_phone_public + authUserSession?.user?.phone_public || "",
        localizator: uuidSliced,
        type: "PaymentHub",
        price: "200000",
        currency: "COP",
        url: `${window.location.origin}/payment-hub-pay-status`,
      };

      const url = process.env.REACT_APP_URL_CREATE_LINK;

      const payment = await axios.post(url, dataNew);

      localStorage.setItem("request-id-payment-hub", payment.data?.requestId);
      localStorage.setItem("payment_hub_item_id", item.id);
      localStorage.setItem("payment_hub_item_data", JSON.stringify(item));

      window.location.href = payment.data?.processUrl;
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingLink(false);
    }
  };

  const handleGoToPay = async (item) => {
    if (item.status === "completed") return;
    await createLinkPay(item);
  };

  const handleCloseViewItem = () => {
    setOpenCreateContact(false);
  };

  const nextHandler = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(filteredItems.length / itemsPerPage)));
  };

  const prevHandler = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  return (
    <div className={styles.cartSection}>
      <div className={styles.cart}>
        <h3 className="text-xl font-bold mb-4">{t("payment_center.title")}</h3>

        <div className={styles.cartItems}>
          {filteredItems?.length > 0 ? (
            filteredItems.map((item) => (
              <div key={item.id} className="flex justify-between items-center bg-white p-4 rounded-lg shadow-md border border-gray-200">
                {/* Información del Pago */}
                <div className="flex items-center space-x-4 w-4/5">
                  <MdSafetyCheck className="text-2xl text-customBlue" />
                  <div>
                    <h4 className="font-bold text-lg">{item.title}</h4>
                    <p className="text-sm text-gray-600 capitalize mt-2">
                      {item.status === "completed" ? t("payment_hub.completed") : t("payment_hub.pending")} - {new Date(item.created_at).toLocaleString()}
                    </p>
                    <p className="text-gray-800 font-semibold my-2">
                      {formatPriceCOP(item.amount_pay)}
                    </p>

                    <Link onClick={() => handleOpenDialogEdit(item)} className="text-customBlue hover:text-customHoverBlue underline">
                      {t("payment_center.see_details")}
                    </Link>
                  </div>
                </div>

                {/* Separador e Indicador de Estado */}
                <div className="flex items-center justify-center w-1/5 border-l border-gray-300 pl-4">
                  {item.status === "completed" ? (
                    <div className="flex flex-col items-center text-center">
                      <FaCheckCircle className="text-green-600 text-2xl" />
                      <span className="text-green-600 font-bold text-sm mt-2">{t("payment_hub.success_payment")}</span>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center">
                      <MdCreditCard className="text-customBlue text-2xl" />
                      <span className="text-customBlue font-bold text-sm mt-2">{t("payment_hub.pending_payment")}</span>
                      <button
                        onClick={() => handleGoToPay(item)}
                        className="bg-customBlue text-white px-4 py-2 rounded-lg text-sm font-semibold hover:bg-customHoverBlue mt-2"
                      >
                        {t("payment_center.go_to_pay")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              <p className="text-gray-600">{t("payment_center.no_results")}</p>
              <AiOutlineExclamationCircle size={20} className="bg-white mx-auto mt-2" />
            </div>
          )}
        </div>

        {filteredItems.length > 0 && (
          <Paginado
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={filteredItems}
            itemsPerPage={itemsPerPage}
            pageRangeDisplayed={5}
          />
        )}
      </div>

      {/* Filtros */}
      <div className="w-[25%]">
        <h3 className="text-xl font-bold mb-4">{t("payment_hub.filters.title")}</h3>
        <div className="space-y-2">
          <button
            onClick={() => handleFilterChange("pending")}
            className={`flex items-center gap-2 w-full px-4 py-2 rounded-lg font-semibold border hover:bg-gray-300 ${
              filter === "pending"
                ? "bg-customBlue text-white border-customBlue"
                : "bg-white text-gray-800 border-gray-300"
            }`}
          >
            <MdPendingActions className={`text-lg ${filter === "pending" ? "text-white" : "text-customBlue"}`} /> 
            <span className="text-sm">{t("payment_hub.filters.pending_payments")}</span> 
          </button>
          <button
            onClick={() => handleFilterChange("completed")}
            className={`flex items-center gap-2 w-full px-4 py-2 rounded-lg font-semibold border hover:bg-gray-300 ${
              filter === "completed"
                ? "bg-green-600 text-white border-green-600"
                : "bg-white text-gray-800 border-gray-300"
            }`}
          >
            <FaCheck className={`text-lg ${filter === "completed" ? "text-white" : "text-green-600"}`} /> 
            <span className="text-sm flex">{t("payment_hub.filters.completed_payments")}</span>
          </button>

          <div className="flex justify-center w-full">
            <button
              onClick={() => handleFilterChange(null)}
              className="flex items-center gap-2 w-fit px-4 py-2 rounded-lg font-semibold border bg-white text-gray-800 border-gray-400 hover:bg-gray-300"
            >
              <MdDelete size={20} color="var(--bluePrimarys)" /> <span className="text-sm flex">{t("payment_hub.filters.clean")}</span>
            </button>
          </div>
        </div>
      </div>

      <Dialog
        PaperProps={{ sx: { width: "100%", maxWidth: "850px!important", padding: "26px", borderRadius: "8px" } }}
        open={openViewItem}
        onClose={handleCloseViewItem}
        BackdropProps={{
          sx: {
            backgroundColor: "#021624b0",
          },
        }}
      >
        <ModalPaymentCenter item={selectedItem} handleClose={handleCloseViewItem} />
      </Dialog>
    </div>
  );
};

export default MyCart;