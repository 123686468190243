import React from 'react';
import InputTypeTravelFlight from './SingleInputs/InputTypeTravelFlight';
import InputOriginFlight from './SingleInputs/InputOriginFlight';
import InputDestinyFlight from './SingleInputs/InputDestinyFlight';

const InputsFlights = ({
  isRoundTrip,
  setIsRoundTrip,
  checkDate,
  setCheckDate,
  getSelectedOrigin,
  isCardRefOrigin,
  selectedOrigin,
  selectedDestination,
  ellipsisText,
  validationSearchDestiny,
  isCardRefDestination,
  getSelectedDestination,
  activeIndex,
  inputCalendarComponent
}) => {

  return (
    <>
      <InputTypeTravelFlight
        isRoundTrip={isRoundTrip}
        setIsRoundTrip={setIsRoundTrip}
        checkDate={checkDate}
        setCheckDate={setCheckDate}
      />

      {
        (activeIndex === 1 || activeIndex === 2) && inputCalendarComponent
      }
      <InputOriginFlight
        getSelectedOrigin={getSelectedOrigin}
        isCardRefOrigin={isCardRefOrigin}
        selectedOrigin={selectedOrigin}
        selectedDestination={selectedDestination}
        ellipsisText={ellipsisText}
        validationSearchDestiny={validationSearchDestiny}
      />

      {
        activeIndex == 2 || activeIndex == 0 || activeIndex == 1 ?
          ""
          :
          < InputDestinyFlight
            isCardRefDestination={isCardRefDestination}
            getSelectedDestination={getSelectedDestination}
            selectedOrigin={selectedOrigin}
            selectedDestination={selectedDestination}
            ellipsisText={ellipsisText}
            validationSearchDestiny={validationSearchDestiny}
          />
      }

    </>
  );
};

export default InputsFlights;